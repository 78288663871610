import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Notifications.frontend.types'

@Injectable({ providedIn: 'root' })
export class Notifications_email_sendService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { to?: string, cc?: string, bcc?: string, subject?: string, html?: string, text?: string, attachments?: { attachment_blob: any, attachment_filename: string, attachment_type: string }[], transaction_id?: string, application_name?: string }): Promise<{ success?: boolean, messages?: any[] }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

