import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { Invoices_homeComponent } from './Invoices.home.component';
import { Invoices_invoice_reportComponent } from './Invoices.invoice_report.component';


@Injectable({ providedIn: 'root' })
export class Invoices_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
  ) {
    super(dialog, toastr);
  }

  public Invoices: Invoices_ShellService = this;

  public openhome(replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Invoices',
        referenceName: 'Invoices_home',
        component: Invoices_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openhomeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Invoices_homeComponent,
      'Invoices',
      mode,
      dialogSize
    )
  }
  public openinvoice_report(inParams:{ invoiceId: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Invoice Report',
        referenceName: 'Invoices_invoice_report',
        component: Invoices_invoice_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinvoice_reportDialog(
    inParams:{ invoiceId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Invoices_invoice_reportComponent,
      'Invoice Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Invoices_home') {
      const title = 'Invoices';
      const component = Invoices_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_invoice_report') {
      const title = 'Invoice Report';
      const component = Invoices_invoice_reportComponent;
      const inParams:{ invoiceId: number } = { invoiceId: null };
      if (!isNil(params.get('invoiceId'))) {
        const paramValueString = params.get('invoiceId');
        inParams.invoiceId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    return result;
  }
}
