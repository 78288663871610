import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_get_orders_by_lookupcodes_types_projects_warehousesService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderLookupcodes: string[], orderTypes?: string[], projectLookupcodes?: string[], projectIds?: number[], warehouseNames?: string[], warehouseIds?: number[], ownerReferences?: string[], vendorReferences?: string[] }): 
  Promise<{ result: { Id?: number, LookupCode?: string, OrderStatusId?: number, OwnerReference?: string, PreferredWarehouseId?: number, PriceOverride?: number, ProjectId?: number, VendorReference?: string, OrderClass?: { LookupCode?: string, OrderTypeId?: number, OrderType?: { Name?: string } }, Project?: { LookupCode?: string }, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number }[], PreferredWarehouse?: { Id?: number, Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderLookupcodes)) {
      missingRequiredInParams.push('\'orderLookupcodes\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderLookupcodes: string[], orderTypes?: string[], projectLookupcodes?: string[], projectIds?: number[], warehouseNames?: string[], warehouseIds?: number[], ownerReferences?: string[], vendorReferences?: string[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, LookupCode?: string, OrderStatusId?: number, OwnerReference?: string, PreferredWarehouseId?: number, PriceOverride?: number, ProjectId?: number, VendorReference?: string, OrderClass?: { LookupCode?: string, OrderTypeId?: number, OrderType?: { Name?: string } }, Project?: { LookupCode?: string }, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number }[], PreferredWarehouse?: { Id?: number, Name?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderLookupcodes)) {
      missingRequiredInParams.push('\'orderLookupcodes\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderLookupcodes: string[], orderTypes?: string[], projectLookupcodes?: string[], projectIds?: number[], warehouseNames?: string[], warehouseIds?: number[], ownerReferences?: string[], vendorReferences?: string[], $keys: number[] }): 
  Promise<{ result: { Id?: number, LookupCode?: string, OrderStatusId?: number, OwnerReference?: string, PreferredWarehouseId?: number, PriceOverride?: number, ProjectId?: number, VendorReference?: string, OrderClass?: { LookupCode?: string, OrderTypeId?: number, OrderType?: { Name?: string } }, Project?: { LookupCode?: string }, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number }[], PreferredWarehouse?: { Id?: number, Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderLookupcodes)) {
      missingRequiredInParams.push('\'orderLookupcodes\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      orderLookupcodes: string[], orderTypes?: string[], projectLookupcodes?: string[], projectIds?: number[], warehouseNames?: string[], warehouseIds?: number[], ownerReferences?: string[], vendorReferences?: string[]    }) {
        if(isNil(inParams.orderLookupcodes)) {
          return true; 
        }
      return false;
    }
}
