import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootprintApiManager_ds_get_order_status_by_transaction_idsService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { transaction_ids: string[] }): 
  Promise<{ result: { Id?: number, FulfillmentDate?: string, LookupCode?: string, ModifiedSysDateTime?: string, Notes?: string, WavelengthConnectorOrderTransmissions?: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, DirectionId?: number, IntegrationId?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, TransactionId?: string, TypeId?: number, WavelengthConnectorTransmissionDirection?: { Name?: string }, WavelengthConnectorTransmissionType?: { Name?: string } }[], Status?: { Id?: number, Name?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { LookupCode?: string, Notes?: string } }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.transaction_ids)) {
      missingRequiredInParams.push('\'transaction_ids\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { transaction_ids: string[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, FulfillmentDate?: string, LookupCode?: string, ModifiedSysDateTime?: string, Notes?: string, WavelengthConnectorOrderTransmissions?: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, DirectionId?: number, IntegrationId?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, TransactionId?: string, TypeId?: number, WavelengthConnectorTransmissionDirection?: { Name?: string }, WavelengthConnectorTransmissionType?: { Name?: string } }[], Status?: { Id?: number, Name?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { LookupCode?: string, Notes?: string } }[] }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.transaction_ids)) {
      missingRequiredInParams.push('\'transaction_ids\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { transaction_ids: string[], $keys: number[] }): 
  Promise<{ result: { Id?: number, FulfillmentDate?: string, LookupCode?: string, ModifiedSysDateTime?: string, Notes?: string, WavelengthConnectorOrderTransmissions?: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, DirectionId?: number, IntegrationId?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, TransactionId?: string, TypeId?: number, WavelengthConnectorTransmissionDirection?: { Name?: string }, WavelengthConnectorTransmissionType?: { Name?: string } }[], Status?: { Id?: number, Name?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { LookupCode?: string, Notes?: string } }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.transaction_ids)) {
      missingRequiredInParams.push('\'transaction_ids\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      transaction_ids: string[]    }) {
        if(isNil(inParams.transaction_ids)) {
          return true; 
        }
      return false;
    }
}
