import { Injectable } from '@angular/core';
import { MissingTranslationHandler, TranslateCompiler, TranslateParser, TranslateService, TranslateStore } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Language, LocalizationService, LocalizationSettings } from './localization.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Utilities_LocalizationService } from './Utilities.localization.service';
import { Cartonization_LocalizationService } from './Cartonization.localization.service';
import { TransloadOrders_LocalizationService } from './TransloadOrders.localization.service';
import { WorkOrders_LocalizationService } from './WorkOrders.localization.service';
import { Notifications_LocalizationService } from './Notifications.localization.service';
import { Invoices_LocalizationService } from './Invoices.localization.service';
import { Reports_LocalizationService } from './Reports.localization.service';
import { ExcelOrderImport_LocalizationService } from './ExcelOrderImport.localization.service';
import { PurchaseOrders_LocalizationService } from './PurchaseOrders.localization.service';
import { SalesOrders_LocalizationService } from './SalesOrders.localization.service';
import { Inventory_LocalizationService } from './Inventory.localization.service';
import { FootprintApiManager_LocalizationService } from './FootprintApiManager.localization.service';
import { PrintNode_LocalizationService } from './PrintNode.localization.service';

@Injectable({
  providedIn: 'root'
})
export class app_LocalizationService
  extends LocalizationService<{  }> {

  public app: app_LocalizationService = this;

  public override get settings(): LocalizationSettings {
    return {"default":"","languages":[]}
  }
  
  private _referencedServices: LocalizationService<any>[] = [];

  constructor(
    httpClient: HttpClient,
    compiler: TranslateCompiler,
    parser: TranslateParser,
    missingHandler: MissingTranslationHandler,
    public Utilities: Utilities_LocalizationService,
    public Cartonization: Cartonization_LocalizationService,
    public TransloadOrders: TransloadOrders_LocalizationService,
    public WorkOrders: WorkOrders_LocalizationService,
    public Notifications: Notifications_LocalizationService,
    public Invoices: Invoices_LocalizationService,
    public Reports: Reports_LocalizationService,
    public ExcelOrderImport: ExcelOrderImport_LocalizationService,
    public PurchaseOrders: PurchaseOrders_LocalizationService,
    public SalesOrders: SalesOrders_LocalizationService,
    public Inventory: Inventory_LocalizationService,
    public FootprintApiManager: FootprintApiManager_LocalizationService,
    public PrintNode: PrintNode_LocalizationService
    ) {
    super(
      new TranslateService(
        new TranslateStore(),
        new TranslateHttpLoader(httpClient, '../assets/i18n/app/', '.json'),
        compiler,
        parser,
        missingHandler,
        true,
        true,
        false,
        ''));

    this._referencedServices.push(this.Utilities);

    this._referencedServices.push(this.Cartonization);

    this._referencedServices.push(this.TransloadOrders);

    this._referencedServices.push(this.WorkOrders);

    this._referencedServices.push(this.Notifications);

    this._referencedServices.push(this.Invoices);

    this._referencedServices.push(this.Reports);

    this._referencedServices.push(this.ExcelOrderImport);

    this._referencedServices.push(this.PurchaseOrders);

    this._referencedServices.push(this.SalesOrders);

    this._referencedServices.push(this.Inventory);

    this._referencedServices.push(this.FootprintApiManager);

    this._referencedServices.push(this.PrintNode);
    
    this.initialize();

  }

  public override setLanguage(lang: Language): void{
    super.setLanguage(lang);
  
    for (let i of this._referencedServices){
      i.setLanguage(lang);
    }
  }
}