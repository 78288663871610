import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class PurchaseOrders_ds_purchase_orders_children_tasks_gridService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { taskId: number }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, ChainHead?: number, CompletedDateTime?: string, CreatedSysDateTime?: string, ExpectedPackagedAmount?: number, ModifiedSysUser?: string, Notes?: string, SerialNumberId?: number, ActualPackagedPack?: { ShortName?: string }, ExpectedPackagedPack?: { ShortName?: string }, Lot?: { Id?: number, LookupCode?: string, Material?: { ProjectId?: number, Project?: { Id?: number, LookupCode?: string } } }, Material?: { Id?: number, LookupCode?: string, ProjectId?: number, Project?: { Id?: number, LookupCode?: string } }, Project?: { Id?: number, LookupCode?: string }, OperationCode?: { Name?: string }, Status?: { Name?: string }, ActualSourceLicensePlate?: { Id?: number, LookupCode?: string }, ActualSourceLocation?: { Id?: number, Name?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLocation?: { Id?: number, Name?: string }, ExpectedSourceLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedSourceLocation?: { Id?: number, Name?: string }, ExpectedTargetLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedTargetLocation?: { Id?: number, Name?: string }, Warehouse?: { Name?: string }, Order?: { LookupCode?: string, OwnerReference?: string, VendorReference?: string }, SerialNumber?: { LookupCode?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.taskId)) {
      missingRequiredInParams.push('\'taskId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { taskId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, ChainHead?: number, CompletedDateTime?: string, CreatedSysDateTime?: string, ExpectedPackagedAmount?: number, ModifiedSysUser?: string, Notes?: string, SerialNumberId?: number, ActualPackagedPack?: { ShortName?: string }, ExpectedPackagedPack?: { ShortName?: string }, Lot?: { Id?: number, LookupCode?: string, Material?: { ProjectId?: number, Project?: { Id?: number, LookupCode?: string } } }, Material?: { Id?: number, LookupCode?: string, ProjectId?: number, Project?: { Id?: number, LookupCode?: string } }, Project?: { Id?: number, LookupCode?: string }, OperationCode?: { Name?: string }, Status?: { Name?: string }, ActualSourceLicensePlate?: { Id?: number, LookupCode?: string }, ActualSourceLocation?: { Id?: number, Name?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLocation?: { Id?: number, Name?: string }, ExpectedSourceLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedSourceLocation?: { Id?: number, Name?: string }, ExpectedTargetLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedTargetLocation?: { Id?: number, Name?: string }, Warehouse?: { Name?: string }, Order?: { LookupCode?: string, OwnerReference?: string, VendorReference?: string }, SerialNumber?: { LookupCode?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.taskId)) {
      missingRequiredInParams.push('\'taskId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { taskId: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, ChainHead?: number, CompletedDateTime?: string, CreatedSysDateTime?: string, ExpectedPackagedAmount?: number, ModifiedSysUser?: string, Notes?: string, SerialNumberId?: number, ActualPackagedPack?: { ShortName?: string }, ExpectedPackagedPack?: { ShortName?: string }, Lot?: { Id?: number, LookupCode?: string, Material?: { ProjectId?: number, Project?: { Id?: number, LookupCode?: string } } }, Material?: { Id?: number, LookupCode?: string, ProjectId?: number, Project?: { Id?: number, LookupCode?: string } }, Project?: { Id?: number, LookupCode?: string }, OperationCode?: { Name?: string }, Status?: { Name?: string }, ActualSourceLicensePlate?: { Id?: number, LookupCode?: string }, ActualSourceLocation?: { Id?: number, Name?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLocation?: { Id?: number, Name?: string }, ExpectedSourceLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedSourceLocation?: { Id?: number, Name?: string }, ExpectedTargetLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedTargetLocation?: { Id?: number, Name?: string }, Warehouse?: { Name?: string }, Order?: { LookupCode?: string, OwnerReference?: string, VendorReference?: string }, SerialNumber?: { LookupCode?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.taskId)) {
      missingRequiredInParams.push('\'taskId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      taskId: number    }) {
        if(isNil(inParams.taskId)) {
          return true; 
        }
      return false;
    }
}
