import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_ReportService } from './Utilities.report.index';
import { Cartonization_ReportService } from './Cartonization.report.index';
import { TransloadOrders_ReportService } from './TransloadOrders.report.index';
import { WorkOrders_ReportService } from './WorkOrders.report.index';
import { Notifications_ReportService } from './Notifications.report.index';
import { Invoices_ReportService } from './Invoices.report.index';
import { Reports_ReportService } from './Reports.report.index';
import { ExcelOrderImport_ReportService } from './ExcelOrderImport.report.index';
import { PurchaseOrders_ReportService } from './PurchaseOrders.report.index';
import { SalesOrders_ReportService } from './SalesOrders.report.index';
import { Inventory_ReportService } from './Inventory.report.index';
import { FootprintApiManager_ReportService } from './FootprintApiManager.report.index';
import { PrintNode_ReportService } from './PrintNode.report.index';


@Injectable({ providedIn: 'root' })
export class app_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_ReportService);
    this.Cartonization = this.injector.get(Cartonization_ReportService);
    this.TransloadOrders = this.injector.get(TransloadOrders_ReportService);
    this.WorkOrders = this.injector.get(WorkOrders_ReportService);
    this.Notifications = this.injector.get(Notifications_ReportService);
    this.Invoices = this.injector.get(Invoices_ReportService);
    this.Reports = this.injector.get(Reports_ReportService);
    this.ExcelOrderImport = this.injector.get(ExcelOrderImport_ReportService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_ReportService);
    this.SalesOrders = this.injector.get(SalesOrders_ReportService);
    this.Inventory = this.injector.get(Inventory_ReportService);
    this.FootprintApiManager = this.injector.get(FootprintApiManager_ReportService);
    this.PrintNode = this.injector.get(PrintNode_ReportService);
  }
    public Utilities: Utilities_ReportService;
    public Cartonization: Cartonization_ReportService;
    public TransloadOrders: TransloadOrders_ReportService;
    public WorkOrders: WorkOrders_ReportService;
    public Notifications: Notifications_ReportService;
    public Invoices: Invoices_ReportService;
    public Reports: Reports_ReportService;
    public ExcelOrderImport: ExcelOrderImport_ReportService;
    public PurchaseOrders: PurchaseOrders_ReportService;
    public SalesOrders: SalesOrders_ReportService;
    public Inventory: Inventory_ReportService;
    public FootprintApiManager: FootprintApiManager_ReportService;
    public PrintNode: PrintNode_ReportService;

  public app: app_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
}

