import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Invoices_ds_freight_billing_headerService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { date_from?: string, date_to?: string, full_text_search?: string, warehouse_id?: number, owner_id?: number, account_id?: number, address_id?: number, project_id?: number, $top?: number, $skip?: number }): Promise<{ result?: { header_id?: string, lookup_code?: string, warehouse_id?: number, warehouse_lookupcode?: string, account_id?: number, account_lookupcode?: string, destination?: string, shipping_days?: string, delivery_days?: string, owner_id?: number, owner_lookupcode?: string, shipping_days_mon?: boolean, shipping_days_tue?: boolean, shipping_days_wed?: boolean, shipping_days_thu?: boolean, shipping_days_fri?: boolean, shipping_days_sat?: boolean, shipping_days_sun?: boolean, delivery_days_mon?: boolean, delivery_days_tue?: boolean, delivery_days_wed?: boolean, delivery_days_thu?: boolean, delivery_days_fri?: boolean, delivery_days_sat?: boolean, delivery_days_sun?: boolean, address_id?: number, created_date?: string, date_number?: number, project_id?: number, project_lookupcode?: string, project_name?: string, owner_name?: string, account_name?: string }[], totalCount?: number }> {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { date_from?: string, date_to?: string, full_text_search?: string, warehouse_id?: number, owner_id?: number, account_id?: number, address_id?: number, project_id?: number, $keys: string[] }): Promise<{ result?: { header_id?: string, lookup_code?: string, warehouse_id?: number, warehouse_lookupcode?: string, account_id?: number, account_lookupcode?: string, destination?: string, shipping_days?: string, delivery_days?: string, owner_id?: number, owner_lookupcode?: string, shipping_days_mon?: boolean, shipping_days_tue?: boolean, shipping_days_wed?: boolean, shipping_days_thu?: boolean, shipping_days_fri?: boolean, shipping_days_sat?: boolean, shipping_days_sun?: boolean, delivery_days_mon?: boolean, delivery_days_tue?: boolean, delivery_days_wed?: boolean, delivery_days_thu?: boolean, delivery_days_fri?: boolean, delivery_days_sat?: boolean, delivery_days_sun?: boolean, address_id?: number, created_date?: string, date_number?: number, project_id?: number, project_lookupcode?: string, project_name?: string, owner_name?: string, account_name?: string }[] }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      date_from?: string, date_to?: string, full_text_search?: string, warehouse_id?: number, owner_id?: number, account_id?: number, address_id?: number, project_id?: number    }) {
      return false;
    }
}
