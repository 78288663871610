import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootprintApiManager_ds_get_dock_appointments_by_shipment_idService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { shipment_id: number }): 
  Promise<{ result: { Id?: number, AssignedBy?: string, AssignedOn?: string, CancelledBy?: string, CancelledOn?: string, CheckedInBy?: string, CheckedInOn?: string, CompletedBy?: string, CompletedOn?: string, DriverLicense?: string, DriverName?: string, EquipmentType?: string, InProcesesBy?: string, InProcessOn?: string, LookupCode?: string, Notes?: string, ReferenceNumber?: string, ScheduledArrival?: string, ScheduledBy?: string, ScheduledDeparture?: string, ScheduledOn?: string, VehicleLicense?: string, ScheduledCarrier?: { Name?: string, ScacCode?: string, ShortName?: string }, Status?: { Name?: string }, CancelReasonCode?: { LookupCode?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipment_id)) {
      missingRequiredInParams.push('\'shipment_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { shipment_id: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, AssignedBy?: string, AssignedOn?: string, CancelledBy?: string, CancelledOn?: string, CheckedInBy?: string, CheckedInOn?: string, CompletedBy?: string, CompletedOn?: string, DriverLicense?: string, DriverName?: string, EquipmentType?: string, InProcesesBy?: string, InProcessOn?: string, LookupCode?: string, Notes?: string, ReferenceNumber?: string, ScheduledArrival?: string, ScheduledBy?: string, ScheduledDeparture?: string, ScheduledOn?: string, VehicleLicense?: string, ScheduledCarrier?: { Name?: string, ScacCode?: string, ShortName?: string }, Status?: { Name?: string }, CancelReasonCode?: { LookupCode?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipment_id)) {
      missingRequiredInParams.push('\'shipment_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { shipment_id: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, AssignedBy?: string, AssignedOn?: string, CancelledBy?: string, CancelledOn?: string, CheckedInBy?: string, CheckedInOn?: string, CompletedBy?: string, CompletedOn?: string, DriverLicense?: string, DriverName?: string, EquipmentType?: string, InProcesesBy?: string, InProcessOn?: string, LookupCode?: string, Notes?: string, ReferenceNumber?: string, ScheduledArrival?: string, ScheduledBy?: string, ScheduledDeparture?: string, ScheduledOn?: string, VehicleLicense?: string, ScheduledCarrier?: { Name?: string, ScacCode?: string, ShortName?: string }, Status?: { Name?: string }, CancelReasonCode?: { LookupCode?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipment_id)) {
      missingRequiredInParams.push('\'shipment_id\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      shipment_id: number    }) {
        if(isNil(inParams.shipment_id)) {
          return true; 
        }
      return false;
    }
}
