import { Inject, Injectable, Injector }from '@angular/core';


import { Inventory_ds_container_type_styles_ddService } from './Inventory.datasource.index';
import { Inventory_ds_container_types_gridService } from './Inventory.datasource.index';
import { Inventory_ds_find_serialnumbersService } from './Inventory.datasource.index';
import { Inventory_ds_find_storage_categoryService } from './Inventory.datasource.index';
import { Inventory_ds_find_storage_category_ruleService } from './Inventory.datasource.index';
import { Inventory_ds_get_available_inventory_by_licenseplateId_lotId_packagedIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_available_inventory_by_licenseplateIds_lotIds_packagedIds_batchedService } from './Inventory.datasource.index';
import { Inventory_ds_get_available_inventory_by_lotId_packagedId_warehouseIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_available_inventory_by_lotIds_packagedIds_warehouseIds_batchedService } from './Inventory.datasource.index';
import { Inventory_ds_get_available_inventory_by_lotIds_packagedIds_warehouseIds_locationIds_batchedService } from './Inventory.datasource.index';
import { Inventory_ds_get_available_inventory_by_materialId_lotId_vendorlotIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_available_inventory_by_materialId_packagedId_locationdService } from './Inventory.datasource.index';
import { Inventory_ds_get_available_inventory_by_materialId_packagedId_warehouseIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_available_inventory_by_materialId_warehouseIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_available_inventory_by_materialIds_packagedIds_warehouseIds_batchedService } from './Inventory.datasource.index';
import { Inventory_ds_get_available_inventory_by_materialIds_warehouseIds_batchedService } from './Inventory.datasource.index';
import { Inventory_ds_get_containertype_by_nameService } from './Inventory.datasource.index';
import { Inventory_ds_get_inventory_by_licenseplateIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_inventory_by_lotReceivedDateService } from './Inventory.datasource.index';
import { Inventory_ds_get_inventory_licenseplate_snapshot_by_projectId_warehouseIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_inventory_lot_snapshot_by_projectId_warehouseIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_inventory_material_snapshot_by_projectId_warehouseIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_inventory_serialnumber_snapshot_by_projectId_warehouseIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_inventory_status_by_NameService } from './Inventory.datasource.index';
import { Inventory_ds_get_licenseplate_by_licenseplateIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_licenseplate_by_licenseplateId_top1Service } from './Inventory.datasource.index';
import { Inventory_ds_get_licenseplate_by_lookupcode_warehouse_archivedService } from './Inventory.datasource.index';
import { Inventory_ds_get_licenseplate_typesService } from './Inventory.datasource.index';
import { Inventory_ds_get_licenseplatecontents_by_licenseplateIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId_detailedService } from './Inventory.datasource.index';
import { Inventory_ds_get_licenseplatecontents_by_locationId_lotId_and_packagingIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_licenseplatecontents_by_lotId_packagingId_licenseplateIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_licenseplates_by_containerTypeId_top1Service } from './Inventory.datasource.index';
import { Inventory_ds_get_licenseplates_by_licenseplateIdsService } from './Inventory.datasource.index';
import { Inventory_ds_get_licenseplates_by_licenseplateIds_detailedService } from './Inventory.datasource.index';
import { Inventory_ds_get_licenseplates_by_locationIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_licenseplates_by_type_and_locationIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_location_by_locationIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_location_by_locationId_detailedService } from './Inventory.datasource.index';
import { Inventory_ds_get_lot_by_lotIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_lot_by_lotIdsService } from './Inventory.datasource.index';
import { Inventory_ds_get_lot_original_inbound_orderService } from './Inventory.datasource.index';
import { Inventory_ds_get_lot_original_inbound_order_batchedService } from './Inventory.datasource.index';
import { Inventory_ds_get_lot_original_inbound_order_by_licenseplateIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_material_by_materialIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_material_packaging_by_materialId_and_packagingIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_material_packaging_by_materialId_and_packagingId_top1Service } from './Inventory.datasource.index';
import { Inventory_ds_get_non_elligible_for_allocation_inventory_by_materialId_packagingId_warehouseIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_non_elligible_for_allocation_inventory_by_materialId_warehouseIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_non_elligible_for_allocation_inventory_by_materialIds_packagingIds_warehouseIds_batchedService } from './Inventory.datasource.index';
import { Inventory_ds_get_non_elligible_for_allocation_inventory_by_materialIds_warehouseIds_batchedService } from './Inventory.datasource.index';
import { Inventory_ds_get_order_by_orderIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_project_by_projectIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_projects_with_inventoryService } from './Inventory.datasource.index';
import { Inventory_ds_get_reasoncodes_by_lookupcode_parentId_parentEntityIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_shipments_contents_lookupService } from './Inventory.datasource.index';
import { Inventory_ds_get_shippingcontainers_by_containerTypeId_top1Service } from './Inventory.datasource.index';
import { Inventory_ds_get_storage_categories_by_ruleIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_storage_category_rulesService } from './Inventory.datasource.index';
import { Inventory_ds_get_task_by_taskIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_total_packaged_amount_by_materialId_packagedId_warehouseIdService } from './Inventory.datasource.index';
import { Inventory_ds_get_warehouse_by_warehouseIdService } from './Inventory.datasource.index';
import { Inventory_ds_inventory_by_licenseplate_selection_gridService } from './Inventory.datasource.index';
import { Inventory_ds_inventory_by_licenseplate_single_selection_gridService } from './Inventory.datasource.index';
import { Inventory_ds_inventory_by_location_selection_gridService } from './Inventory.datasource.index';
import { Inventory_ds_inventory_by_lot_selection_gridService } from './Inventory.datasource.index';
import { Inventory_ds_inventory_by_lot_single_selection_gridService } from './Inventory.datasource.index';
import { Inventory_ds_inventory_by_project_reportService } from './Inventory.datasource.index';
import { Inventory_ds_inventory_by_serialnumber_selection_gridService } from './Inventory.datasource.index';
import { Inventory_ds_inventory_by_serialnumber_single_selection_gridService } from './Inventory.datasource.index';
import { Inventory_ds_inventory_licenseplate_gridService } from './Inventory.datasource.index';
import { Inventory_ds_inventory_licenseplate_snapshot_gridService } from './Inventory.datasource.index';
import { Inventory_ds_inventory_licenseplate_snapshot_reportService } from './Inventory.datasource.index';
import { Inventory_ds_inventory_location_gridService } from './Inventory.datasource.index';
import { Inventory_ds_inventory_lot_gridService } from './Inventory.datasource.index';
import { Inventory_ds_inventory_lot_snapshot_gridService } from './Inventory.datasource.index';
import { Inventory_ds_inventory_material_gridService } from './Inventory.datasource.index';
import { Inventory_ds_inventory_material_snapshot_gridService } from './Inventory.datasource.index';
import { Inventory_ds_inventory_parent_licenseplate_gridService } from './Inventory.datasource.index';
import { Inventory_ds_inventory_snapshot_rules_gridService } from './Inventory.datasource.index';
import { Inventory_ds_inventory_statuses_gridService } from './Inventory.datasource.index';
import { Inventory_ds_inventory_summary_gridService } from './Inventory.datasource.index';
import { Inventory_ds_inventory_tasks_gridService } from './Inventory.datasource.index';
import { Inventory_ds_licenseplate_gridService } from './Inventory.datasource.index';
import { Inventory_ds_licenseplate_navigation_listService } from './Inventory.datasource.index';
import { Inventory_ds_licenseplate_total_gross_weight_widgetService } from './Inventory.datasource.index';
import { Inventory_ds_licenseplate_total_packaged_amount_widgetService } from './Inventory.datasource.index';
import { Inventory_ds_licenseplate_totals_widgetService } from './Inventory.datasource.index';
import { Inventory_ds_licenseplates_by_inventory_ddService } from './Inventory.datasource.index';
import { Inventory_ds_licenseplates_ddService } from './Inventory.datasource.index';
import { Inventory_ds_lot_history_report_LotHistoryBreakdownByShipment_DedupedLoadingTasksCTEService } from './Inventory.datasource.index';
import { Inventory_ds_lot_history_report_LotHistoryBreakdownByShipment_SerialNumbersCTEService } from './Inventory.datasource.index';
import { Inventory_ds_lot_history_report_LotHistoryBreakdownByShipment_ShippedLotsCTEService } from './Inventory.datasource.index';
import { Inventory_ds_lot_history_report_LotHistoryBreakdown_ByReceiving_Adjustment_Inspection_StatusChangeService } from './Inventory.datasource.index';
import { Inventory_ds_lot_history_report_headerService } from './Inventory.datasource.index';
import { Inventory_ds_lots_by_licenseplate_ddService } from './Inventory.datasource.index';
import { Inventory_ds_materials_by_licenseplate_ddService } from './Inventory.datasource.index';
import { Inventory_ds_materials_udf_gridService } from './Inventory.datasource.index';
import { Inventory_ds_measurement_units_ddService } from './Inventory.datasource.index';
import { Inventory_ds_operationcodes_ddService } from './Inventory.datasource.index';
import { Inventory_ds_owners_ddService } from './Inventory.datasource.index';
import { Inventory_ds_packagings_by_licenseplate_material_ddService } from './Inventory.datasource.index';
import { Inventory_ds_projects_ddService } from './Inventory.datasource.index';
import { Inventory_ds_reasoncodes_ddService } from './Inventory.datasource.index';
import { Inventory_ds_serialnumber_weight_widgetService } from './Inventory.datasource.index';
import { Inventory_ds_serialnumbers_by_inventory_ddService } from './Inventory.datasource.index';
import { Inventory_ds_storage_categories_gridService } from './Inventory.datasource.index';
import { Inventory_ds_storage_categories_rules_gridService } from './Inventory.datasource.index';
import { Inventory_ds_task_statuses_ddService } from './Inventory.datasource.index';
import { Inventory_ds_temperature_categories_ddService } from './Inventory.datasource.index';
import { Inventory_ds_temperature_category_rules_ddService } from './Inventory.datasource.index';
import { Inventory_ds_total_available_amount_widgetService } from './Inventory.datasource.index';
import { Inventory_ds_total_available_amount_widget_locationService } from './Inventory.datasource.index';
import { Inventory_ds_total_available_base_amount_widgetService } from './Inventory.datasource.index';
import { Inventory_ds_total_gross_weight_widgetService } from './Inventory.datasource.index';
import { Inventory_ds_total_packaged_amount_widgetService } from './Inventory.datasource.index';
import { Inventory_ds_vendorlots_by_licenseplate_ddService } from './Inventory.datasource.index';
import { Inventory_ds_warehouse_ddService } from './Inventory.datasource.index';
import { Inventory_ds_warehouses_navigation_gridService } from './Inventory.datasource.index';
import { Inventory_license_plate_planned_asn_totalsService } from './Inventory.datasource.index';

@Injectable({ providedIn: 'root' })
export class Inventory_DatasourceService {

  constructor(
    private injector: Injector
  ) {
  }

  public Inventory: Inventory_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_container_type_styles_dd: Inventory_ds_container_type_styles_ddService;
  public get ds_container_type_styles_dd(): Inventory_ds_container_type_styles_ddService {
    if(!this._ds_container_type_styles_dd) {
      this._ds_container_type_styles_dd = this.injector.get(Inventory_ds_container_type_styles_ddService);
    }
    return this._ds_container_type_styles_dd;
  }
  private _ds_container_types_grid: Inventory_ds_container_types_gridService;
  public get ds_container_types_grid(): Inventory_ds_container_types_gridService {
    if(!this._ds_container_types_grid) {
      this._ds_container_types_grid = this.injector.get(Inventory_ds_container_types_gridService);
    }
    return this._ds_container_types_grid;
  }
  private _ds_find_serialnumbers: Inventory_ds_find_serialnumbersService;
  public get ds_find_serialnumbers(): Inventory_ds_find_serialnumbersService {
    if(!this._ds_find_serialnumbers) {
      this._ds_find_serialnumbers = this.injector.get(Inventory_ds_find_serialnumbersService);
    }
    return this._ds_find_serialnumbers;
  }
  private _ds_find_storage_category: Inventory_ds_find_storage_categoryService;
  public get ds_find_storage_category(): Inventory_ds_find_storage_categoryService {
    if(!this._ds_find_storage_category) {
      this._ds_find_storage_category = this.injector.get(Inventory_ds_find_storage_categoryService);
    }
    return this._ds_find_storage_category;
  }
  private _ds_find_storage_category_rule: Inventory_ds_find_storage_category_ruleService;
  public get ds_find_storage_category_rule(): Inventory_ds_find_storage_category_ruleService {
    if(!this._ds_find_storage_category_rule) {
      this._ds_find_storage_category_rule = this.injector.get(Inventory_ds_find_storage_category_ruleService);
    }
    return this._ds_find_storage_category_rule;
  }
  private _ds_get_available_inventory_by_licenseplateId_lotId_packagedId: Inventory_ds_get_available_inventory_by_licenseplateId_lotId_packagedIdService;
  public get ds_get_available_inventory_by_licenseplateId_lotId_packagedId(): Inventory_ds_get_available_inventory_by_licenseplateId_lotId_packagedIdService {
    if(!this._ds_get_available_inventory_by_licenseplateId_lotId_packagedId) {
      this._ds_get_available_inventory_by_licenseplateId_lotId_packagedId = this.injector.get(Inventory_ds_get_available_inventory_by_licenseplateId_lotId_packagedIdService);
    }
    return this._ds_get_available_inventory_by_licenseplateId_lotId_packagedId;
  }
  private _ds_get_available_inventory_by_licenseplateIds_lotIds_packagedIds_batched: Inventory_ds_get_available_inventory_by_licenseplateIds_lotIds_packagedIds_batchedService;
  public get ds_get_available_inventory_by_licenseplateIds_lotIds_packagedIds_batched(): Inventory_ds_get_available_inventory_by_licenseplateIds_lotIds_packagedIds_batchedService {
    if(!this._ds_get_available_inventory_by_licenseplateIds_lotIds_packagedIds_batched) {
      this._ds_get_available_inventory_by_licenseplateIds_lotIds_packagedIds_batched = this.injector.get(Inventory_ds_get_available_inventory_by_licenseplateIds_lotIds_packagedIds_batchedService);
    }
    return this._ds_get_available_inventory_by_licenseplateIds_lotIds_packagedIds_batched;
  }
  private _ds_get_available_inventory_by_lotId_packagedId_warehouseId: Inventory_ds_get_available_inventory_by_lotId_packagedId_warehouseIdService;
  public get ds_get_available_inventory_by_lotId_packagedId_warehouseId(): Inventory_ds_get_available_inventory_by_lotId_packagedId_warehouseIdService {
    if(!this._ds_get_available_inventory_by_lotId_packagedId_warehouseId) {
      this._ds_get_available_inventory_by_lotId_packagedId_warehouseId = this.injector.get(Inventory_ds_get_available_inventory_by_lotId_packagedId_warehouseIdService);
    }
    return this._ds_get_available_inventory_by_lotId_packagedId_warehouseId;
  }
  private _ds_get_available_inventory_by_lotIds_packagedIds_warehouseIds_batched: Inventory_ds_get_available_inventory_by_lotIds_packagedIds_warehouseIds_batchedService;
  public get ds_get_available_inventory_by_lotIds_packagedIds_warehouseIds_batched(): Inventory_ds_get_available_inventory_by_lotIds_packagedIds_warehouseIds_batchedService {
    if(!this._ds_get_available_inventory_by_lotIds_packagedIds_warehouseIds_batched) {
      this._ds_get_available_inventory_by_lotIds_packagedIds_warehouseIds_batched = this.injector.get(Inventory_ds_get_available_inventory_by_lotIds_packagedIds_warehouseIds_batchedService);
    }
    return this._ds_get_available_inventory_by_lotIds_packagedIds_warehouseIds_batched;
  }
  private _ds_get_available_inventory_by_lotIds_packagedIds_warehouseIds_locationIds_batched: Inventory_ds_get_available_inventory_by_lotIds_packagedIds_warehouseIds_locationIds_batchedService;
  public get ds_get_available_inventory_by_lotIds_packagedIds_warehouseIds_locationIds_batched(): Inventory_ds_get_available_inventory_by_lotIds_packagedIds_warehouseIds_locationIds_batchedService {
    if(!this._ds_get_available_inventory_by_lotIds_packagedIds_warehouseIds_locationIds_batched) {
      this._ds_get_available_inventory_by_lotIds_packagedIds_warehouseIds_locationIds_batched = this.injector.get(Inventory_ds_get_available_inventory_by_lotIds_packagedIds_warehouseIds_locationIds_batchedService);
    }
    return this._ds_get_available_inventory_by_lotIds_packagedIds_warehouseIds_locationIds_batched;
  }
  private _ds_get_available_inventory_by_materialId_lotId_vendorlotId: Inventory_ds_get_available_inventory_by_materialId_lotId_vendorlotIdService;
  public get ds_get_available_inventory_by_materialId_lotId_vendorlotId(): Inventory_ds_get_available_inventory_by_materialId_lotId_vendorlotIdService {
    if(!this._ds_get_available_inventory_by_materialId_lotId_vendorlotId) {
      this._ds_get_available_inventory_by_materialId_lotId_vendorlotId = this.injector.get(Inventory_ds_get_available_inventory_by_materialId_lotId_vendorlotIdService);
    }
    return this._ds_get_available_inventory_by_materialId_lotId_vendorlotId;
  }
  private _ds_get_available_inventory_by_materialId_packagedId_locationd: Inventory_ds_get_available_inventory_by_materialId_packagedId_locationdService;
  public get ds_get_available_inventory_by_materialId_packagedId_locationd(): Inventory_ds_get_available_inventory_by_materialId_packagedId_locationdService {
    if(!this._ds_get_available_inventory_by_materialId_packagedId_locationd) {
      this._ds_get_available_inventory_by_materialId_packagedId_locationd = this.injector.get(Inventory_ds_get_available_inventory_by_materialId_packagedId_locationdService);
    }
    return this._ds_get_available_inventory_by_materialId_packagedId_locationd;
  }
  private _ds_get_available_inventory_by_materialId_packagedId_warehouseId: Inventory_ds_get_available_inventory_by_materialId_packagedId_warehouseIdService;
  public get ds_get_available_inventory_by_materialId_packagedId_warehouseId(): Inventory_ds_get_available_inventory_by_materialId_packagedId_warehouseIdService {
    if(!this._ds_get_available_inventory_by_materialId_packagedId_warehouseId) {
      this._ds_get_available_inventory_by_materialId_packagedId_warehouseId = this.injector.get(Inventory_ds_get_available_inventory_by_materialId_packagedId_warehouseIdService);
    }
    return this._ds_get_available_inventory_by_materialId_packagedId_warehouseId;
  }
  private _ds_get_available_inventory_by_materialId_warehouseId: Inventory_ds_get_available_inventory_by_materialId_warehouseIdService;
  public get ds_get_available_inventory_by_materialId_warehouseId(): Inventory_ds_get_available_inventory_by_materialId_warehouseIdService {
    if(!this._ds_get_available_inventory_by_materialId_warehouseId) {
      this._ds_get_available_inventory_by_materialId_warehouseId = this.injector.get(Inventory_ds_get_available_inventory_by_materialId_warehouseIdService);
    }
    return this._ds_get_available_inventory_by_materialId_warehouseId;
  }
  private _ds_get_available_inventory_by_materialIds_packagedIds_warehouseIds_batched: Inventory_ds_get_available_inventory_by_materialIds_packagedIds_warehouseIds_batchedService;
  public get ds_get_available_inventory_by_materialIds_packagedIds_warehouseIds_batched(): Inventory_ds_get_available_inventory_by_materialIds_packagedIds_warehouseIds_batchedService {
    if(!this._ds_get_available_inventory_by_materialIds_packagedIds_warehouseIds_batched) {
      this._ds_get_available_inventory_by_materialIds_packagedIds_warehouseIds_batched = this.injector.get(Inventory_ds_get_available_inventory_by_materialIds_packagedIds_warehouseIds_batchedService);
    }
    return this._ds_get_available_inventory_by_materialIds_packagedIds_warehouseIds_batched;
  }
  private _ds_get_available_inventory_by_materialIds_warehouseIds_batched: Inventory_ds_get_available_inventory_by_materialIds_warehouseIds_batchedService;
  public get ds_get_available_inventory_by_materialIds_warehouseIds_batched(): Inventory_ds_get_available_inventory_by_materialIds_warehouseIds_batchedService {
    if(!this._ds_get_available_inventory_by_materialIds_warehouseIds_batched) {
      this._ds_get_available_inventory_by_materialIds_warehouseIds_batched = this.injector.get(Inventory_ds_get_available_inventory_by_materialIds_warehouseIds_batchedService);
    }
    return this._ds_get_available_inventory_by_materialIds_warehouseIds_batched;
  }
  private _ds_get_containertype_by_name: Inventory_ds_get_containertype_by_nameService;
  public get ds_get_containertype_by_name(): Inventory_ds_get_containertype_by_nameService {
    if(!this._ds_get_containertype_by_name) {
      this._ds_get_containertype_by_name = this.injector.get(Inventory_ds_get_containertype_by_nameService);
    }
    return this._ds_get_containertype_by_name;
  }
  private _ds_get_inventory_by_licenseplateId: Inventory_ds_get_inventory_by_licenseplateIdService;
  public get ds_get_inventory_by_licenseplateId(): Inventory_ds_get_inventory_by_licenseplateIdService {
    if(!this._ds_get_inventory_by_licenseplateId) {
      this._ds_get_inventory_by_licenseplateId = this.injector.get(Inventory_ds_get_inventory_by_licenseplateIdService);
    }
    return this._ds_get_inventory_by_licenseplateId;
  }
  private _ds_get_inventory_by_lotReceivedDate: Inventory_ds_get_inventory_by_lotReceivedDateService;
  public get ds_get_inventory_by_lotReceivedDate(): Inventory_ds_get_inventory_by_lotReceivedDateService {
    if(!this._ds_get_inventory_by_lotReceivedDate) {
      this._ds_get_inventory_by_lotReceivedDate = this.injector.get(Inventory_ds_get_inventory_by_lotReceivedDateService);
    }
    return this._ds_get_inventory_by_lotReceivedDate;
  }
  private _ds_get_inventory_licenseplate_snapshot_by_projectId_warehouseId: Inventory_ds_get_inventory_licenseplate_snapshot_by_projectId_warehouseIdService;
  public get ds_get_inventory_licenseplate_snapshot_by_projectId_warehouseId(): Inventory_ds_get_inventory_licenseplate_snapshot_by_projectId_warehouseIdService {
    if(!this._ds_get_inventory_licenseplate_snapshot_by_projectId_warehouseId) {
      this._ds_get_inventory_licenseplate_snapshot_by_projectId_warehouseId = this.injector.get(Inventory_ds_get_inventory_licenseplate_snapshot_by_projectId_warehouseIdService);
    }
    return this._ds_get_inventory_licenseplate_snapshot_by_projectId_warehouseId;
  }
  private _ds_get_inventory_lot_snapshot_by_projectId_warehouseId: Inventory_ds_get_inventory_lot_snapshot_by_projectId_warehouseIdService;
  public get ds_get_inventory_lot_snapshot_by_projectId_warehouseId(): Inventory_ds_get_inventory_lot_snapshot_by_projectId_warehouseIdService {
    if(!this._ds_get_inventory_lot_snapshot_by_projectId_warehouseId) {
      this._ds_get_inventory_lot_snapshot_by_projectId_warehouseId = this.injector.get(Inventory_ds_get_inventory_lot_snapshot_by_projectId_warehouseIdService);
    }
    return this._ds_get_inventory_lot_snapshot_by_projectId_warehouseId;
  }
  private _ds_get_inventory_material_snapshot_by_projectId_warehouseId: Inventory_ds_get_inventory_material_snapshot_by_projectId_warehouseIdService;
  public get ds_get_inventory_material_snapshot_by_projectId_warehouseId(): Inventory_ds_get_inventory_material_snapshot_by_projectId_warehouseIdService {
    if(!this._ds_get_inventory_material_snapshot_by_projectId_warehouseId) {
      this._ds_get_inventory_material_snapshot_by_projectId_warehouseId = this.injector.get(Inventory_ds_get_inventory_material_snapshot_by_projectId_warehouseIdService);
    }
    return this._ds_get_inventory_material_snapshot_by_projectId_warehouseId;
  }
  private _ds_get_inventory_serialnumber_snapshot_by_projectId_warehouseId: Inventory_ds_get_inventory_serialnumber_snapshot_by_projectId_warehouseIdService;
  public get ds_get_inventory_serialnumber_snapshot_by_projectId_warehouseId(): Inventory_ds_get_inventory_serialnumber_snapshot_by_projectId_warehouseIdService {
    if(!this._ds_get_inventory_serialnumber_snapshot_by_projectId_warehouseId) {
      this._ds_get_inventory_serialnumber_snapshot_by_projectId_warehouseId = this.injector.get(Inventory_ds_get_inventory_serialnumber_snapshot_by_projectId_warehouseIdService);
    }
    return this._ds_get_inventory_serialnumber_snapshot_by_projectId_warehouseId;
  }
  private _ds_get_inventory_status_by_Name: Inventory_ds_get_inventory_status_by_NameService;
  public get ds_get_inventory_status_by_Name(): Inventory_ds_get_inventory_status_by_NameService {
    if(!this._ds_get_inventory_status_by_Name) {
      this._ds_get_inventory_status_by_Name = this.injector.get(Inventory_ds_get_inventory_status_by_NameService);
    }
    return this._ds_get_inventory_status_by_Name;
  }
  private _ds_get_licenseplate_by_licenseplateId: Inventory_ds_get_licenseplate_by_licenseplateIdService;
  public get ds_get_licenseplate_by_licenseplateId(): Inventory_ds_get_licenseplate_by_licenseplateIdService {
    if(!this._ds_get_licenseplate_by_licenseplateId) {
      this._ds_get_licenseplate_by_licenseplateId = this.injector.get(Inventory_ds_get_licenseplate_by_licenseplateIdService);
    }
    return this._ds_get_licenseplate_by_licenseplateId;
  }
  private _ds_get_licenseplate_by_licenseplateId_top1: Inventory_ds_get_licenseplate_by_licenseplateId_top1Service;
  public get ds_get_licenseplate_by_licenseplateId_top1(): Inventory_ds_get_licenseplate_by_licenseplateId_top1Service {
    if(!this._ds_get_licenseplate_by_licenseplateId_top1) {
      this._ds_get_licenseplate_by_licenseplateId_top1 = this.injector.get(Inventory_ds_get_licenseplate_by_licenseplateId_top1Service);
    }
    return this._ds_get_licenseplate_by_licenseplateId_top1;
  }
  private _ds_get_licenseplate_by_lookupcode_warehouse_archived: Inventory_ds_get_licenseplate_by_lookupcode_warehouse_archivedService;
  public get ds_get_licenseplate_by_lookupcode_warehouse_archived(): Inventory_ds_get_licenseplate_by_lookupcode_warehouse_archivedService {
    if(!this._ds_get_licenseplate_by_lookupcode_warehouse_archived) {
      this._ds_get_licenseplate_by_lookupcode_warehouse_archived = this.injector.get(Inventory_ds_get_licenseplate_by_lookupcode_warehouse_archivedService);
    }
    return this._ds_get_licenseplate_by_lookupcode_warehouse_archived;
  }
  private _ds_get_licenseplate_types: Inventory_ds_get_licenseplate_typesService;
  public get ds_get_licenseplate_types(): Inventory_ds_get_licenseplate_typesService {
    if(!this._ds_get_licenseplate_types) {
      this._ds_get_licenseplate_types = this.injector.get(Inventory_ds_get_licenseplate_typesService);
    }
    return this._ds_get_licenseplate_types;
  }
  private _ds_get_licenseplatecontents_by_licenseplateId: Inventory_ds_get_licenseplatecontents_by_licenseplateIdService;
  public get ds_get_licenseplatecontents_by_licenseplateId(): Inventory_ds_get_licenseplatecontents_by_licenseplateIdService {
    if(!this._ds_get_licenseplatecontents_by_licenseplateId) {
      this._ds_get_licenseplatecontents_by_licenseplateId = this.injector.get(Inventory_ds_get_licenseplatecontents_by_licenseplateIdService);
    }
    return this._ds_get_licenseplatecontents_by_licenseplateId;
  }
  private _ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId: Inventory_ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingIdService;
  public get ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId(): Inventory_ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingIdService {
    if(!this._ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId) {
      this._ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId = this.injector.get(Inventory_ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingIdService);
    }
    return this._ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId;
  }
  private _ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId_detailed: Inventory_ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId_detailedService;
  public get ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId_detailed(): Inventory_ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId_detailedService {
    if(!this._ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId_detailed) {
      this._ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId_detailed = this.injector.get(Inventory_ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId_detailedService);
    }
    return this._ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId_detailed;
  }
  private _ds_get_licenseplatecontents_by_locationId_lotId_and_packagingId: Inventory_ds_get_licenseplatecontents_by_locationId_lotId_and_packagingIdService;
  public get ds_get_licenseplatecontents_by_locationId_lotId_and_packagingId(): Inventory_ds_get_licenseplatecontents_by_locationId_lotId_and_packagingIdService {
    if(!this._ds_get_licenseplatecontents_by_locationId_lotId_and_packagingId) {
      this._ds_get_licenseplatecontents_by_locationId_lotId_and_packagingId = this.injector.get(Inventory_ds_get_licenseplatecontents_by_locationId_lotId_and_packagingIdService);
    }
    return this._ds_get_licenseplatecontents_by_locationId_lotId_and_packagingId;
  }
  private _ds_get_licenseplatecontents_by_lotId_packagingId_licenseplateId: Inventory_ds_get_licenseplatecontents_by_lotId_packagingId_licenseplateIdService;
  public get ds_get_licenseplatecontents_by_lotId_packagingId_licenseplateId(): Inventory_ds_get_licenseplatecontents_by_lotId_packagingId_licenseplateIdService {
    if(!this._ds_get_licenseplatecontents_by_lotId_packagingId_licenseplateId) {
      this._ds_get_licenseplatecontents_by_lotId_packagingId_licenseplateId = this.injector.get(Inventory_ds_get_licenseplatecontents_by_lotId_packagingId_licenseplateIdService);
    }
    return this._ds_get_licenseplatecontents_by_lotId_packagingId_licenseplateId;
  }
  private _ds_get_licenseplates_by_containerTypeId_top1: Inventory_ds_get_licenseplates_by_containerTypeId_top1Service;
  public get ds_get_licenseplates_by_containerTypeId_top1(): Inventory_ds_get_licenseplates_by_containerTypeId_top1Service {
    if(!this._ds_get_licenseplates_by_containerTypeId_top1) {
      this._ds_get_licenseplates_by_containerTypeId_top1 = this.injector.get(Inventory_ds_get_licenseplates_by_containerTypeId_top1Service);
    }
    return this._ds_get_licenseplates_by_containerTypeId_top1;
  }
  private _ds_get_licenseplates_by_licenseplateIds: Inventory_ds_get_licenseplates_by_licenseplateIdsService;
  public get ds_get_licenseplates_by_licenseplateIds(): Inventory_ds_get_licenseplates_by_licenseplateIdsService {
    if(!this._ds_get_licenseplates_by_licenseplateIds) {
      this._ds_get_licenseplates_by_licenseplateIds = this.injector.get(Inventory_ds_get_licenseplates_by_licenseplateIdsService);
    }
    return this._ds_get_licenseplates_by_licenseplateIds;
  }
  private _ds_get_licenseplates_by_licenseplateIds_detailed: Inventory_ds_get_licenseplates_by_licenseplateIds_detailedService;
  public get ds_get_licenseplates_by_licenseplateIds_detailed(): Inventory_ds_get_licenseplates_by_licenseplateIds_detailedService {
    if(!this._ds_get_licenseplates_by_licenseplateIds_detailed) {
      this._ds_get_licenseplates_by_licenseplateIds_detailed = this.injector.get(Inventory_ds_get_licenseplates_by_licenseplateIds_detailedService);
    }
    return this._ds_get_licenseplates_by_licenseplateIds_detailed;
  }
  private _ds_get_licenseplates_by_locationId: Inventory_ds_get_licenseplates_by_locationIdService;
  public get ds_get_licenseplates_by_locationId(): Inventory_ds_get_licenseplates_by_locationIdService {
    if(!this._ds_get_licenseplates_by_locationId) {
      this._ds_get_licenseplates_by_locationId = this.injector.get(Inventory_ds_get_licenseplates_by_locationIdService);
    }
    return this._ds_get_licenseplates_by_locationId;
  }
  private _ds_get_licenseplates_by_type_and_locationId: Inventory_ds_get_licenseplates_by_type_and_locationIdService;
  public get ds_get_licenseplates_by_type_and_locationId(): Inventory_ds_get_licenseplates_by_type_and_locationIdService {
    if(!this._ds_get_licenseplates_by_type_and_locationId) {
      this._ds_get_licenseplates_by_type_and_locationId = this.injector.get(Inventory_ds_get_licenseplates_by_type_and_locationIdService);
    }
    return this._ds_get_licenseplates_by_type_and_locationId;
  }
  private _ds_get_location_by_locationId: Inventory_ds_get_location_by_locationIdService;
  public get ds_get_location_by_locationId(): Inventory_ds_get_location_by_locationIdService {
    if(!this._ds_get_location_by_locationId) {
      this._ds_get_location_by_locationId = this.injector.get(Inventory_ds_get_location_by_locationIdService);
    }
    return this._ds_get_location_by_locationId;
  }
  private _ds_get_location_by_locationId_detailed: Inventory_ds_get_location_by_locationId_detailedService;
  public get ds_get_location_by_locationId_detailed(): Inventory_ds_get_location_by_locationId_detailedService {
    if(!this._ds_get_location_by_locationId_detailed) {
      this._ds_get_location_by_locationId_detailed = this.injector.get(Inventory_ds_get_location_by_locationId_detailedService);
    }
    return this._ds_get_location_by_locationId_detailed;
  }
  private _ds_get_lot_by_lotId: Inventory_ds_get_lot_by_lotIdService;
  public get ds_get_lot_by_lotId(): Inventory_ds_get_lot_by_lotIdService {
    if(!this._ds_get_lot_by_lotId) {
      this._ds_get_lot_by_lotId = this.injector.get(Inventory_ds_get_lot_by_lotIdService);
    }
    return this._ds_get_lot_by_lotId;
  }
  private _ds_get_lot_by_lotIds: Inventory_ds_get_lot_by_lotIdsService;
  public get ds_get_lot_by_lotIds(): Inventory_ds_get_lot_by_lotIdsService {
    if(!this._ds_get_lot_by_lotIds) {
      this._ds_get_lot_by_lotIds = this.injector.get(Inventory_ds_get_lot_by_lotIdsService);
    }
    return this._ds_get_lot_by_lotIds;
  }
  private _ds_get_lot_original_inbound_order: Inventory_ds_get_lot_original_inbound_orderService;
  public get ds_get_lot_original_inbound_order(): Inventory_ds_get_lot_original_inbound_orderService {
    if(!this._ds_get_lot_original_inbound_order) {
      this._ds_get_lot_original_inbound_order = this.injector.get(Inventory_ds_get_lot_original_inbound_orderService);
    }
    return this._ds_get_lot_original_inbound_order;
  }
  private _ds_get_lot_original_inbound_order_batched: Inventory_ds_get_lot_original_inbound_order_batchedService;
  public get ds_get_lot_original_inbound_order_batched(): Inventory_ds_get_lot_original_inbound_order_batchedService {
    if(!this._ds_get_lot_original_inbound_order_batched) {
      this._ds_get_lot_original_inbound_order_batched = this.injector.get(Inventory_ds_get_lot_original_inbound_order_batchedService);
    }
    return this._ds_get_lot_original_inbound_order_batched;
  }
  private _ds_get_lot_original_inbound_order_by_licenseplateId: Inventory_ds_get_lot_original_inbound_order_by_licenseplateIdService;
  public get ds_get_lot_original_inbound_order_by_licenseplateId(): Inventory_ds_get_lot_original_inbound_order_by_licenseplateIdService {
    if(!this._ds_get_lot_original_inbound_order_by_licenseplateId) {
      this._ds_get_lot_original_inbound_order_by_licenseplateId = this.injector.get(Inventory_ds_get_lot_original_inbound_order_by_licenseplateIdService);
    }
    return this._ds_get_lot_original_inbound_order_by_licenseplateId;
  }
  private _ds_get_material_by_materialId: Inventory_ds_get_material_by_materialIdService;
  public get ds_get_material_by_materialId(): Inventory_ds_get_material_by_materialIdService {
    if(!this._ds_get_material_by_materialId) {
      this._ds_get_material_by_materialId = this.injector.get(Inventory_ds_get_material_by_materialIdService);
    }
    return this._ds_get_material_by_materialId;
  }
  private _ds_get_material_packaging_by_materialId_and_packagingId: Inventory_ds_get_material_packaging_by_materialId_and_packagingIdService;
  public get ds_get_material_packaging_by_materialId_and_packagingId(): Inventory_ds_get_material_packaging_by_materialId_and_packagingIdService {
    if(!this._ds_get_material_packaging_by_materialId_and_packagingId) {
      this._ds_get_material_packaging_by_materialId_and_packagingId = this.injector.get(Inventory_ds_get_material_packaging_by_materialId_and_packagingIdService);
    }
    return this._ds_get_material_packaging_by_materialId_and_packagingId;
  }
  private _ds_get_material_packaging_by_materialId_and_packagingId_top1: Inventory_ds_get_material_packaging_by_materialId_and_packagingId_top1Service;
  public get ds_get_material_packaging_by_materialId_and_packagingId_top1(): Inventory_ds_get_material_packaging_by_materialId_and_packagingId_top1Service {
    if(!this._ds_get_material_packaging_by_materialId_and_packagingId_top1) {
      this._ds_get_material_packaging_by_materialId_and_packagingId_top1 = this.injector.get(Inventory_ds_get_material_packaging_by_materialId_and_packagingId_top1Service);
    }
    return this._ds_get_material_packaging_by_materialId_and_packagingId_top1;
  }
  private _ds_get_non_elligible_for_allocation_inventory_by_materialId_packagingId_warehouseId: Inventory_ds_get_non_elligible_for_allocation_inventory_by_materialId_packagingId_warehouseIdService;
  public get ds_get_non_elligible_for_allocation_inventory_by_materialId_packagingId_warehouseId(): Inventory_ds_get_non_elligible_for_allocation_inventory_by_materialId_packagingId_warehouseIdService {
    if(!this._ds_get_non_elligible_for_allocation_inventory_by_materialId_packagingId_warehouseId) {
      this._ds_get_non_elligible_for_allocation_inventory_by_materialId_packagingId_warehouseId = this.injector.get(Inventory_ds_get_non_elligible_for_allocation_inventory_by_materialId_packagingId_warehouseIdService);
    }
    return this._ds_get_non_elligible_for_allocation_inventory_by_materialId_packagingId_warehouseId;
  }
  private _ds_get_non_elligible_for_allocation_inventory_by_materialId_warehouseId: Inventory_ds_get_non_elligible_for_allocation_inventory_by_materialId_warehouseIdService;
  public get ds_get_non_elligible_for_allocation_inventory_by_materialId_warehouseId(): Inventory_ds_get_non_elligible_for_allocation_inventory_by_materialId_warehouseIdService {
    if(!this._ds_get_non_elligible_for_allocation_inventory_by_materialId_warehouseId) {
      this._ds_get_non_elligible_for_allocation_inventory_by_materialId_warehouseId = this.injector.get(Inventory_ds_get_non_elligible_for_allocation_inventory_by_materialId_warehouseIdService);
    }
    return this._ds_get_non_elligible_for_allocation_inventory_by_materialId_warehouseId;
  }
  private _ds_get_non_elligible_for_allocation_inventory_by_materialIds_packagingIds_warehouseIds_batched: Inventory_ds_get_non_elligible_for_allocation_inventory_by_materialIds_packagingIds_warehouseIds_batchedService;
  public get ds_get_non_elligible_for_allocation_inventory_by_materialIds_packagingIds_warehouseIds_batched(): Inventory_ds_get_non_elligible_for_allocation_inventory_by_materialIds_packagingIds_warehouseIds_batchedService {
    if(!this._ds_get_non_elligible_for_allocation_inventory_by_materialIds_packagingIds_warehouseIds_batched) {
      this._ds_get_non_elligible_for_allocation_inventory_by_materialIds_packagingIds_warehouseIds_batched = this.injector.get(Inventory_ds_get_non_elligible_for_allocation_inventory_by_materialIds_packagingIds_warehouseIds_batchedService);
    }
    return this._ds_get_non_elligible_for_allocation_inventory_by_materialIds_packagingIds_warehouseIds_batched;
  }
  private _ds_get_non_elligible_for_allocation_inventory_by_materialIds_warehouseIds_batched: Inventory_ds_get_non_elligible_for_allocation_inventory_by_materialIds_warehouseIds_batchedService;
  public get ds_get_non_elligible_for_allocation_inventory_by_materialIds_warehouseIds_batched(): Inventory_ds_get_non_elligible_for_allocation_inventory_by_materialIds_warehouseIds_batchedService {
    if(!this._ds_get_non_elligible_for_allocation_inventory_by_materialIds_warehouseIds_batched) {
      this._ds_get_non_elligible_for_allocation_inventory_by_materialIds_warehouseIds_batched = this.injector.get(Inventory_ds_get_non_elligible_for_allocation_inventory_by_materialIds_warehouseIds_batchedService);
    }
    return this._ds_get_non_elligible_for_allocation_inventory_by_materialIds_warehouseIds_batched;
  }
  private _ds_get_order_by_orderId: Inventory_ds_get_order_by_orderIdService;
  public get ds_get_order_by_orderId(): Inventory_ds_get_order_by_orderIdService {
    if(!this._ds_get_order_by_orderId) {
      this._ds_get_order_by_orderId = this.injector.get(Inventory_ds_get_order_by_orderIdService);
    }
    return this._ds_get_order_by_orderId;
  }
  private _ds_get_project_by_projectId: Inventory_ds_get_project_by_projectIdService;
  public get ds_get_project_by_projectId(): Inventory_ds_get_project_by_projectIdService {
    if(!this._ds_get_project_by_projectId) {
      this._ds_get_project_by_projectId = this.injector.get(Inventory_ds_get_project_by_projectIdService);
    }
    return this._ds_get_project_by_projectId;
  }
  private _ds_get_projects_with_inventory: Inventory_ds_get_projects_with_inventoryService;
  public get ds_get_projects_with_inventory(): Inventory_ds_get_projects_with_inventoryService {
    if(!this._ds_get_projects_with_inventory) {
      this._ds_get_projects_with_inventory = this.injector.get(Inventory_ds_get_projects_with_inventoryService);
    }
    return this._ds_get_projects_with_inventory;
  }
  private _ds_get_reasoncodes_by_lookupcode_parentId_parentEntityId: Inventory_ds_get_reasoncodes_by_lookupcode_parentId_parentEntityIdService;
  public get ds_get_reasoncodes_by_lookupcode_parentId_parentEntityId(): Inventory_ds_get_reasoncodes_by_lookupcode_parentId_parentEntityIdService {
    if(!this._ds_get_reasoncodes_by_lookupcode_parentId_parentEntityId) {
      this._ds_get_reasoncodes_by_lookupcode_parentId_parentEntityId = this.injector.get(Inventory_ds_get_reasoncodes_by_lookupcode_parentId_parentEntityIdService);
    }
    return this._ds_get_reasoncodes_by_lookupcode_parentId_parentEntityId;
  }
  private _ds_get_shipments_contents_lookup: Inventory_ds_get_shipments_contents_lookupService;
  public get ds_get_shipments_contents_lookup(): Inventory_ds_get_shipments_contents_lookupService {
    if(!this._ds_get_shipments_contents_lookup) {
      this._ds_get_shipments_contents_lookup = this.injector.get(Inventory_ds_get_shipments_contents_lookupService);
    }
    return this._ds_get_shipments_contents_lookup;
  }
  private _ds_get_shippingcontainers_by_containerTypeId_top1: Inventory_ds_get_shippingcontainers_by_containerTypeId_top1Service;
  public get ds_get_shippingcontainers_by_containerTypeId_top1(): Inventory_ds_get_shippingcontainers_by_containerTypeId_top1Service {
    if(!this._ds_get_shippingcontainers_by_containerTypeId_top1) {
      this._ds_get_shippingcontainers_by_containerTypeId_top1 = this.injector.get(Inventory_ds_get_shippingcontainers_by_containerTypeId_top1Service);
    }
    return this._ds_get_shippingcontainers_by_containerTypeId_top1;
  }
  private _ds_get_storage_categories_by_ruleId: Inventory_ds_get_storage_categories_by_ruleIdService;
  public get ds_get_storage_categories_by_ruleId(): Inventory_ds_get_storage_categories_by_ruleIdService {
    if(!this._ds_get_storage_categories_by_ruleId) {
      this._ds_get_storage_categories_by_ruleId = this.injector.get(Inventory_ds_get_storage_categories_by_ruleIdService);
    }
    return this._ds_get_storage_categories_by_ruleId;
  }
  private _ds_get_storage_category_rules: Inventory_ds_get_storage_category_rulesService;
  public get ds_get_storage_category_rules(): Inventory_ds_get_storage_category_rulesService {
    if(!this._ds_get_storage_category_rules) {
      this._ds_get_storage_category_rules = this.injector.get(Inventory_ds_get_storage_category_rulesService);
    }
    return this._ds_get_storage_category_rules;
  }
  private _ds_get_task_by_taskId: Inventory_ds_get_task_by_taskIdService;
  public get ds_get_task_by_taskId(): Inventory_ds_get_task_by_taskIdService {
    if(!this._ds_get_task_by_taskId) {
      this._ds_get_task_by_taskId = this.injector.get(Inventory_ds_get_task_by_taskIdService);
    }
    return this._ds_get_task_by_taskId;
  }
  private _ds_get_total_packaged_amount_by_materialId_packagedId_warehouseId: Inventory_ds_get_total_packaged_amount_by_materialId_packagedId_warehouseIdService;
  public get ds_get_total_packaged_amount_by_materialId_packagedId_warehouseId(): Inventory_ds_get_total_packaged_amount_by_materialId_packagedId_warehouseIdService {
    if(!this._ds_get_total_packaged_amount_by_materialId_packagedId_warehouseId) {
      this._ds_get_total_packaged_amount_by_materialId_packagedId_warehouseId = this.injector.get(Inventory_ds_get_total_packaged_amount_by_materialId_packagedId_warehouseIdService);
    }
    return this._ds_get_total_packaged_amount_by_materialId_packagedId_warehouseId;
  }
  private _ds_get_warehouse_by_warehouseId: Inventory_ds_get_warehouse_by_warehouseIdService;
  public get ds_get_warehouse_by_warehouseId(): Inventory_ds_get_warehouse_by_warehouseIdService {
    if(!this._ds_get_warehouse_by_warehouseId) {
      this._ds_get_warehouse_by_warehouseId = this.injector.get(Inventory_ds_get_warehouse_by_warehouseIdService);
    }
    return this._ds_get_warehouse_by_warehouseId;
  }
  private _ds_inventory_by_licenseplate_selection_grid: Inventory_ds_inventory_by_licenseplate_selection_gridService;
  public get ds_inventory_by_licenseplate_selection_grid(): Inventory_ds_inventory_by_licenseplate_selection_gridService {
    if(!this._ds_inventory_by_licenseplate_selection_grid) {
      this._ds_inventory_by_licenseplate_selection_grid = this.injector.get(Inventory_ds_inventory_by_licenseplate_selection_gridService);
    }
    return this._ds_inventory_by_licenseplate_selection_grid;
  }
  private _ds_inventory_by_licenseplate_single_selection_grid: Inventory_ds_inventory_by_licenseplate_single_selection_gridService;
  public get ds_inventory_by_licenseplate_single_selection_grid(): Inventory_ds_inventory_by_licenseplate_single_selection_gridService {
    if(!this._ds_inventory_by_licenseplate_single_selection_grid) {
      this._ds_inventory_by_licenseplate_single_selection_grid = this.injector.get(Inventory_ds_inventory_by_licenseplate_single_selection_gridService);
    }
    return this._ds_inventory_by_licenseplate_single_selection_grid;
  }
  private _ds_inventory_by_location_selection_grid: Inventory_ds_inventory_by_location_selection_gridService;
  public get ds_inventory_by_location_selection_grid(): Inventory_ds_inventory_by_location_selection_gridService {
    if(!this._ds_inventory_by_location_selection_grid) {
      this._ds_inventory_by_location_selection_grid = this.injector.get(Inventory_ds_inventory_by_location_selection_gridService);
    }
    return this._ds_inventory_by_location_selection_grid;
  }
  private _ds_inventory_by_lot_selection_grid: Inventory_ds_inventory_by_lot_selection_gridService;
  public get ds_inventory_by_lot_selection_grid(): Inventory_ds_inventory_by_lot_selection_gridService {
    if(!this._ds_inventory_by_lot_selection_grid) {
      this._ds_inventory_by_lot_selection_grid = this.injector.get(Inventory_ds_inventory_by_lot_selection_gridService);
    }
    return this._ds_inventory_by_lot_selection_grid;
  }
  private _ds_inventory_by_lot_single_selection_grid: Inventory_ds_inventory_by_lot_single_selection_gridService;
  public get ds_inventory_by_lot_single_selection_grid(): Inventory_ds_inventory_by_lot_single_selection_gridService {
    if(!this._ds_inventory_by_lot_single_selection_grid) {
      this._ds_inventory_by_lot_single_selection_grid = this.injector.get(Inventory_ds_inventory_by_lot_single_selection_gridService);
    }
    return this._ds_inventory_by_lot_single_selection_grid;
  }
  private _ds_inventory_by_project_report: Inventory_ds_inventory_by_project_reportService;
  public get ds_inventory_by_project_report(): Inventory_ds_inventory_by_project_reportService {
    if(!this._ds_inventory_by_project_report) {
      this._ds_inventory_by_project_report = this.injector.get(Inventory_ds_inventory_by_project_reportService);
    }
    return this._ds_inventory_by_project_report;
  }
  private _ds_inventory_by_serialnumber_selection_grid: Inventory_ds_inventory_by_serialnumber_selection_gridService;
  public get ds_inventory_by_serialnumber_selection_grid(): Inventory_ds_inventory_by_serialnumber_selection_gridService {
    if(!this._ds_inventory_by_serialnumber_selection_grid) {
      this._ds_inventory_by_serialnumber_selection_grid = this.injector.get(Inventory_ds_inventory_by_serialnumber_selection_gridService);
    }
    return this._ds_inventory_by_serialnumber_selection_grid;
  }
  private _ds_inventory_by_serialnumber_single_selection_grid: Inventory_ds_inventory_by_serialnumber_single_selection_gridService;
  public get ds_inventory_by_serialnumber_single_selection_grid(): Inventory_ds_inventory_by_serialnumber_single_selection_gridService {
    if(!this._ds_inventory_by_serialnumber_single_selection_grid) {
      this._ds_inventory_by_serialnumber_single_selection_grid = this.injector.get(Inventory_ds_inventory_by_serialnumber_single_selection_gridService);
    }
    return this._ds_inventory_by_serialnumber_single_selection_grid;
  }
  private _ds_inventory_licenseplate_grid: Inventory_ds_inventory_licenseplate_gridService;
  public get ds_inventory_licenseplate_grid(): Inventory_ds_inventory_licenseplate_gridService {
    if(!this._ds_inventory_licenseplate_grid) {
      this._ds_inventory_licenseplate_grid = this.injector.get(Inventory_ds_inventory_licenseplate_gridService);
    }
    return this._ds_inventory_licenseplate_grid;
  }
  private _ds_inventory_licenseplate_snapshot_grid: Inventory_ds_inventory_licenseplate_snapshot_gridService;
  public get ds_inventory_licenseplate_snapshot_grid(): Inventory_ds_inventory_licenseplate_snapshot_gridService {
    if(!this._ds_inventory_licenseplate_snapshot_grid) {
      this._ds_inventory_licenseplate_snapshot_grid = this.injector.get(Inventory_ds_inventory_licenseplate_snapshot_gridService);
    }
    return this._ds_inventory_licenseplate_snapshot_grid;
  }
  private _ds_inventory_licenseplate_snapshot_report: Inventory_ds_inventory_licenseplate_snapshot_reportService;
  public get ds_inventory_licenseplate_snapshot_report(): Inventory_ds_inventory_licenseplate_snapshot_reportService {
    if(!this._ds_inventory_licenseplate_snapshot_report) {
      this._ds_inventory_licenseplate_snapshot_report = this.injector.get(Inventory_ds_inventory_licenseplate_snapshot_reportService);
    }
    return this._ds_inventory_licenseplate_snapshot_report;
  }
  private _ds_inventory_location_grid: Inventory_ds_inventory_location_gridService;
  public get ds_inventory_location_grid(): Inventory_ds_inventory_location_gridService {
    if(!this._ds_inventory_location_grid) {
      this._ds_inventory_location_grid = this.injector.get(Inventory_ds_inventory_location_gridService);
    }
    return this._ds_inventory_location_grid;
  }
  private _ds_inventory_lot_grid: Inventory_ds_inventory_lot_gridService;
  public get ds_inventory_lot_grid(): Inventory_ds_inventory_lot_gridService {
    if(!this._ds_inventory_lot_grid) {
      this._ds_inventory_lot_grid = this.injector.get(Inventory_ds_inventory_lot_gridService);
    }
    return this._ds_inventory_lot_grid;
  }
  private _ds_inventory_lot_snapshot_grid: Inventory_ds_inventory_lot_snapshot_gridService;
  public get ds_inventory_lot_snapshot_grid(): Inventory_ds_inventory_lot_snapshot_gridService {
    if(!this._ds_inventory_lot_snapshot_grid) {
      this._ds_inventory_lot_snapshot_grid = this.injector.get(Inventory_ds_inventory_lot_snapshot_gridService);
    }
    return this._ds_inventory_lot_snapshot_grid;
  }
  private _ds_inventory_material_grid: Inventory_ds_inventory_material_gridService;
  public get ds_inventory_material_grid(): Inventory_ds_inventory_material_gridService {
    if(!this._ds_inventory_material_grid) {
      this._ds_inventory_material_grid = this.injector.get(Inventory_ds_inventory_material_gridService);
    }
    return this._ds_inventory_material_grid;
  }
  private _ds_inventory_material_snapshot_grid: Inventory_ds_inventory_material_snapshot_gridService;
  public get ds_inventory_material_snapshot_grid(): Inventory_ds_inventory_material_snapshot_gridService {
    if(!this._ds_inventory_material_snapshot_grid) {
      this._ds_inventory_material_snapshot_grid = this.injector.get(Inventory_ds_inventory_material_snapshot_gridService);
    }
    return this._ds_inventory_material_snapshot_grid;
  }
  private _ds_inventory_parent_licenseplate_grid: Inventory_ds_inventory_parent_licenseplate_gridService;
  public get ds_inventory_parent_licenseplate_grid(): Inventory_ds_inventory_parent_licenseplate_gridService {
    if(!this._ds_inventory_parent_licenseplate_grid) {
      this._ds_inventory_parent_licenseplate_grid = this.injector.get(Inventory_ds_inventory_parent_licenseplate_gridService);
    }
    return this._ds_inventory_parent_licenseplate_grid;
  }
  private _ds_inventory_snapshot_rules_grid: Inventory_ds_inventory_snapshot_rules_gridService;
  public get ds_inventory_snapshot_rules_grid(): Inventory_ds_inventory_snapshot_rules_gridService {
    if(!this._ds_inventory_snapshot_rules_grid) {
      this._ds_inventory_snapshot_rules_grid = this.injector.get(Inventory_ds_inventory_snapshot_rules_gridService);
    }
    return this._ds_inventory_snapshot_rules_grid;
  }
  private _ds_inventory_statuses_grid: Inventory_ds_inventory_statuses_gridService;
  public get ds_inventory_statuses_grid(): Inventory_ds_inventory_statuses_gridService {
    if(!this._ds_inventory_statuses_grid) {
      this._ds_inventory_statuses_grid = this.injector.get(Inventory_ds_inventory_statuses_gridService);
    }
    return this._ds_inventory_statuses_grid;
  }
  private _ds_inventory_summary_grid: Inventory_ds_inventory_summary_gridService;
  public get ds_inventory_summary_grid(): Inventory_ds_inventory_summary_gridService {
    if(!this._ds_inventory_summary_grid) {
      this._ds_inventory_summary_grid = this.injector.get(Inventory_ds_inventory_summary_gridService);
    }
    return this._ds_inventory_summary_grid;
  }
  private _ds_inventory_tasks_grid: Inventory_ds_inventory_tasks_gridService;
  public get ds_inventory_tasks_grid(): Inventory_ds_inventory_tasks_gridService {
    if(!this._ds_inventory_tasks_grid) {
      this._ds_inventory_tasks_grid = this.injector.get(Inventory_ds_inventory_tasks_gridService);
    }
    return this._ds_inventory_tasks_grid;
  }
  private _ds_licenseplate_grid: Inventory_ds_licenseplate_gridService;
  public get ds_licenseplate_grid(): Inventory_ds_licenseplate_gridService {
    if(!this._ds_licenseplate_grid) {
      this._ds_licenseplate_grid = this.injector.get(Inventory_ds_licenseplate_gridService);
    }
    return this._ds_licenseplate_grid;
  }
  private _ds_licenseplate_navigation_list: Inventory_ds_licenseplate_navigation_listService;
  public get ds_licenseplate_navigation_list(): Inventory_ds_licenseplate_navigation_listService {
    if(!this._ds_licenseplate_navigation_list) {
      this._ds_licenseplate_navigation_list = this.injector.get(Inventory_ds_licenseplate_navigation_listService);
    }
    return this._ds_licenseplate_navigation_list;
  }
  private _ds_licenseplate_total_gross_weight_widget: Inventory_ds_licenseplate_total_gross_weight_widgetService;
  public get ds_licenseplate_total_gross_weight_widget(): Inventory_ds_licenseplate_total_gross_weight_widgetService {
    if(!this._ds_licenseplate_total_gross_weight_widget) {
      this._ds_licenseplate_total_gross_weight_widget = this.injector.get(Inventory_ds_licenseplate_total_gross_weight_widgetService);
    }
    return this._ds_licenseplate_total_gross_weight_widget;
  }
  private _ds_licenseplate_total_packaged_amount_widget: Inventory_ds_licenseplate_total_packaged_amount_widgetService;
  public get ds_licenseplate_total_packaged_amount_widget(): Inventory_ds_licenseplate_total_packaged_amount_widgetService {
    if(!this._ds_licenseplate_total_packaged_amount_widget) {
      this._ds_licenseplate_total_packaged_amount_widget = this.injector.get(Inventory_ds_licenseplate_total_packaged_amount_widgetService);
    }
    return this._ds_licenseplate_total_packaged_amount_widget;
  }
  private _ds_licenseplate_totals_widget: Inventory_ds_licenseplate_totals_widgetService;
  public get ds_licenseplate_totals_widget(): Inventory_ds_licenseplate_totals_widgetService {
    if(!this._ds_licenseplate_totals_widget) {
      this._ds_licenseplate_totals_widget = this.injector.get(Inventory_ds_licenseplate_totals_widgetService);
    }
    return this._ds_licenseplate_totals_widget;
  }
  private _ds_licenseplates_by_inventory_dd: Inventory_ds_licenseplates_by_inventory_ddService;
  public get ds_licenseplates_by_inventory_dd(): Inventory_ds_licenseplates_by_inventory_ddService {
    if(!this._ds_licenseplates_by_inventory_dd) {
      this._ds_licenseplates_by_inventory_dd = this.injector.get(Inventory_ds_licenseplates_by_inventory_ddService);
    }
    return this._ds_licenseplates_by_inventory_dd;
  }
  private _ds_licenseplates_dd: Inventory_ds_licenseplates_ddService;
  public get ds_licenseplates_dd(): Inventory_ds_licenseplates_ddService {
    if(!this._ds_licenseplates_dd) {
      this._ds_licenseplates_dd = this.injector.get(Inventory_ds_licenseplates_ddService);
    }
    return this._ds_licenseplates_dd;
  }
  private _ds_lot_history_report_LotHistoryBreakdownByShipment_DedupedLoadingTasksCTE: Inventory_ds_lot_history_report_LotHistoryBreakdownByShipment_DedupedLoadingTasksCTEService;
  public get ds_lot_history_report_LotHistoryBreakdownByShipment_DedupedLoadingTasksCTE(): Inventory_ds_lot_history_report_LotHistoryBreakdownByShipment_DedupedLoadingTasksCTEService {
    if(!this._ds_lot_history_report_LotHistoryBreakdownByShipment_DedupedLoadingTasksCTE) {
      this._ds_lot_history_report_LotHistoryBreakdownByShipment_DedupedLoadingTasksCTE = this.injector.get(Inventory_ds_lot_history_report_LotHistoryBreakdownByShipment_DedupedLoadingTasksCTEService);
    }
    return this._ds_lot_history_report_LotHistoryBreakdownByShipment_DedupedLoadingTasksCTE;
  }
  private _ds_lot_history_report_LotHistoryBreakdownByShipment_SerialNumbersCTE: Inventory_ds_lot_history_report_LotHistoryBreakdownByShipment_SerialNumbersCTEService;
  public get ds_lot_history_report_LotHistoryBreakdownByShipment_SerialNumbersCTE(): Inventory_ds_lot_history_report_LotHistoryBreakdownByShipment_SerialNumbersCTEService {
    if(!this._ds_lot_history_report_LotHistoryBreakdownByShipment_SerialNumbersCTE) {
      this._ds_lot_history_report_LotHistoryBreakdownByShipment_SerialNumbersCTE = this.injector.get(Inventory_ds_lot_history_report_LotHistoryBreakdownByShipment_SerialNumbersCTEService);
    }
    return this._ds_lot_history_report_LotHistoryBreakdownByShipment_SerialNumbersCTE;
  }
  private _ds_lot_history_report_LotHistoryBreakdownByShipment_ShippedLotsCTE: Inventory_ds_lot_history_report_LotHistoryBreakdownByShipment_ShippedLotsCTEService;
  public get ds_lot_history_report_LotHistoryBreakdownByShipment_ShippedLotsCTE(): Inventory_ds_lot_history_report_LotHistoryBreakdownByShipment_ShippedLotsCTEService {
    if(!this._ds_lot_history_report_LotHistoryBreakdownByShipment_ShippedLotsCTE) {
      this._ds_lot_history_report_LotHistoryBreakdownByShipment_ShippedLotsCTE = this.injector.get(Inventory_ds_lot_history_report_LotHistoryBreakdownByShipment_ShippedLotsCTEService);
    }
    return this._ds_lot_history_report_LotHistoryBreakdownByShipment_ShippedLotsCTE;
  }
  private _ds_lot_history_report_LotHistoryBreakdown_ByReceiving_Adjustment_Inspection_StatusChange: Inventory_ds_lot_history_report_LotHistoryBreakdown_ByReceiving_Adjustment_Inspection_StatusChangeService;
  public get ds_lot_history_report_LotHistoryBreakdown_ByReceiving_Adjustment_Inspection_StatusChange(): Inventory_ds_lot_history_report_LotHistoryBreakdown_ByReceiving_Adjustment_Inspection_StatusChangeService {
    if(!this._ds_lot_history_report_LotHistoryBreakdown_ByReceiving_Adjustment_Inspection_StatusChange) {
      this._ds_lot_history_report_LotHistoryBreakdown_ByReceiving_Adjustment_Inspection_StatusChange = this.injector.get(Inventory_ds_lot_history_report_LotHistoryBreakdown_ByReceiving_Adjustment_Inspection_StatusChangeService);
    }
    return this._ds_lot_history_report_LotHistoryBreakdown_ByReceiving_Adjustment_Inspection_StatusChange;
  }
  private _ds_lot_history_report_header: Inventory_ds_lot_history_report_headerService;
  public get ds_lot_history_report_header(): Inventory_ds_lot_history_report_headerService {
    if(!this._ds_lot_history_report_header) {
      this._ds_lot_history_report_header = this.injector.get(Inventory_ds_lot_history_report_headerService);
    }
    return this._ds_lot_history_report_header;
  }
  private _ds_lots_by_licenseplate_dd: Inventory_ds_lots_by_licenseplate_ddService;
  public get ds_lots_by_licenseplate_dd(): Inventory_ds_lots_by_licenseplate_ddService {
    if(!this._ds_lots_by_licenseplate_dd) {
      this._ds_lots_by_licenseplate_dd = this.injector.get(Inventory_ds_lots_by_licenseplate_ddService);
    }
    return this._ds_lots_by_licenseplate_dd;
  }
  private _ds_materials_by_licenseplate_dd: Inventory_ds_materials_by_licenseplate_ddService;
  public get ds_materials_by_licenseplate_dd(): Inventory_ds_materials_by_licenseplate_ddService {
    if(!this._ds_materials_by_licenseplate_dd) {
      this._ds_materials_by_licenseplate_dd = this.injector.get(Inventory_ds_materials_by_licenseplate_ddService);
    }
    return this._ds_materials_by_licenseplate_dd;
  }
  private _ds_materials_udf_grid: Inventory_ds_materials_udf_gridService;
  public get ds_materials_udf_grid(): Inventory_ds_materials_udf_gridService {
    if(!this._ds_materials_udf_grid) {
      this._ds_materials_udf_grid = this.injector.get(Inventory_ds_materials_udf_gridService);
    }
    return this._ds_materials_udf_grid;
  }
  private _ds_measurement_units_dd: Inventory_ds_measurement_units_ddService;
  public get ds_measurement_units_dd(): Inventory_ds_measurement_units_ddService {
    if(!this._ds_measurement_units_dd) {
      this._ds_measurement_units_dd = this.injector.get(Inventory_ds_measurement_units_ddService);
    }
    return this._ds_measurement_units_dd;
  }
  private _ds_operationcodes_dd: Inventory_ds_operationcodes_ddService;
  public get ds_operationcodes_dd(): Inventory_ds_operationcodes_ddService {
    if(!this._ds_operationcodes_dd) {
      this._ds_operationcodes_dd = this.injector.get(Inventory_ds_operationcodes_ddService);
    }
    return this._ds_operationcodes_dd;
  }
  private _ds_owners_dd: Inventory_ds_owners_ddService;
  public get ds_owners_dd(): Inventory_ds_owners_ddService {
    if(!this._ds_owners_dd) {
      this._ds_owners_dd = this.injector.get(Inventory_ds_owners_ddService);
    }
    return this._ds_owners_dd;
  }
  private _ds_packagings_by_licenseplate_material_dd: Inventory_ds_packagings_by_licenseplate_material_ddService;
  public get ds_packagings_by_licenseplate_material_dd(): Inventory_ds_packagings_by_licenseplate_material_ddService {
    if(!this._ds_packagings_by_licenseplate_material_dd) {
      this._ds_packagings_by_licenseplate_material_dd = this.injector.get(Inventory_ds_packagings_by_licenseplate_material_ddService);
    }
    return this._ds_packagings_by_licenseplate_material_dd;
  }
  private _ds_projects_dd: Inventory_ds_projects_ddService;
  public get ds_projects_dd(): Inventory_ds_projects_ddService {
    if(!this._ds_projects_dd) {
      this._ds_projects_dd = this.injector.get(Inventory_ds_projects_ddService);
    }
    return this._ds_projects_dd;
  }
  private _ds_reasoncodes_dd: Inventory_ds_reasoncodes_ddService;
  public get ds_reasoncodes_dd(): Inventory_ds_reasoncodes_ddService {
    if(!this._ds_reasoncodes_dd) {
      this._ds_reasoncodes_dd = this.injector.get(Inventory_ds_reasoncodes_ddService);
    }
    return this._ds_reasoncodes_dd;
  }
  private _ds_serialnumber_weight_widget: Inventory_ds_serialnumber_weight_widgetService;
  public get ds_serialnumber_weight_widget(): Inventory_ds_serialnumber_weight_widgetService {
    if(!this._ds_serialnumber_weight_widget) {
      this._ds_serialnumber_weight_widget = this.injector.get(Inventory_ds_serialnumber_weight_widgetService);
    }
    return this._ds_serialnumber_weight_widget;
  }
  private _ds_serialnumbers_by_inventory_dd: Inventory_ds_serialnumbers_by_inventory_ddService;
  public get ds_serialnumbers_by_inventory_dd(): Inventory_ds_serialnumbers_by_inventory_ddService {
    if(!this._ds_serialnumbers_by_inventory_dd) {
      this._ds_serialnumbers_by_inventory_dd = this.injector.get(Inventory_ds_serialnumbers_by_inventory_ddService);
    }
    return this._ds_serialnumbers_by_inventory_dd;
  }
  private _ds_storage_categories_grid: Inventory_ds_storage_categories_gridService;
  public get ds_storage_categories_grid(): Inventory_ds_storage_categories_gridService {
    if(!this._ds_storage_categories_grid) {
      this._ds_storage_categories_grid = this.injector.get(Inventory_ds_storage_categories_gridService);
    }
    return this._ds_storage_categories_grid;
  }
  private _ds_storage_categories_rules_grid: Inventory_ds_storage_categories_rules_gridService;
  public get ds_storage_categories_rules_grid(): Inventory_ds_storage_categories_rules_gridService {
    if(!this._ds_storage_categories_rules_grid) {
      this._ds_storage_categories_rules_grid = this.injector.get(Inventory_ds_storage_categories_rules_gridService);
    }
    return this._ds_storage_categories_rules_grid;
  }
  private _ds_task_statuses_dd: Inventory_ds_task_statuses_ddService;
  public get ds_task_statuses_dd(): Inventory_ds_task_statuses_ddService {
    if(!this._ds_task_statuses_dd) {
      this._ds_task_statuses_dd = this.injector.get(Inventory_ds_task_statuses_ddService);
    }
    return this._ds_task_statuses_dd;
  }
  private _ds_temperature_categories_dd: Inventory_ds_temperature_categories_ddService;
  public get ds_temperature_categories_dd(): Inventory_ds_temperature_categories_ddService {
    if(!this._ds_temperature_categories_dd) {
      this._ds_temperature_categories_dd = this.injector.get(Inventory_ds_temperature_categories_ddService);
    }
    return this._ds_temperature_categories_dd;
  }
  private _ds_temperature_category_rules_dd: Inventory_ds_temperature_category_rules_ddService;
  public get ds_temperature_category_rules_dd(): Inventory_ds_temperature_category_rules_ddService {
    if(!this._ds_temperature_category_rules_dd) {
      this._ds_temperature_category_rules_dd = this.injector.get(Inventory_ds_temperature_category_rules_ddService);
    }
    return this._ds_temperature_category_rules_dd;
  }
  private _ds_total_available_amount_widget: Inventory_ds_total_available_amount_widgetService;
  public get ds_total_available_amount_widget(): Inventory_ds_total_available_amount_widgetService {
    if(!this._ds_total_available_amount_widget) {
      this._ds_total_available_amount_widget = this.injector.get(Inventory_ds_total_available_amount_widgetService);
    }
    return this._ds_total_available_amount_widget;
  }
  private _ds_total_available_amount_widget_location: Inventory_ds_total_available_amount_widget_locationService;
  public get ds_total_available_amount_widget_location(): Inventory_ds_total_available_amount_widget_locationService {
    if(!this._ds_total_available_amount_widget_location) {
      this._ds_total_available_amount_widget_location = this.injector.get(Inventory_ds_total_available_amount_widget_locationService);
    }
    return this._ds_total_available_amount_widget_location;
  }
  private _ds_total_available_base_amount_widget: Inventory_ds_total_available_base_amount_widgetService;
  public get ds_total_available_base_amount_widget(): Inventory_ds_total_available_base_amount_widgetService {
    if(!this._ds_total_available_base_amount_widget) {
      this._ds_total_available_base_amount_widget = this.injector.get(Inventory_ds_total_available_base_amount_widgetService);
    }
    return this._ds_total_available_base_amount_widget;
  }
  private _ds_total_gross_weight_widget: Inventory_ds_total_gross_weight_widgetService;
  public get ds_total_gross_weight_widget(): Inventory_ds_total_gross_weight_widgetService {
    if(!this._ds_total_gross_weight_widget) {
      this._ds_total_gross_weight_widget = this.injector.get(Inventory_ds_total_gross_weight_widgetService);
    }
    return this._ds_total_gross_weight_widget;
  }
  private _ds_total_packaged_amount_widget: Inventory_ds_total_packaged_amount_widgetService;
  public get ds_total_packaged_amount_widget(): Inventory_ds_total_packaged_amount_widgetService {
    if(!this._ds_total_packaged_amount_widget) {
      this._ds_total_packaged_amount_widget = this.injector.get(Inventory_ds_total_packaged_amount_widgetService);
    }
    return this._ds_total_packaged_amount_widget;
  }
  private _ds_vendorlots_by_licenseplate_dd: Inventory_ds_vendorlots_by_licenseplate_ddService;
  public get ds_vendorlots_by_licenseplate_dd(): Inventory_ds_vendorlots_by_licenseplate_ddService {
    if(!this._ds_vendorlots_by_licenseplate_dd) {
      this._ds_vendorlots_by_licenseplate_dd = this.injector.get(Inventory_ds_vendorlots_by_licenseplate_ddService);
    }
    return this._ds_vendorlots_by_licenseplate_dd;
  }
  private _ds_warehouse_dd: Inventory_ds_warehouse_ddService;
  public get ds_warehouse_dd(): Inventory_ds_warehouse_ddService {
    if(!this._ds_warehouse_dd) {
      this._ds_warehouse_dd = this.injector.get(Inventory_ds_warehouse_ddService);
    }
    return this._ds_warehouse_dd;
  }
  private _ds_warehouses_navigation_grid: Inventory_ds_warehouses_navigation_gridService;
  public get ds_warehouses_navigation_grid(): Inventory_ds_warehouses_navigation_gridService {
    if(!this._ds_warehouses_navigation_grid) {
      this._ds_warehouses_navigation_grid = this.injector.get(Inventory_ds_warehouses_navigation_gridService);
    }
    return this._ds_warehouses_navigation_grid;
  }
  private _license_plate_planned_asn_totals: Inventory_license_plate_planned_asn_totalsService;
  public get license_plate_planned_asn_totals(): Inventory_license_plate_planned_asn_totalsService {
    if(!this._license_plate_planned_asn_totals) {
      this._license_plate_planned_asn_totals = this.injector.get(Inventory_license_plate_planned_asn_totalsService);
    }
    return this._license_plate_planned_asn_totals;
  }
}

