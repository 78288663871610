import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class WorkOrders_ds_get_task_by_taskIdService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { taskId: number }): 
  Promise<{ result: { Id?: number, StatusId?: number, AccessorialTaskProperty?: { ActualAmount?: number, ActualUom?: number, ExpectedAmount?: number, ExpectedUom?: number }, OperationCode?: { Name?: string } } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.taskId)) {
      missingRequiredInParams.push('\'taskId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      taskId: number    }) {
        if(isNil(inParams.taskId)) {
          return true; 
        }
      return false;
    }
}
