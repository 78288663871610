import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { WorkOrders_DatasourceService } from './WorkOrders.datasource.index';

interface IWorkOrders_work_order_reportServiceInParams {
  workOrderId: number}

interface IWorkOrders_work_order_reportServiceData {
  header?: { result?: { Id?: number, CreatedSysDateTime?: string, Description?: string, ExpectedDate?: string, LookupCode?: string, Notes?: string, ReferenceNumber?: string, Project?: { Name?: string, Owner?: { Name?: string } }, Shipment?: { LookupCode?: string }, Status?: { DisplayName?: string }, Warehouse?: { Name?: string } }[] }
  details?: { result?: { Id?: number, CompletedDateTime?: string, Employee?: string, ModifiedSysUser?: string, Notes?: string, OperationCodeId?: number, StartDateTime?: string, WorkOrderId?: number, AccessorialTaskProperty?: { ActualAmount?: number, ExpectedAmount?: number }, OperationCode?: { Name?: string }, Status?: { Name?: string } }[] }
}

@Injectable({ providedIn: 'root' })
export class WorkOrders_work_order_reportService extends ReportBaseService<IWorkOrders_work_order_reportServiceInParams, IWorkOrders_work_order_reportServiceData> {

  protected reportReferenceName = 'work_order_report';
  protected appReferenceName = 'WorkOrders';

  constructor(
    $utils: UtilsService,
    private $datasources: WorkOrders_DatasourceService,
    ) {
      super($utils);
  }

  override throwIfMissingRequiredInParams (inParams: IWorkOrders_work_order_reportServiceInParams) {
  }

  protected async getData(inParams: IWorkOrders_work_order_reportServiceInParams): Promise<IWorkOrders_work_order_reportServiceData> 
  {
    const $utils = this.$utils;
    const $report: { inParams: IWorkOrders_work_order_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IWorkOrders_work_order_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        workOrderId:  $report.inParams.workOrderId 
      };
      
      const dsData = await this.$datasources.WorkOrders.ds_get_work_order_details_by_workOrderId.get(dsParams);
      
      data.header = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        workOrderId:  $report.inParams.workOrderId 
      };
      
      const dsData = await this.$datasources.WorkOrders.ds_get_tasks_details_by_workorderId.get(dsParams);
      
      data.details = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
