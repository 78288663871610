import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootprintApiManager_ds_get_available_inventory_by_lot_and_locationService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { owner?: string, project?: string, warehouse?: string, material?: string }): 
  Promise<{ result: { LocationId?: number, LotId?: number, PackagedId?: number, ActiveAmount?: number, ActivePackagedAmount?: number, AllocatedAmount?: number, AllocatedPackagedAmount?: number, AvailableAmount?: number, AvailablePackagedAmount?: number, CurrentlyAvailableAmount?: number, CurrentlyAvailablePackagedAmount?: number, InactiveAmount?: number, InactivePackagedAmount?: number, IncomingAmount?: number, IncomingPackagedAmount?: number, SoftAllocatedAmount?: number, SoftAllocatedPackagedAmount?: number, SoftIncomingAmount?: number, SoftIncomingPackagedAmount?: number, TotalAmount?: number, TotalPackagedAmount?: number, Location?: { Name?: string, Status?: { Name?: string }, Warehouse?: { Name?: string } }, Lot?: { LookupCode?: string, Status?: { Name?: string }, Material?: { Description?: string, LookupCode?: string, Name?: string, Status?: { Name?: string }, Project?: { LookupCode?: string, Owner?: { LookupCode?: string } } }, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string } }, Packaging?: { ShortName?: string } }[] }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { owner?: string, project?: string, warehouse?: string, material?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { LocationId?: number, LotId?: number, PackagedId?: number, ActiveAmount?: number, ActivePackagedAmount?: number, AllocatedAmount?: number, AllocatedPackagedAmount?: number, AvailableAmount?: number, AvailablePackagedAmount?: number, CurrentlyAvailableAmount?: number, CurrentlyAvailablePackagedAmount?: number, InactiveAmount?: number, InactivePackagedAmount?: number, IncomingAmount?: number, IncomingPackagedAmount?: number, SoftAllocatedAmount?: number, SoftAllocatedPackagedAmount?: number, SoftIncomingAmount?: number, SoftIncomingPackagedAmount?: number, TotalAmount?: number, TotalPackagedAmount?: number, Location?: { Name?: string, Status?: { Name?: string }, Warehouse?: { Name?: string } }, Lot?: { LookupCode?: string, Status?: { Name?: string }, Material?: { Description?: string, LookupCode?: string, Name?: string, Status?: { Name?: string }, Project?: { LookupCode?: string, Owner?: { LookupCode?: string } } }, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string } }, Packaging?: { ShortName?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { owner?: string, project?: string, warehouse?: string, material?: string, $keys: { LocationId?: number, LotId?: number, PackagedId?: number }[] }): 
  Promise<{ result: { LocationId?: number, LotId?: number, PackagedId?: number, ActiveAmount?: number, ActivePackagedAmount?: number, AllocatedAmount?: number, AllocatedPackagedAmount?: number, AvailableAmount?: number, AvailablePackagedAmount?: number, CurrentlyAvailableAmount?: number, CurrentlyAvailablePackagedAmount?: number, InactiveAmount?: number, InactivePackagedAmount?: number, IncomingAmount?: number, IncomingPackagedAmount?: number, SoftAllocatedAmount?: number, SoftAllocatedPackagedAmount?: number, SoftIncomingAmount?: number, SoftIncomingPackagedAmount?: number, TotalAmount?: number, TotalPackagedAmount?: number, Location?: { Name?: string, Status?: { Name?: string }, Warehouse?: { Name?: string } }, Lot?: { LookupCode?: string, Status?: { Name?: string }, Material?: { Description?: string, LookupCode?: string, Name?: string, Status?: { Name?: string }, Project?: { LookupCode?: string, Owner?: { LookupCode?: string } } }, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string } }, Packaging?: { ShortName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      owner?: string, project?: string, warehouse?: string, material?: string    }) {
      return false;
    }
}
