import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './FootprintApiManager.frontend.types'

@Injectable({ providedIn: 'root' })
export class FootprintApiManager_footprint_generic_json_order_schemaService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { example?: boolean, map?: boolean }): Promise<{ DatexOrderInfo?: $frontendTypes.FootprintApiManager.DatexOrderInfo, Addresses?: $frontendTypes.FootprintApiManager.Address[], Shipments?: $frontendTypes.FootprintApiManager.Shipment[], Conveyance?: $frontendTypes.FootprintApiManager.Conveyance, ShipmentTotals?: $frontendTypes.FootprintApiManager.ShipmentTotals, Orders?: $frontendTypes.FootprintApiManager.Order[], OrderLines?: $frontendTypes.FootprintApiManager.OrderLine[], DockAppointments?: $frontendTypes.FootprintApiManager.DockAppointment[], UserDefinedFields?: $frontendTypes.FootprintApiManager.UserDefinedField[], ReasonCodes?: $frontendTypes.FootprintApiManager.ReasonCode[], Material?: $frontendTypes.FootprintApiManager.Material, MaterialPackaging?: $frontendTypes.FootprintApiManager.MaterialPackaging, Lot?: $frontendTypes.FootprintApiManager.Lot, ShippingContainer?: $frontendTypes.FootprintApiManager.ShippingContainer, LicensePlates?: $frontendTypes.FootprintApiManager.LicensePlate[], LicensePlateContents?: $frontendTypes.FootprintApiManager.LicensePlateContent[], SerialNumbers?: $frontendTypes.FootprintApiManager.SerialNumber[], WavelengthConnectorTransmissions?: $frontendTypes.FootprintApiManager.WavelengthConnectorTransmission[], PalletTransactions?: $frontendTypes.FootprintApiManager.PalletTransaction[], AddressEntityTypes?: string[] }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

