import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './FootprintApiManager.frontend.types'

@Injectable({ providedIn: 'root' })
export class FootprintApiManager_storage_adjustmentsService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { payload?: { id?: string, task_id?: number, project_id?: number, task_data?: string, license_plate_data?: string }, action: string, skip?: number, take?: number, date_from?: string, date_to?: string, integration_name: string, task_ids?: number[] }): Promise<{ payload?: { id?: string, task_id?: number, project_id?: number, task_data?: string, license_plate_data?: string, integration_name?: string, created_on?: string }[], success?: boolean, error?: any[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.action)) {
      missingRequiredInParams.push('\'action\'');
    }
    if (isNil(inParams.integration_name)) {
      missingRequiredInParams.push('\'integration_name\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

