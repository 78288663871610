import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './FootprintApiManager.frontend.types'

@Injectable({ providedIn: 'root' })
export class FootprintApiManager_create_purchase_order_lines_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { order_lines: $frontendTypes.FootprintApiManager.input_create_purchase_order_line[] }): Promise<{ order_lines?: $frontendTypes.FootprintApiManager.public_order_line[], errors?: string[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.order_lines)) {
      missingRequiredInParams.push('\'order_lines\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

