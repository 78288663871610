import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { PurchaseOrders_homeComponent } from './PurchaseOrders.home.component';
import { PurchaseOrders_inbound_orders_summary_reportComponent } from './PurchaseOrders.inbound_orders_summary_report.component';
import { PurchaseOrders_inbound_unloading_tally_reportComponent } from './PurchaseOrders.inbound_unloading_tally_report.component';
import { PurchaseOrders_open_putaway_reportComponent } from './PurchaseOrders.open_putaway_report.component';
import { PurchaseOrders_purchase_order_reportComponent } from './PurchaseOrders.purchase_order_report.component';
import { PurchaseOrders_receiving_reportComponent } from './PurchaseOrders.receiving_report.component';


@Injectable({ providedIn: 'root' })
export class PurchaseOrders_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
  ) {
    super(dialog, toastr);
  }

  public PurchaseOrders: PurchaseOrders_ShellService = this;

  public openhome(replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'PurchaseOrders',
        referenceName: 'PurchaseOrders_home',
        component: PurchaseOrders_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openhomeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      PurchaseOrders_homeComponent,
      'PurchaseOrders',
      mode,
      dialogSize
    )
  }
  public openinbound_orders_summary_report(inParams:{ startDate: string, endDate: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Inbound Orders Summary',
        referenceName: 'PurchaseOrders_inbound_orders_summary_report',
        component: PurchaseOrders_inbound_orders_summary_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinbound_orders_summary_reportDialog(
    inParams:{ startDate: string, endDate: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      PurchaseOrders_inbound_orders_summary_reportComponent,
      'Inbound Orders Summary',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinbound_unloading_tally_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Unloading Tally Report',
        referenceName: 'PurchaseOrders_inbound_unloading_tally_report',
        component: PurchaseOrders_inbound_unloading_tally_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinbound_unloading_tally_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      PurchaseOrders_inbound_unloading_tally_reportComponent,
      'Unloading Tally Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openopen_putaway_report(inParams:{ startDate?: string, endDate?: string, ownerId?: number, projectId?: number, warehouseId?: number[] }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Open Putaway Report',
        referenceName: 'PurchaseOrders_open_putaway_report',
        component: PurchaseOrders_open_putaway_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openopen_putaway_reportDialog(
    inParams:{ startDate?: string, endDate?: string, ownerId?: number, projectId?: number, warehouseId?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      PurchaseOrders_open_putaway_reportComponent,
      'Open Putaway Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpurchase_order_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Purchase order report',
        referenceName: 'PurchaseOrders_purchase_order_report',
        component: PurchaseOrders_purchase_order_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpurchase_order_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      PurchaseOrders_purchase_order_reportComponent,
      'Purchase order report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openreceiving_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Receiving Report',
        referenceName: 'PurchaseOrders_receiving_report',
        component: PurchaseOrders_receiving_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openreceiving_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      PurchaseOrders_receiving_reportComponent,
      'Receiving Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'PurchaseOrders_home') {
      const title = 'PurchaseOrders';
      const component = PurchaseOrders_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_inbound_orders_summary_report') {
      const title = 'Inbound Orders Summary';
      const component = PurchaseOrders_inbound_orders_summary_reportComponent;
      const inParams:{ startDate: string, endDate: string } = { startDate: null, endDate: null };
      if (!isNil(params.get('startDate'))) {
        const paramValueString = params.get('startDate');
        // TODO: date
        inParams.startDate = paramValueString;
              }
      if (!isNil(params.get('endDate'))) {
        const paramValueString = params.get('endDate');
        // TODO: date
        inParams.endDate = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_inbound_unloading_tally_report') {
      const title = 'Unloading Tally Report';
      const component = PurchaseOrders_inbound_unloading_tally_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_open_putaway_report') {
      const title = 'Open Putaway Report';
      const component = PurchaseOrders_open_putaway_reportComponent;
      const inParams:{ startDate?: string, endDate?: string, ownerId?: number, projectId?: number, warehouseId?: number[] } = { startDate: null, endDate: null, ownerId: null, projectId: null, warehouseId: [] };
      if (!isNil(params.get('startDate'))) {
        const paramValueString = params.get('startDate');
        // TODO: date
        inParams.startDate = paramValueString;
              }
      if (!isNil(params.get('endDate'))) {
        const paramValueString = params.get('endDate');
        // TODO: date
        inParams.endDate = paramValueString;
              }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_purchase_order_report') {
      const title = 'Purchase order report';
      const component = PurchaseOrders_purchase_order_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PurchaseOrders_receiving_report') {
      const title = 'Receiving Report';
      const component = PurchaseOrders_receiving_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    return result;
  }
}
