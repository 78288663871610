import { Inject, Injectable, Injector } from '@angular/core';


import { Inventory_add_inventory_on_existing_licenseplate_flowService } from './Inventory.flow.index';
import { Inventory_add_serials_inventory_on_existing_licenseplate_flowService } from './Inventory.flow.index';
import { Inventory_calculate_license_plates_weight_flowService } from './Inventory.flow.index';
import { Inventory_change_entity_status_flowService } from './Inventory.flow.index';
import { Inventory_complete_inventory_move_task_flowService } from './Inventory.flow.index';
import { Inventory_complete_licenseplate_move_task_flowService } from './Inventory.flow.index';
import { Inventory_create_container_type_style_flowService } from './Inventory.flow.index';
import { Inventory_create_inventory_on_existing_licenseplate_flowService } from './Inventory.flow.index';
import { Inventory_create_inventory_on_new_licenseplate_flowService } from './Inventory.flow.index';
import { Inventory_create_inventory_status_flowService } from './Inventory.flow.index';
import { Inventory_create_licenseplate_flowService } from './Inventory.flow.index';
import { Inventory_create_reasoncode_flowService } from './Inventory.flow.index';
import { Inventory_create_released_inventory_move_task_flowService } from './Inventory.flow.index';
import { Inventory_create_released_licenseplate_move_task_flowService } from './Inventory.flow.index';
import { Inventory_create_serials_inventory_on_existing_licenseplate_flowService } from './Inventory.flow.index';
import { Inventory_create_serials_inventory_on_new_licenseplate_flowService } from './Inventory.flow.index';
import { Inventory_create_storage_category_flowService } from './Inventory.flow.index';
import { Inventory_create_storage_category_rules_flowService } from './Inventory.flow.index';
import { Inventory_delete_containertype_flowService } from './Inventory.flow.index';
import { Inventory_delete_inventory_status_flowService } from './Inventory.flow.index';
import { Inventory_delete_storage_category_flowService } from './Inventory.flow.index';
import { Inventory_delete_storage_category_rule_flowService } from './Inventory.flow.index';
import { Inventory_get_inventory_licenseplate_snapshot_by_date_projectId_warehouseIdService } from './Inventory.flow.index';
import { Inventory_get_inventory_lot_snaphot_by_date_projectId_warehouseIdService } from './Inventory.flow.index';
import { Inventory_get_inventory_material_snaphot_by_date_projectId_warehouseIdService } from './Inventory.flow.index';
import { Inventory_get_inventory_snaphot_rulesService } from './Inventory.flow.index';
import { Inventory_get_serial_hierarchy_flowService } from './Inventory.flow.index';
import { Inventory_insert_inventory_licenseplate_snapshot_flowService } from './Inventory.flow.index';
import { Inventory_insert_inventory_lot_snapshot_flowService } from './Inventory.flow.index';
import { Inventory_insert_inventory_material_snapshot_flowService } from './Inventory.flow.index';
import { Inventory_insert_inventory_snapshot_rule_flowService } from './Inventory.flow.index';
import { Inventory_is_container_type_deletable_flowService } from './Inventory.flow.index';
import { Inventory_populate_date_number_for_snapshot_tablesService } from './Inventory.flow.index';
import { Inventory_populate_date_number_for_snapshot_tablesCloneService } from './Inventory.flow.index';
import { Inventory_process_inventory_snapshot_rulesService } from './Inventory.flow.index';
import { Inventory_remove_inventory_flowService } from './Inventory.flow.index';
import { Inventory_remove_inventory_snapshot_rule_flowService } from './Inventory.flow.index';
import { Inventory_remove_serials_inventory_flowService } from './Inventory.flow.index';
import { Inventory_set_inventory_on_existing_licenseplate_flowService } from './Inventory.flow.index';
import { Inventory_testing_populate_licenseplate_snapshot_dataService } from './Inventory.flow.index';
import { Inventory_update_inventory_snapshot_fieldsService } from './Inventory.flow.index';
import { Inventory_update_inventory_status_flowService } from './Inventory.flow.index';
import { Inventory_update_storage_categories_flowService } from './Inventory.flow.index';
import { Inventory_update_storage_category_rules_flowService } from './Inventory.flow.index';
import { Inventory_validate_licenseplate_move_flowService } from './Inventory.flow.index';
import { Inventory_validate_location_flowService } from './Inventory.flow.index';

import { $frontendTypes } from './Inventory.frontend.types'

@Injectable({ providedIn: 'root' })
export class Inventory_FlowService {

  constructor(
    private injector: Injector
  ) {
  }

  public Inventory: Inventory_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _add_inventory_on_existing_licenseplate_flow: Inventory_add_inventory_on_existing_licenseplate_flowService;
  public async add_inventory_on_existing_licenseplate_flow(inParams: { licenseplateId: number, lotId: number, packagingId: number, packagedAmount: number, reasoncodeId: number, operationcodeId: number }): Promise< { reasons?: string[], confirm?: boolean }> {
    if(!this._add_inventory_on_existing_licenseplate_flow) { 
      this._add_inventory_on_existing_licenseplate_flow = this.injector.get(Inventory_add_inventory_on_existing_licenseplate_flowService);
    }
    return this._add_inventory_on_existing_licenseplate_flow.run(inParams);
  }
   
   

   
 
  private _add_serials_inventory_on_existing_licenseplate_flow: Inventory_add_serials_inventory_on_existing_licenseplate_flowService;
  public async add_serials_inventory_on_existing_licenseplate_flow(inParams: { lotId: number, packagingId: number, reasoncodeId: number, operationcodeId: number, licenseplateId: number, serialNumbers: { LookupCode?: string, NetWeightValue?: number, GrossWeightValue?: number, WeightUOM?: number, Width?: number, Height?: number, Length?: number, DimensionUOM?: number, NetVolumeValue?: number, GrossVolumeValue?: number, VolumeUOM?: number }[] }): Promise< { reasons?: string[], confirm?: boolean, licenseplateId?: number }> {
    if(!this._add_serials_inventory_on_existing_licenseplate_flow) { 
      this._add_serials_inventory_on_existing_licenseplate_flow = this.injector.get(Inventory_add_serials_inventory_on_existing_licenseplate_flowService);
    }
    return this._add_serials_inventory_on_existing_licenseplate_flow.run(inParams);
  }
   
   

   
 
  private _calculate_license_plates_weight_flow: Inventory_calculate_license_plates_weight_flowService;
  public async calculate_license_plates_weight_flow(inParams: { license_plate_ids: number[] }): Promise< { reasons?: string[] }> {
    if(!this._calculate_license_plates_weight_flow) { 
      this._calculate_license_plates_weight_flow = this.injector.get(Inventory_calculate_license_plates_weight_flowService);
    }
    return this._calculate_license_plates_weight_flow.run(inParams);
  }
   
   

   
 
  private _change_entity_status_flow: Inventory_change_entity_status_flowService;
  public async change_entity_status_flow(inParams: { entityName: string, entityId: number, targetStatusId: number, reasonCodeIds?: number[], parentTaskId?: number }): Promise< { reasons?: string[] }> {
    if(!this._change_entity_status_flow) { 
      this._change_entity_status_flow = this.injector.get(Inventory_change_entity_status_flowService);
    }
    return this._change_entity_status_flow.run(inParams);
  }
   
   

   
 
  private _complete_inventory_move_task_flow: Inventory_complete_inventory_move_task_flowService;
  public async complete_inventory_move_task_flow(inParams: { sourceLicenseplateId: number, lotId: number, packagingId: number, packagedAmount: number, targetLicenseplate: { id?: number, lookupcode?: string }, notes?: string, reasoncodeId?: number, targetLocationId: number, serialNumberIds?: number[] }): Promise<void> {
    if(!this._complete_inventory_move_task_flow) { 
      this._complete_inventory_move_task_flow = this.injector.get(Inventory_complete_inventory_move_task_flowService);
    }
    return this._complete_inventory_move_task_flow.run(inParams);
  }
   
   

   
 
  private _complete_licenseplate_move_task_flow: Inventory_complete_licenseplate_move_task_flowService;
  public async complete_licenseplate_move_task_flow(inParams: { licenseplateId: number, targetLocationId: number, user?: string, notes?: string, taskId?: number, reasoncodeId?: number }): Promise< { reasons?: string[], taskId?: number }> {
    if(!this._complete_licenseplate_move_task_flow) { 
      this._complete_licenseplate_move_task_flow = this.injector.get(Inventory_complete_licenseplate_move_task_flowService);
    }
    return this._complete_licenseplate_move_task_flow.run(inParams);
  }
   
   

   
 
  private _create_container_type_style_flow: Inventory_create_container_type_style_flowService;
  public async create_container_type_style_flow(inParams: { containerTypeName: string }): Promise< { reasons?: string[], containerTypeStyleId?: number }> {
    if(!this._create_container_type_style_flow) { 
      this._create_container_type_style_flow = this.injector.get(Inventory_create_container_type_style_flowService);
    }
    return this._create_container_type_style_flow.run(inParams);
  }
   
   

   
 
  private _create_inventory_on_existing_licenseplate_flow: Inventory_create_inventory_on_existing_licenseplate_flowService;
  public async create_inventory_on_existing_licenseplate_flow(inParams: { licenseplateId: number, lotId: number, packagingId: number, packagedAmount: number, reasoncodeId: number, operationcodeId: number }): Promise< { reasons?: string[], confirm?: boolean }> {
    if(!this._create_inventory_on_existing_licenseplate_flow) { 
      this._create_inventory_on_existing_licenseplate_flow = this.injector.get(Inventory_create_inventory_on_existing_licenseplate_flowService);
    }
    return this._create_inventory_on_existing_licenseplate_flow.run(inParams);
  }
   
   

   
 
  private _create_inventory_on_new_licenseplate_flow: Inventory_create_inventory_on_new_licenseplate_flowService;
  public async create_inventory_on_new_licenseplate_flow(inParams: { licenseplateLookupcode: string, lotId: number, packagingId: number, packagedAmount: number, reasoncodeId: number, operationcodeId: number, locationId: number }): Promise< { reasons?: string[], confirm?: boolean, licenseplateId?: number }> {
    if(!this._create_inventory_on_new_licenseplate_flow) { 
      this._create_inventory_on_new_licenseplate_flow = this.injector.get(Inventory_create_inventory_on_new_licenseplate_flowService);
    }
    return this._create_inventory_on_new_licenseplate_flow.run(inParams);
  }
   
   

   
 
  private _create_inventory_status_flow: Inventory_create_inventory_status_flowService;
  public async create_inventory_status_flow(inParams: { statusName: string }): Promise< { reasons?: string[] }> {
    if(!this._create_inventory_status_flow) { 
      this._create_inventory_status_flow = this.injector.get(Inventory_create_inventory_status_flowService);
    }
    return this._create_inventory_status_flow.run(inParams);
  }
   
   

   
 
  private _create_licenseplate_flow: Inventory_create_licenseplate_flowService;
  public async create_licenseplate_flow(inParams: { archived?: boolean, dimensionsUomId: number, grossVolume?: number, grossWeight?: number, height?: number, length?: number, licensePlateContainerTypeId?: number, locationId: number, lookupCode?: string, maximumWeight?: number, netVolume?: number, netWeight?: number, parentId?: number, shipmentId?: number, shippingContainerId?: number, statusId: number, typeId: number, volumeUomId: number, warehouseId: number, weightUomId: number, width?: number }): Promise< { reasons?: string[], licensePlateId?: number }> {
    if(!this._create_licenseplate_flow) { 
      this._create_licenseplate_flow = this.injector.get(Inventory_create_licenseplate_flowService);
    }
    return this._create_licenseplate_flow.run(inParams);
  }
   
   

   
 
  private _create_reasoncode_flow: Inventory_create_reasoncode_flowService;
  public async create_reasoncode_flow(inParams: { properties?: any }): Promise< { reasons?: string[], reasonCodeId?: number }> {
    if(!this._create_reasoncode_flow) { 
      this._create_reasoncode_flow = this.injector.get(Inventory_create_reasoncode_flowService);
    }
    return this._create_reasoncode_flow.run(inParams);
  }
   
   

   
 
  private _create_released_inventory_move_task_flow: Inventory_create_released_inventory_move_task_flowService;
  public async create_released_inventory_move_task_flow(inParams: { sourceLicenseplateId: number, lotId: number, packagingId: number, packagedAmount: number, targetLicenseplate: { id?: number, lookupcode?: string }, notes?: string, reasoncodeId?: number, targetLocationId: number, serialNumberIds?: number[] }): Promise< { reasons?: string[] }> {
    if(!this._create_released_inventory_move_task_flow) { 
      this._create_released_inventory_move_task_flow = this.injector.get(Inventory_create_released_inventory_move_task_flowService);
    }
    return this._create_released_inventory_move_task_flow.run(inParams);
  }
   
   

   
 
  private _create_released_licenseplate_move_task_flow: Inventory_create_released_licenseplate_move_task_flowService;
  public async create_released_licenseplate_move_task_flow(inParams: { licenseplateId: number, targetLocationId?: number, user?: string, notes?: string, reasoncodeId?: number }): Promise< { reasons?: string[], taskId?: number }> {
    if(!this._create_released_licenseplate_move_task_flow) { 
      this._create_released_licenseplate_move_task_flow = this.injector.get(Inventory_create_released_licenseplate_move_task_flowService);
    }
    return this._create_released_licenseplate_move_task_flow.run(inParams);
  }
   
   

   
 
  private _create_serials_inventory_on_existing_licenseplate_flow: Inventory_create_serials_inventory_on_existing_licenseplate_flowService;
  public async create_serials_inventory_on_existing_licenseplate_flow(inParams: { lotId: number, packagingId: number, reasoncodeId: number, operationcodeId: number, licenseplateId: number, serialNumbers: { LookupCode?: string, NetWeightValue?: number, GrossWeightValue?: number, WeightUOM?: number, Width?: number, Height?: number, Length?: number, DimensionUOM?: number, NetVolumeValue?: number, GrossVolumeValue?: number, VolumeUOM?: number }[] }): Promise< { reasons?: string[], confirm?: boolean, licenseplateId?: number }> {
    if(!this._create_serials_inventory_on_existing_licenseplate_flow) { 
      this._create_serials_inventory_on_existing_licenseplate_flow = this.injector.get(Inventory_create_serials_inventory_on_existing_licenseplate_flowService);
    }
    return this._create_serials_inventory_on_existing_licenseplate_flow.run(inParams);
  }
   
   

   
 
  private _create_serials_inventory_on_new_licenseplate_flow: Inventory_create_serials_inventory_on_new_licenseplate_flowService;
  public async create_serials_inventory_on_new_licenseplate_flow(inParams: { licenseplateLookupcode: string, lotId: number, packagingId: number, reasoncodeId: number, operationcodeId: number, locationId: number, serialNumbers: { LookupCode?: string, NetWeightValue?: number, GrossWeightValue?: number, WeightUOM?: number, Width?: number, Height?: number, Length?: number, DimensionUOM?: number, NetVolumeValue?: number, GrossVolumeValue?: number, VolumeUOM?: number }[] }): Promise< { reasons?: string[], confirm?: boolean, licenseplateId?: number }> {
    if(!this._create_serials_inventory_on_new_licenseplate_flow) { 
      this._create_serials_inventory_on_new_licenseplate_flow = this.injector.get(Inventory_create_serials_inventory_on_new_licenseplate_flowService);
    }
    return this._create_serials_inventory_on_new_licenseplate_flow.run(inParams);
  }
   
   

   
 
  private _create_storage_category_flow: Inventory_create_storage_category_flowService;
  public async create_storage_category_flow(inParams: { lookupCode: string }): Promise< { reasons?: string[] }> {
    if(!this._create_storage_category_flow) { 
      this._create_storage_category_flow = this.injector.get(Inventory_create_storage_category_flowService);
    }
    return this._create_storage_category_flow.run(inParams);
  }
   
   

   
 
  private _create_storage_category_rules_flow: Inventory_create_storage_category_rules_flowService;
  public async create_storage_category_rules_flow(inParams: { ruleName: string }): Promise< { reasons?: string[] }> {
    if(!this._create_storage_category_rules_flow) { 
      this._create_storage_category_rules_flow = this.injector.get(Inventory_create_storage_category_rules_flowService);
    }
    return this._create_storage_category_rules_flow.run(inParams);
  }
   
   

   
 
  private _delete_containertype_flow: Inventory_delete_containertype_flowService;
  public async delete_containertype_flow(inParams: { containerTypeId: number }): Promise< { reason?: string }> {
    if(!this._delete_containertype_flow) { 
      this._delete_containertype_flow = this.injector.get(Inventory_delete_containertype_flowService);
    }
    return this._delete_containertype_flow.run(inParams);
  }
   
   

   
 
  private _delete_inventory_status_flow: Inventory_delete_inventory_status_flowService;
  public async delete_inventory_status_flow(inParams: { statusId: number }): Promise< { reasons?: string[] }> {
    if(!this._delete_inventory_status_flow) { 
      this._delete_inventory_status_flow = this.injector.get(Inventory_delete_inventory_status_flowService);
    }
    return this._delete_inventory_status_flow.run(inParams);
  }
   
   

   
 
  private _delete_storage_category_flow: Inventory_delete_storage_category_flowService;
  public async delete_storage_category_flow(inParams: { categoryId: number }): Promise< { reasons?: string[] }> {
    if(!this._delete_storage_category_flow) { 
      this._delete_storage_category_flow = this.injector.get(Inventory_delete_storage_category_flowService);
    }
    return this._delete_storage_category_flow.run(inParams);
  }
   
   

   
 
  private _delete_storage_category_rule_flow: Inventory_delete_storage_category_rule_flowService;
  public async delete_storage_category_rule_flow(inParams: { ruleId: number }): Promise< { reasons?: string[] }> {
    if(!this._delete_storage_category_rule_flow) { 
      this._delete_storage_category_rule_flow = this.injector.get(Inventory_delete_storage_category_rule_flowService);
    }
    return this._delete_storage_category_rule_flow.run(inParams);
  }
   
   

   
 
  private _get_inventory_licenseplate_snapshot_by_date_projectId_warehouseId: Inventory_get_inventory_licenseplate_snapshot_by_date_projectId_warehouseIdService;
  public async get_inventory_licenseplate_snapshot_by_date_projectId_warehouseId(inParams: { captureDate: string, projectId?: number, warehouseId?: number, materialIds?: number[], fullTextSearch?: string, skip?: number, take?: number }): Promise< { list?: { capture_date?: string, lot_id?: number, packaging_id?: number, warehouse_id?: number, total_packaged_amount?: number, total_base_amount?: number, project_id?: number, material_lookupcode?: string, packaging_shortname?: string, warehouse_name?: string, total_net_weight?: number, total_gross_weight?: number, weight_uom_id?: number, weight_uom_name?: string, lot_lookupcode?: string, material_id?: number, licenseplate_id?: number, licenseplate_lookupcode?: string, serial_numbers?: string, receive_date?: string, last_receive_date?: string, location_id?: number, location_name?: string, date_number?: number }[], count?: number }> {
    if(!this._get_inventory_licenseplate_snapshot_by_date_projectId_warehouseId) { 
      this._get_inventory_licenseplate_snapshot_by_date_projectId_warehouseId = this.injector.get(Inventory_get_inventory_licenseplate_snapshot_by_date_projectId_warehouseIdService);
    }
    return this._get_inventory_licenseplate_snapshot_by_date_projectId_warehouseId.run(inParams);
  }
   
   

   
 
  private _get_inventory_lot_snaphot_by_date_projectId_warehouseId: Inventory_get_inventory_lot_snaphot_by_date_projectId_warehouseIdService;
  public async get_inventory_lot_snaphot_by_date_projectId_warehouseId(inParams: { captureDate: string, projectId: number, warehouseId: number, materialIds?: number[], fullTextSearch?: string, skip?: number, take?: number }): Promise< { list?: { capture_date?: string, lot_id?: number, packaging_id?: number, warehouse_id?: number, total_packaged_amount?: number, total_base_amount?: number, project_id?: number, material_lookupcode?: string, packaging_shortname?: string, warehouse_name?: string, total_net_weight?: number, total_gross_weight?: number, weight_uom_id?: number, weight_uom_name?: string, lot_lookupcode?: string, material_id?: number, receive_date?: string, last_receive_date?: string }[], count?: number }> {
    if(!this._get_inventory_lot_snaphot_by_date_projectId_warehouseId) { 
      this._get_inventory_lot_snaphot_by_date_projectId_warehouseId = this.injector.get(Inventory_get_inventory_lot_snaphot_by_date_projectId_warehouseIdService);
    }
    return this._get_inventory_lot_snaphot_by_date_projectId_warehouseId.run(inParams);
  }
   
   

   
 
  private _get_inventory_material_snaphot_by_date_projectId_warehouseId: Inventory_get_inventory_material_snaphot_by_date_projectId_warehouseIdService;
  public async get_inventory_material_snaphot_by_date_projectId_warehouseId(inParams: { captureDate: string, projectId: number, warehouseId: number, materialIds?: number[], fullTextSearch?: string }): Promise< { list?: { capture_date?: string, material_id?: number, packaging_id?: number, warehouse_id?: number, total_packaged_amount?: number, total_base_amount?: number, project_id?: number, material_lookupcode?: string, packaging_shortname?: string, warehouse_name?: string, total_net_weight?: number, total_gross_weight?: number, weight_uom_id?: number, weight_uom_name?: string }[] }> {
    if(!this._get_inventory_material_snaphot_by_date_projectId_warehouseId) { 
      this._get_inventory_material_snaphot_by_date_projectId_warehouseId = this.injector.get(Inventory_get_inventory_material_snaphot_by_date_projectId_warehouseIdService);
    }
    return this._get_inventory_material_snaphot_by_date_projectId_warehouseId.run(inParams);
  }
   
   

   
 
  private _get_inventory_snaphot_rules: Inventory_get_inventory_snaphot_rulesService;
  public async get_inventory_snaphot_rules(inParams: {  }): Promise< { list?: { owner_id?: number, project_id?: number, warehouse_id?: number, snapshot_level?: string, owner_lookupcode?: string, project_lookupcode?: string, warehouse_name?: string, id?: number }[] }> {
    if(!this._get_inventory_snaphot_rules) { 
      this._get_inventory_snaphot_rules = this.injector.get(Inventory_get_inventory_snaphot_rulesService);
    }
    return this._get_inventory_snaphot_rules.run(inParams);
  }
   
   

   
 
  private _get_serial_hierarchy_flow: Inventory_get_serial_hierarchy_flowService;
  public async get_serial_hierarchy_flow(inParams: { serial_number_id: number }): Promise< { ParentSerialNumber?: any, ChildSerialNumbers?: any[] }> {
    if(!this._get_serial_hierarchy_flow) { 
      this._get_serial_hierarchy_flow = this.injector.get(Inventory_get_serial_hierarchy_flowService);
    }
    return this._get_serial_hierarchy_flow.run(inParams);
  }
   
   

   
 
  private _insert_inventory_licenseplate_snapshot_flow: Inventory_insert_inventory_licenseplate_snapshot_flowService;
  public async insert_inventory_licenseplate_snapshot_flow(inParams: { projectId: number, warehouseId: number }): Promise<void> {
    if(!this._insert_inventory_licenseplate_snapshot_flow) { 
      this._insert_inventory_licenseplate_snapshot_flow = this.injector.get(Inventory_insert_inventory_licenseplate_snapshot_flowService);
    }
    return this._insert_inventory_licenseplate_snapshot_flow.run(inParams);
  }
   
   

   
 
  private _insert_inventory_lot_snapshot_flow: Inventory_insert_inventory_lot_snapshot_flowService;
  public async insert_inventory_lot_snapshot_flow(inParams: { projectId: number, warehouseId: number }): Promise<void> {
    if(!this._insert_inventory_lot_snapshot_flow) { 
      this._insert_inventory_lot_snapshot_flow = this.injector.get(Inventory_insert_inventory_lot_snapshot_flowService);
    }
    return this._insert_inventory_lot_snapshot_flow.run(inParams);
  }
   
   

   
 
  private _insert_inventory_material_snapshot_flow: Inventory_insert_inventory_material_snapshot_flowService;
  public async insert_inventory_material_snapshot_flow(inParams: { projectId: number, warehouseId: number }): Promise<void> {
    if(!this._insert_inventory_material_snapshot_flow) { 
      this._insert_inventory_material_snapshot_flow = this.injector.get(Inventory_insert_inventory_material_snapshot_flowService);
    }
    return this._insert_inventory_material_snapshot_flow.run(inParams);
  }
   
   

   
 
  private _insert_inventory_snapshot_rule_flow: Inventory_insert_inventory_snapshot_rule_flowService;
  public async insert_inventory_snapshot_rule_flow(inParams: { ownerId: number, projectId: number, warehouseId: number, snapshotLevel: string, ownerLookupcode: string, projectLookupcode: string, warehouseName: string }): Promise<void> {
    if(!this._insert_inventory_snapshot_rule_flow) { 
      this._insert_inventory_snapshot_rule_flow = this.injector.get(Inventory_insert_inventory_snapshot_rule_flowService);
    }
    return this._insert_inventory_snapshot_rule_flow.run(inParams);
  }
   
   

   
 
  private _is_container_type_deletable_flow: Inventory_is_container_type_deletable_flowService;
  public async is_container_type_deletable_flow(inParams: { containerTypeId: number }): Promise< { reasons?: string[] }> {
    if(!this._is_container_type_deletable_flow) { 
      this._is_container_type_deletable_flow = this.injector.get(Inventory_is_container_type_deletable_flowService);
    }
    return this._is_container_type_deletable_flow.run(inParams);
  }
   
   

   
 
  private _populate_date_number_for_snapshot_tables: Inventory_populate_date_number_for_snapshot_tablesService;
  public async populate_date_number_for_snapshot_tables(inParams: {  }): Promise< { count?: number, output?: any[] }> {
    if(!this._populate_date_number_for_snapshot_tables) { 
      this._populate_date_number_for_snapshot_tables = this.injector.get(Inventory_populate_date_number_for_snapshot_tablesService);
    }
    return this._populate_date_number_for_snapshot_tables.run(inParams);
  }
   
   

   
 
  private _populate_date_number_for_snapshot_tablesClone: Inventory_populate_date_number_for_snapshot_tablesCloneService;
  public async populate_date_number_for_snapshot_tablesClone(inParams: {  }): Promise< { count?: number, output?: any[] }> {
    if(!this._populate_date_number_for_snapshot_tablesClone) { 
      this._populate_date_number_for_snapshot_tablesClone = this.injector.get(Inventory_populate_date_number_for_snapshot_tablesCloneService);
    }
    return this._populate_date_number_for_snapshot_tablesClone.run(inParams);
  }
   
   

   
 
  private _process_inventory_snapshot_rules: Inventory_process_inventory_snapshot_rulesService;
  public async process_inventory_snapshot_rules(inParams: {  }): Promise< { messages?: any }> {
    if(!this._process_inventory_snapshot_rules) { 
      this._process_inventory_snapshot_rules = this.injector.get(Inventory_process_inventory_snapshot_rulesService);
    }
    return this._process_inventory_snapshot_rules.run(inParams);
  }
   
   

   
 
  private _remove_inventory_flow: Inventory_remove_inventory_flowService;
  public async remove_inventory_flow(inParams: { licenseplateId: number, lotId: number, packagingId: number, packagedAmount: number, reasoncodeId: number, operationcodeId: number }): Promise< { reasons?: string[], confirm?: boolean }> {
    if(!this._remove_inventory_flow) { 
      this._remove_inventory_flow = this.injector.get(Inventory_remove_inventory_flowService);
    }
    return this._remove_inventory_flow.run(inParams);
  }
   
   

   
 
  private _remove_inventory_snapshot_rule_flow: Inventory_remove_inventory_snapshot_rule_flowService;
  public async remove_inventory_snapshot_rule_flow(inParams: { id: string }): Promise<void> {
    if(!this._remove_inventory_snapshot_rule_flow) { 
      this._remove_inventory_snapshot_rule_flow = this.injector.get(Inventory_remove_inventory_snapshot_rule_flowService);
    }
    return this._remove_inventory_snapshot_rule_flow.run(inParams);
  }
   
   

   
 
  private _remove_serials_inventory_flow: Inventory_remove_serials_inventory_flowService;
  public async remove_serials_inventory_flow(inParams: { lotId: number, packagingId: number, reasoncodeId: number, operationcodeId: number, licenseplateId: number, serialNumberIds: number[] }): Promise< { reasons?: string[], confirm?: boolean, licenseplateId?: number }> {
    if(!this._remove_serials_inventory_flow) { 
      this._remove_serials_inventory_flow = this.injector.get(Inventory_remove_serials_inventory_flowService);
    }
    return this._remove_serials_inventory_flow.run(inParams);
  }
   
   

   
 
  private _set_inventory_on_existing_licenseplate_flow: Inventory_set_inventory_on_existing_licenseplate_flowService;
  public async set_inventory_on_existing_licenseplate_flow(inParams: { licenseplateId: number, lotId: number, packagingId: number, packagedAmount: number, reasoncodeId: number, operationcodeId: number }): Promise< { reasons?: string[], confirm?: boolean }> {
    if(!this._set_inventory_on_existing_licenseplate_flow) { 
      this._set_inventory_on_existing_licenseplate_flow = this.injector.get(Inventory_set_inventory_on_existing_licenseplate_flowService);
    }
    return this._set_inventory_on_existing_licenseplate_flow.run(inParams);
  }
   
   

   
 
  private _testing_populate_licenseplate_snapshot_data: Inventory_testing_populate_licenseplate_snapshot_dataService;
  public async testing_populate_licenseplate_snapshot_data(inParams: { purge?: string, run?: string }): Promise<void> {
    if(!this._testing_populate_licenseplate_snapshot_data) { 
      this._testing_populate_licenseplate_snapshot_data = this.injector.get(Inventory_testing_populate_licenseplate_snapshot_dataService);
    }
    return this._testing_populate_licenseplate_snapshot_data.run(inParams);
  }
   
   

   
 
  private _update_inventory_snapshot_fields: Inventory_update_inventory_snapshot_fieldsService;
  public async update_inventory_snapshot_fields(inParams: { force?: boolean }): Promise< { messages?: any[] }> {
    if(!this._update_inventory_snapshot_fields) { 
      this._update_inventory_snapshot_fields = this.injector.get(Inventory_update_inventory_snapshot_fieldsService);
    }
    return this._update_inventory_snapshot_fields.run(inParams);
  }
   
   

   
 
  private _update_inventory_status_flow: Inventory_update_inventory_status_flowService;
  public async update_inventory_status_flow(inParams: { statusId: number, statusName: string }): Promise< { reasons?: string[] }> {
    if(!this._update_inventory_status_flow) { 
      this._update_inventory_status_flow = this.injector.get(Inventory_update_inventory_status_flowService);
    }
    return this._update_inventory_status_flow.run(inParams);
  }
   
   

   
 
  private _update_storage_categories_flow: Inventory_update_storage_categories_flowService;
  public async update_storage_categories_flow(inParams: { lookupCode: string, categoryId: number }): Promise< { reasons?: string[] }> {
    if(!this._update_storage_categories_flow) { 
      this._update_storage_categories_flow = this.injector.get(Inventory_update_storage_categories_flowService);
    }
    return this._update_storage_categories_flow.run(inParams);
  }
   
   

   
 
  private _update_storage_category_rules_flow: Inventory_update_storage_category_rules_flowService;
  public async update_storage_category_rules_flow(inParams: { ruleId: number, ruleName: string }): Promise< { reasons?: string[] }> {
    if(!this._update_storage_category_rules_flow) { 
      this._update_storage_category_rules_flow = this.injector.get(Inventory_update_storage_category_rules_flowService);
    }
    return this._update_storage_category_rules_flow.run(inParams);
  }
   
   

   
 
  private _validate_licenseplate_move_flow: Inventory_validate_licenseplate_move_flowService;
  public async validate_licenseplate_move_flow(inParams: { licenseplateId: number, targetLocationId?: number }): Promise< { isValid?: boolean, reasons?: string[] }> {
    if(!this._validate_licenseplate_move_flow) { 
      this._validate_licenseplate_move_flow = this.injector.get(Inventory_validate_licenseplate_move_flowService);
    }
    return this._validate_licenseplate_move_flow.run(inParams);
  }
   
   

   
 
  private _validate_location_flow: Inventory_validate_location_flowService;
  public async validate_location_flow(inParams: { lotId: number, locationId: number, licensePlateId?: number }): Promise<void> {
    if(!this._validate_location_flow) { 
      this._validate_location_flow = this.injector.get(Inventory_validate_location_flowService);
    }
    return this._validate_location_flow.run(inParams);
  }
   
   

   
}
