import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Inventory_ds_get_inventory_by_licenseplateIdService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { licenseplateId: number }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { Id?: number, LookupCode?: string }, Material?: { Id?: number, LookupCode?: string } }, Packaged?: { Id?: number, ShortName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.licenseplateId)) {
      missingRequiredInParams.push('\'licenseplateId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { licenseplateId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { Id?: number, LookupCode?: string }, Material?: { Id?: number, LookupCode?: string } }, Packaged?: { Id?: number, ShortName?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.licenseplateId)) {
      missingRequiredInParams.push('\'licenseplateId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { licenseplateId: number, $keys: { LicensePlateId?: number, LotId?: number, PackagedId?: number }[] }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { Id?: number, LookupCode?: string }, Material?: { Id?: number, LookupCode?: string } }, Packaged?: { Id?: number, ShortName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.licenseplateId)) {
      missingRequiredInParams.push('\'licenseplateId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      licenseplateId: number    }) {
        if(isNil(inParams.licenseplateId)) {
          return true; 
        }
      return false;
    }
}
