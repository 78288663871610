import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './FootprintApiManager.frontend.types'

@Injectable({ providedIn: 'root' })
export class FootprintApiManager_get_return_notificationsService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { order_ids: number[] }): Promise<{ return_notifications?: { date?: string, employee?: string, order?: string, contact?: $frontendTypes.FootprintApiManager.public_contact, return_items?: { material?: string, quantity?: number, packaging?: string, serialized?: boolean, serial_number?: string }[], order_id?: number }[], errors?: string[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.order_ids)) {
      missingRequiredInParams.push('\'order_ids\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

