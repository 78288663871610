import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';
import { Cartonization_DatasourceService } from './Cartonization.datasource.index';
import { TransloadOrders_DatasourceService } from './TransloadOrders.datasource.index';
import { WorkOrders_DatasourceService } from './WorkOrders.datasource.index';
import { Notifications_DatasourceService } from './Notifications.datasource.index';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { Reports_DatasourceService } from './Reports.datasource.index';
import { ExcelOrderImport_DatasourceService } from './ExcelOrderImport.datasource.index';
import { PurchaseOrders_DatasourceService } from './PurchaseOrders.datasource.index';
import { SalesOrders_DatasourceService } from './SalesOrders.datasource.index';
import { Inventory_DatasourceService } from './Inventory.datasource.index';
import { FootprintApiManager_DatasourceService } from './FootprintApiManager.datasource.index';
import { PrintNode_DatasourceService } from './PrintNode.datasource.index';

import { app_custom_ds_account_contact_lookupService } from './app.datasource.index';
import { app_custom_ds_freight_billing_order_detailsService } from './app.datasource.index';
import { app_custom_ds_freight_billing_order_scheduler_listService } from './app.datasource.index';
import { app_custom_ds_get_account_by_lookupcodeService } from './app.datasource.index';
import { app_custom_ds_get_carrier_id_by_nameService } from './app.datasource.index';
import { app_custom_ds_get_lots_for_order_importService } from './app.datasource.index';
import { app_custom_ds_get_order_class_id_by_nameService } from './app.datasource.index';
import { app_custom_ds_get_orders_by_lookupcodes_types_projects_warehousesService } from './app.datasource.index';
import { app_custom_ds_get_picking_tasks_by_shipmentIdService } from './app.datasource.index';
import { app_custom_ds_get_project_lookup_by_nameService } from './app.datasource.index';
import { app_custom_ds_get_transloadId_by_orderIdService } from './app.datasource.index';
import { app_custom_get_orderlines_by_orderidService } from './app.datasource.index';
import { app_ds_get_inventory_by_project_lookupService } from './app.datasource.index';
import { app_ds_get_warehousesService } from './app.datasource.index';

@Injectable({ providedIn: 'root' })
export class app_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
    this.Cartonization = this.injector.get(Cartonization_DatasourceService);
    this.TransloadOrders = this.injector.get(TransloadOrders_DatasourceService);
    this.WorkOrders = this.injector.get(WorkOrders_DatasourceService);
    this.Notifications = this.injector.get(Notifications_DatasourceService);
    this.Invoices = this.injector.get(Invoices_DatasourceService);
    this.Reports = this.injector.get(Reports_DatasourceService);
    this.ExcelOrderImport = this.injector.get(ExcelOrderImport_DatasourceService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_DatasourceService);
    this.SalesOrders = this.injector.get(SalesOrders_DatasourceService);
    this.Inventory = this.injector.get(Inventory_DatasourceService);
    this.FootprintApiManager = this.injector.get(FootprintApiManager_DatasourceService);
    this.PrintNode = this.injector.get(PrintNode_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
    public Cartonization: Cartonization_DatasourceService;
    public TransloadOrders: TransloadOrders_DatasourceService;
    public WorkOrders: WorkOrders_DatasourceService;
    public Notifications: Notifications_DatasourceService;
    public Invoices: Invoices_DatasourceService;
    public Reports: Reports_DatasourceService;
    public ExcelOrderImport: ExcelOrderImport_DatasourceService;
    public PurchaseOrders: PurchaseOrders_DatasourceService;
    public SalesOrders: SalesOrders_DatasourceService;
    public Inventory: Inventory_DatasourceService;
    public FootprintApiManager: FootprintApiManager_DatasourceService;
    public PrintNode: PrintNode_DatasourceService;
  public app: app_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _custom_ds_account_contact_lookup: app_custom_ds_account_contact_lookupService;
  public get custom_ds_account_contact_lookup(): app_custom_ds_account_contact_lookupService {
    if(!this._custom_ds_account_contact_lookup) {
      this._custom_ds_account_contact_lookup = this.injector.get(app_custom_ds_account_contact_lookupService);
    }
    return this._custom_ds_account_contact_lookup;
  }
  private _custom_ds_freight_billing_order_details: app_custom_ds_freight_billing_order_detailsService;
  public get custom_ds_freight_billing_order_details(): app_custom_ds_freight_billing_order_detailsService {
    if(!this._custom_ds_freight_billing_order_details) {
      this._custom_ds_freight_billing_order_details = this.injector.get(app_custom_ds_freight_billing_order_detailsService);
    }
    return this._custom_ds_freight_billing_order_details;
  }
  private _custom_ds_freight_billing_order_scheduler_list: app_custom_ds_freight_billing_order_scheduler_listService;
  public get custom_ds_freight_billing_order_scheduler_list(): app_custom_ds_freight_billing_order_scheduler_listService {
    if(!this._custom_ds_freight_billing_order_scheduler_list) {
      this._custom_ds_freight_billing_order_scheduler_list = this.injector.get(app_custom_ds_freight_billing_order_scheduler_listService);
    }
    return this._custom_ds_freight_billing_order_scheduler_list;
  }
  private _custom_ds_get_account_by_lookupcode: app_custom_ds_get_account_by_lookupcodeService;
  public get custom_ds_get_account_by_lookupcode(): app_custom_ds_get_account_by_lookupcodeService {
    if(!this._custom_ds_get_account_by_lookupcode) {
      this._custom_ds_get_account_by_lookupcode = this.injector.get(app_custom_ds_get_account_by_lookupcodeService);
    }
    return this._custom_ds_get_account_by_lookupcode;
  }
  private _custom_ds_get_carrier_id_by_name: app_custom_ds_get_carrier_id_by_nameService;
  public get custom_ds_get_carrier_id_by_name(): app_custom_ds_get_carrier_id_by_nameService {
    if(!this._custom_ds_get_carrier_id_by_name) {
      this._custom_ds_get_carrier_id_by_name = this.injector.get(app_custom_ds_get_carrier_id_by_nameService);
    }
    return this._custom_ds_get_carrier_id_by_name;
  }
  private _custom_ds_get_lots_for_order_import: app_custom_ds_get_lots_for_order_importService;
  public get custom_ds_get_lots_for_order_import(): app_custom_ds_get_lots_for_order_importService {
    if(!this._custom_ds_get_lots_for_order_import) {
      this._custom_ds_get_lots_for_order_import = this.injector.get(app_custom_ds_get_lots_for_order_importService);
    }
    return this._custom_ds_get_lots_for_order_import;
  }
  private _custom_ds_get_order_class_id_by_name: app_custom_ds_get_order_class_id_by_nameService;
  public get custom_ds_get_order_class_id_by_name(): app_custom_ds_get_order_class_id_by_nameService {
    if(!this._custom_ds_get_order_class_id_by_name) {
      this._custom_ds_get_order_class_id_by_name = this.injector.get(app_custom_ds_get_order_class_id_by_nameService);
    }
    return this._custom_ds_get_order_class_id_by_name;
  }
  private _custom_ds_get_orders_by_lookupcodes_types_projects_warehouses: app_custom_ds_get_orders_by_lookupcodes_types_projects_warehousesService;
  public get custom_ds_get_orders_by_lookupcodes_types_projects_warehouses(): app_custom_ds_get_orders_by_lookupcodes_types_projects_warehousesService {
    if(!this._custom_ds_get_orders_by_lookupcodes_types_projects_warehouses) {
      this._custom_ds_get_orders_by_lookupcodes_types_projects_warehouses = this.injector.get(app_custom_ds_get_orders_by_lookupcodes_types_projects_warehousesService);
    }
    return this._custom_ds_get_orders_by_lookupcodes_types_projects_warehouses;
  }
  private _custom_ds_get_picking_tasks_by_shipmentId: app_custom_ds_get_picking_tasks_by_shipmentIdService;
  public get custom_ds_get_picking_tasks_by_shipmentId(): app_custom_ds_get_picking_tasks_by_shipmentIdService {
    if(!this._custom_ds_get_picking_tasks_by_shipmentId) {
      this._custom_ds_get_picking_tasks_by_shipmentId = this.injector.get(app_custom_ds_get_picking_tasks_by_shipmentIdService);
    }
    return this._custom_ds_get_picking_tasks_by_shipmentId;
  }
  private _custom_ds_get_project_lookup_by_name: app_custom_ds_get_project_lookup_by_nameService;
  public get custom_ds_get_project_lookup_by_name(): app_custom_ds_get_project_lookup_by_nameService {
    if(!this._custom_ds_get_project_lookup_by_name) {
      this._custom_ds_get_project_lookup_by_name = this.injector.get(app_custom_ds_get_project_lookup_by_nameService);
    }
    return this._custom_ds_get_project_lookup_by_name;
  }
  private _custom_ds_get_transloadId_by_orderId: app_custom_ds_get_transloadId_by_orderIdService;
  public get custom_ds_get_transloadId_by_orderId(): app_custom_ds_get_transloadId_by_orderIdService {
    if(!this._custom_ds_get_transloadId_by_orderId) {
      this._custom_ds_get_transloadId_by_orderId = this.injector.get(app_custom_ds_get_transloadId_by_orderIdService);
    }
    return this._custom_ds_get_transloadId_by_orderId;
  }
  private _custom_get_orderlines_by_orderid: app_custom_get_orderlines_by_orderidService;
  public get custom_get_orderlines_by_orderid(): app_custom_get_orderlines_by_orderidService {
    if(!this._custom_get_orderlines_by_orderid) {
      this._custom_get_orderlines_by_orderid = this.injector.get(app_custom_get_orderlines_by_orderidService);
    }
    return this._custom_get_orderlines_by_orderid;
  }
  private _ds_get_inventory_by_project_lookup: app_ds_get_inventory_by_project_lookupService;
  public get ds_get_inventory_by_project_lookup(): app_ds_get_inventory_by_project_lookupService {
    if(!this._ds_get_inventory_by_project_lookup) {
      this._ds_get_inventory_by_project_lookup = this.injector.get(app_ds_get_inventory_by_project_lookupService);
    }
    return this._ds_get_inventory_by_project_lookup;
  }
  private _ds_get_warehouses: app_ds_get_warehousesService;
  public get ds_get_warehouses(): app_ds_get_warehousesService {
    if(!this._ds_get_warehouses) {
      this._ds_get_warehouses = this.injector.get(app_ds_get_warehousesService);
    }
    return this._ds_get_warehouses;
  }
}

