import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Invoices.frontend.types'

@Injectable({ providedIn: 'root' })
export class Invoices_create_or_update_billing_contract_line_detail_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { billingContractLineId: number, strategyName: string, value?: string, data?: { ScheduleType?: string, Frequency?: string, DailyInterval?: number, WeeklyInterval?: number, Monday?: boolean, Tuesday?: boolean, Wednesday?: boolean, Thursday?: boolean, Friday?: boolean, Saturday?: boolean, Sunday?: boolean, MonthlyInterval?: number, MonthlyDayNumber?: number, OccursAt?: string, AdvancedBilling?: boolean, AdvanceBillingGracePeriod?: number, AdvanceBillingGraceFrequency?: string, AnniversaryBillingGracePeriod?: number, AnniversaryBillingGraceFrequency?: string, TagIds?: number[] } }): Promise<{ success?: boolean, reason?: string, xml?: string }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.billingContractLineId)) {
      missingRequiredInParams.push('\'billingContractLineId\'');
    }
    if (isNil(inParams.strategyName)) {
      missingRequiredInParams.push('\'strategyName\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

