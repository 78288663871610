import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './SalesOrders.frontend.types'

@Injectable({ providedIn: 'root' })
export class SalesOrders_unload_shipping_licenseplate_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { licenseplateId: number, shipmentId: number, notes?: string, locationId: number }): Promise<{ reason?: string }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.licenseplateId)) {
      missingRequiredInParams.push('\'licenseplateId\'');
    }
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (isNil(inParams.locationId)) {
      missingRequiredInParams.push('\'locationId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

