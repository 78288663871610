import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { PurchaseOrders_DatasourceService } from './PurchaseOrders.datasource.index';

interface IPurchaseOrders_receiving_reportServiceInParams {
  orderId: number}

interface IPurchaseOrders_receiving_reportServiceData {
  Header?: { result?: { Id?: number, FulfillmentDate?: string, LookupCode?: string, OwnerReference?: string, VendorReference?: string, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { LookupCode?: string, Notes?: string, PickupDate?: string, SealId?: string, TrailerId?: string, Carrier?: { Name?: string, ScacCode?: string, ShortName?: string } } }[], Project?: { LookupCode?: string, Name?: string }, PreferredWarehouse?: { Name?: string } } }
  Lines?: { result?: { OrderLines?: { LineNumber?: number, PackagedAmount?: number, ActualPackagedAmount?: number, Packaging?: string, Material_LookupCode?: string, Material_Description?: string, Material_ControllerTypeId?: number, Lot_LookupCode?: string, VendorLot_LookupCode?: string, VendorLot_ExpirationDate?: string, VendorLot_ManufactureDate?: string, TotalGrossWeight?: number, TotalPackagedAmount?: number }[] } }
  WarehouseContact?: { result?: { FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string, Email?: string, Phone?: string } }
  OwnerContact?: { result?: { FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string } }
  AccountContact?: { result?: { AttentionOf?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string, PrimaryTelephone?: string, OrderId?: number, Email?: string } }
  Signature?: { result?: { Id?: number, FileName?: string, FileExtension?: string, Description?: string, CreatedSysDateTime?: string, ModifiedSysDateTime?: string, FileSize?: number, FileContent?: string } }
}

@Injectable({ providedIn: 'root' })
export class PurchaseOrders_receiving_reportService extends ReportBaseService<IPurchaseOrders_receiving_reportServiceInParams, IPurchaseOrders_receiving_reportServiceData> {

  protected reportReferenceName = 'receiving_report';
  protected appReferenceName = 'PurchaseOrders';

  constructor(
    $utils: UtilsService,
    private $datasources: PurchaseOrders_DatasourceService,
    ) {
      super($utils);
  }

  override throwIfMissingRequiredInParams (inParams: IPurchaseOrders_receiving_reportServiceInParams) {
  }

  protected async getData(inParams: IPurchaseOrders_receiving_reportServiceInParams): Promise<IPurchaseOrders_receiving_reportServiceData> 
  {
    const $utils = this.$utils;
    const $report: { inParams: IPurchaseOrders_receiving_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IPurchaseOrders_receiving_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.$datasources.PurchaseOrders.ds_receiving_header_report.get(dsParams);
      
      data.Header = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.$datasources.PurchaseOrders.ds_receiving_details_report_flow_based.get(dsParams);
      
      data.Lines = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.$datasources.PurchaseOrders.ds_get_order_warehouse_contact_top1_flow_based.get(dsParams);
      
      data.WarehouseContact = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.$datasources.PurchaseOrders.ds_get_order_owner_contact_top1_flow_based.get(dsParams);
      
      data.OwnerContact = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.$datasources.PurchaseOrders.ds_get_order_ship_from_account_vs_order_address.get(dsParams);
      
      data.AccountContact = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.$datasources.PurchaseOrders.ds_get_order_signature_attachment.get(dsParams);
      
      data.Signature = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
