import { Inject, Injectable, Injector }from '@angular/core';


import { TransloadOrders_ds_conveyance_ddService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_get_child_lps_by_parent_idService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_get_licenseplate_associations_by_shipment_idService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_get_licenseplate_by_licenseplate_idService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_get_licenseplate_count_by_children_shipmentService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_get_licenseplate_count_by_composite_shipmentService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_get_orderLines_by_orderIdService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_get_order_by_orderIdService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_get_order_total_picked_units_by_orderIdsService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_get_order_total_units_by_shipmentIdsService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_get_orderaddresses_by_orderIdService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_get_released_transload_receiving_tasksService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_get_shipment_aggregated_tasksService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_get_shipment_lines_by_shipment_idService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_get_shipments_aggregated_children_licenseplatesService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_get_shipments_aggregated_licenseplatesService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_get_shipments_aggregated_loading_tasksService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_get_shipments_aggregated_tasksService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_get_shipments_by_orderIdService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_get_shipments_by_shipmentIdService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_get_shipped_transload_inventory_by_shipment_idService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_get_task_by_task_idService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_get_transload_by_transloadIdService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_get_transload_load_task_countService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_get_transload_receiving_pending_countService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_order_crossdocked_licenseplate_count_widgetService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_order_expected_licenseplate_countService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_order_expected_licenseplate_count_widgetService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_order_loaded_licenseplate_count_widgetService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_order_putaway_licenseplate_count_widgetService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_order_total_received_gross_by_date_widgetService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_order_total_received_order_count_by_dateService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_order_total_received_order_count_by_date_widgetService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_order_total_received_units_by_date_widgetService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_orderclasses_ddService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_orderline_total_gross_received_widgetService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_orderline_total_packaged_amountService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_orderline_total_packaged_amount_widgetService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_orderline_total_receivedService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_orderline_total_received_widgetService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_orderstatuses_ddService } from './TransloadOrders.datasource.index';
import { TransloadOrders_ds_transload_composites_by_transload_idService } from './TransloadOrders.datasource.index';

@Injectable({ providedIn: 'root' })
export class TransloadOrders_DatasourceService {

  constructor(
    private injector: Injector
  ) {
  }

  public TransloadOrders: TransloadOrders_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_conveyance_dd: TransloadOrders_ds_conveyance_ddService;
  public get ds_conveyance_dd(): TransloadOrders_ds_conveyance_ddService {
    if(!this._ds_conveyance_dd) {
      this._ds_conveyance_dd = this.injector.get(TransloadOrders_ds_conveyance_ddService);
    }
    return this._ds_conveyance_dd;
  }
  private _ds_get_child_lps_by_parent_id: TransloadOrders_ds_get_child_lps_by_parent_idService;
  public get ds_get_child_lps_by_parent_id(): TransloadOrders_ds_get_child_lps_by_parent_idService {
    if(!this._ds_get_child_lps_by_parent_id) {
      this._ds_get_child_lps_by_parent_id = this.injector.get(TransloadOrders_ds_get_child_lps_by_parent_idService);
    }
    return this._ds_get_child_lps_by_parent_id;
  }
  private _ds_get_licenseplate_associations_by_shipment_id: TransloadOrders_ds_get_licenseplate_associations_by_shipment_idService;
  public get ds_get_licenseplate_associations_by_shipment_id(): TransloadOrders_ds_get_licenseplate_associations_by_shipment_idService {
    if(!this._ds_get_licenseplate_associations_by_shipment_id) {
      this._ds_get_licenseplate_associations_by_shipment_id = this.injector.get(TransloadOrders_ds_get_licenseplate_associations_by_shipment_idService);
    }
    return this._ds_get_licenseplate_associations_by_shipment_id;
  }
  private _ds_get_licenseplate_by_licenseplate_id: TransloadOrders_ds_get_licenseplate_by_licenseplate_idService;
  public get ds_get_licenseplate_by_licenseplate_id(): TransloadOrders_ds_get_licenseplate_by_licenseplate_idService {
    if(!this._ds_get_licenseplate_by_licenseplate_id) {
      this._ds_get_licenseplate_by_licenseplate_id = this.injector.get(TransloadOrders_ds_get_licenseplate_by_licenseplate_idService);
    }
    return this._ds_get_licenseplate_by_licenseplate_id;
  }
  private _ds_get_licenseplate_count_by_children_shipment: TransloadOrders_ds_get_licenseplate_count_by_children_shipmentService;
  public get ds_get_licenseplate_count_by_children_shipment(): TransloadOrders_ds_get_licenseplate_count_by_children_shipmentService {
    if(!this._ds_get_licenseplate_count_by_children_shipment) {
      this._ds_get_licenseplate_count_by_children_shipment = this.injector.get(TransloadOrders_ds_get_licenseplate_count_by_children_shipmentService);
    }
    return this._ds_get_licenseplate_count_by_children_shipment;
  }
  private _ds_get_licenseplate_count_by_composite_shipment: TransloadOrders_ds_get_licenseplate_count_by_composite_shipmentService;
  public get ds_get_licenseplate_count_by_composite_shipment(): TransloadOrders_ds_get_licenseplate_count_by_composite_shipmentService {
    if(!this._ds_get_licenseplate_count_by_composite_shipment) {
      this._ds_get_licenseplate_count_by_composite_shipment = this.injector.get(TransloadOrders_ds_get_licenseplate_count_by_composite_shipmentService);
    }
    return this._ds_get_licenseplate_count_by_composite_shipment;
  }
  private _ds_get_orderLines_by_orderId: TransloadOrders_ds_get_orderLines_by_orderIdService;
  public get ds_get_orderLines_by_orderId(): TransloadOrders_ds_get_orderLines_by_orderIdService {
    if(!this._ds_get_orderLines_by_orderId) {
      this._ds_get_orderLines_by_orderId = this.injector.get(TransloadOrders_ds_get_orderLines_by_orderIdService);
    }
    return this._ds_get_orderLines_by_orderId;
  }
  private _ds_get_order_by_orderId: TransloadOrders_ds_get_order_by_orderIdService;
  public get ds_get_order_by_orderId(): TransloadOrders_ds_get_order_by_orderIdService {
    if(!this._ds_get_order_by_orderId) {
      this._ds_get_order_by_orderId = this.injector.get(TransloadOrders_ds_get_order_by_orderIdService);
    }
    return this._ds_get_order_by_orderId;
  }
  private _ds_get_order_total_picked_units_by_orderIds: TransloadOrders_ds_get_order_total_picked_units_by_orderIdsService;
  public get ds_get_order_total_picked_units_by_orderIds(): TransloadOrders_ds_get_order_total_picked_units_by_orderIdsService {
    if(!this._ds_get_order_total_picked_units_by_orderIds) {
      this._ds_get_order_total_picked_units_by_orderIds = this.injector.get(TransloadOrders_ds_get_order_total_picked_units_by_orderIdsService);
    }
    return this._ds_get_order_total_picked_units_by_orderIds;
  }
  private _ds_get_order_total_units_by_shipmentIds: TransloadOrders_ds_get_order_total_units_by_shipmentIdsService;
  public get ds_get_order_total_units_by_shipmentIds(): TransloadOrders_ds_get_order_total_units_by_shipmentIdsService {
    if(!this._ds_get_order_total_units_by_shipmentIds) {
      this._ds_get_order_total_units_by_shipmentIds = this.injector.get(TransloadOrders_ds_get_order_total_units_by_shipmentIdsService);
    }
    return this._ds_get_order_total_units_by_shipmentIds;
  }
  private _ds_get_orderaddresses_by_orderId: TransloadOrders_ds_get_orderaddresses_by_orderIdService;
  public get ds_get_orderaddresses_by_orderId(): TransloadOrders_ds_get_orderaddresses_by_orderIdService {
    if(!this._ds_get_orderaddresses_by_orderId) {
      this._ds_get_orderaddresses_by_orderId = this.injector.get(TransloadOrders_ds_get_orderaddresses_by_orderIdService);
    }
    return this._ds_get_orderaddresses_by_orderId;
  }
  private _ds_get_released_transload_receiving_tasks: TransloadOrders_ds_get_released_transload_receiving_tasksService;
  public get ds_get_released_transload_receiving_tasks(): TransloadOrders_ds_get_released_transload_receiving_tasksService {
    if(!this._ds_get_released_transload_receiving_tasks) {
      this._ds_get_released_transload_receiving_tasks = this.injector.get(TransloadOrders_ds_get_released_transload_receiving_tasksService);
    }
    return this._ds_get_released_transload_receiving_tasks;
  }
  private _ds_get_shipment_aggregated_tasks: TransloadOrders_ds_get_shipment_aggregated_tasksService;
  public get ds_get_shipment_aggregated_tasks(): TransloadOrders_ds_get_shipment_aggregated_tasksService {
    if(!this._ds_get_shipment_aggregated_tasks) {
      this._ds_get_shipment_aggregated_tasks = this.injector.get(TransloadOrders_ds_get_shipment_aggregated_tasksService);
    }
    return this._ds_get_shipment_aggregated_tasks;
  }
  private _ds_get_shipment_lines_by_shipment_id: TransloadOrders_ds_get_shipment_lines_by_shipment_idService;
  public get ds_get_shipment_lines_by_shipment_id(): TransloadOrders_ds_get_shipment_lines_by_shipment_idService {
    if(!this._ds_get_shipment_lines_by_shipment_id) {
      this._ds_get_shipment_lines_by_shipment_id = this.injector.get(TransloadOrders_ds_get_shipment_lines_by_shipment_idService);
    }
    return this._ds_get_shipment_lines_by_shipment_id;
  }
  private _ds_get_shipments_aggregated_children_licenseplates: TransloadOrders_ds_get_shipments_aggregated_children_licenseplatesService;
  public get ds_get_shipments_aggregated_children_licenseplates(): TransloadOrders_ds_get_shipments_aggregated_children_licenseplatesService {
    if(!this._ds_get_shipments_aggregated_children_licenseplates) {
      this._ds_get_shipments_aggregated_children_licenseplates = this.injector.get(TransloadOrders_ds_get_shipments_aggregated_children_licenseplatesService);
    }
    return this._ds_get_shipments_aggregated_children_licenseplates;
  }
  private _ds_get_shipments_aggregated_licenseplates: TransloadOrders_ds_get_shipments_aggregated_licenseplatesService;
  public get ds_get_shipments_aggregated_licenseplates(): TransloadOrders_ds_get_shipments_aggregated_licenseplatesService {
    if(!this._ds_get_shipments_aggregated_licenseplates) {
      this._ds_get_shipments_aggregated_licenseplates = this.injector.get(TransloadOrders_ds_get_shipments_aggregated_licenseplatesService);
    }
    return this._ds_get_shipments_aggregated_licenseplates;
  }
  private _ds_get_shipments_aggregated_loading_tasks: TransloadOrders_ds_get_shipments_aggregated_loading_tasksService;
  public get ds_get_shipments_aggregated_loading_tasks(): TransloadOrders_ds_get_shipments_aggregated_loading_tasksService {
    if(!this._ds_get_shipments_aggregated_loading_tasks) {
      this._ds_get_shipments_aggregated_loading_tasks = this.injector.get(TransloadOrders_ds_get_shipments_aggregated_loading_tasksService);
    }
    return this._ds_get_shipments_aggregated_loading_tasks;
  }
  private _ds_get_shipments_aggregated_tasks: TransloadOrders_ds_get_shipments_aggregated_tasksService;
  public get ds_get_shipments_aggregated_tasks(): TransloadOrders_ds_get_shipments_aggregated_tasksService {
    if(!this._ds_get_shipments_aggregated_tasks) {
      this._ds_get_shipments_aggregated_tasks = this.injector.get(TransloadOrders_ds_get_shipments_aggregated_tasksService);
    }
    return this._ds_get_shipments_aggregated_tasks;
  }
  private _ds_get_shipments_by_orderId: TransloadOrders_ds_get_shipments_by_orderIdService;
  public get ds_get_shipments_by_orderId(): TransloadOrders_ds_get_shipments_by_orderIdService {
    if(!this._ds_get_shipments_by_orderId) {
      this._ds_get_shipments_by_orderId = this.injector.get(TransloadOrders_ds_get_shipments_by_orderIdService);
    }
    return this._ds_get_shipments_by_orderId;
  }
  private _ds_get_shipments_by_shipmentId: TransloadOrders_ds_get_shipments_by_shipmentIdService;
  public get ds_get_shipments_by_shipmentId(): TransloadOrders_ds_get_shipments_by_shipmentIdService {
    if(!this._ds_get_shipments_by_shipmentId) {
      this._ds_get_shipments_by_shipmentId = this.injector.get(TransloadOrders_ds_get_shipments_by_shipmentIdService);
    }
    return this._ds_get_shipments_by_shipmentId;
  }
  private _ds_get_shipped_transload_inventory_by_shipment_id: TransloadOrders_ds_get_shipped_transload_inventory_by_shipment_idService;
  public get ds_get_shipped_transload_inventory_by_shipment_id(): TransloadOrders_ds_get_shipped_transload_inventory_by_shipment_idService {
    if(!this._ds_get_shipped_transload_inventory_by_shipment_id) {
      this._ds_get_shipped_transload_inventory_by_shipment_id = this.injector.get(TransloadOrders_ds_get_shipped_transload_inventory_by_shipment_idService);
    }
    return this._ds_get_shipped_transload_inventory_by_shipment_id;
  }
  private _ds_get_task_by_task_id: TransloadOrders_ds_get_task_by_task_idService;
  public get ds_get_task_by_task_id(): TransloadOrders_ds_get_task_by_task_idService {
    if(!this._ds_get_task_by_task_id) {
      this._ds_get_task_by_task_id = this.injector.get(TransloadOrders_ds_get_task_by_task_idService);
    }
    return this._ds_get_task_by_task_id;
  }
  private _ds_get_transload_by_transloadId: TransloadOrders_ds_get_transload_by_transloadIdService;
  public get ds_get_transload_by_transloadId(): TransloadOrders_ds_get_transload_by_transloadIdService {
    if(!this._ds_get_transload_by_transloadId) {
      this._ds_get_transload_by_transloadId = this.injector.get(TransloadOrders_ds_get_transload_by_transloadIdService);
    }
    return this._ds_get_transload_by_transloadId;
  }
  private _ds_get_transload_load_task_count: TransloadOrders_ds_get_transload_load_task_countService;
  public get ds_get_transload_load_task_count(): TransloadOrders_ds_get_transload_load_task_countService {
    if(!this._ds_get_transload_load_task_count) {
      this._ds_get_transload_load_task_count = this.injector.get(TransloadOrders_ds_get_transload_load_task_countService);
    }
    return this._ds_get_transload_load_task_count;
  }
  private _ds_get_transload_receiving_pending_count: TransloadOrders_ds_get_transload_receiving_pending_countService;
  public get ds_get_transload_receiving_pending_count(): TransloadOrders_ds_get_transload_receiving_pending_countService {
    if(!this._ds_get_transload_receiving_pending_count) {
      this._ds_get_transload_receiving_pending_count = this.injector.get(TransloadOrders_ds_get_transload_receiving_pending_countService);
    }
    return this._ds_get_transload_receiving_pending_count;
  }
  private _ds_order_crossdocked_licenseplate_count_widget: TransloadOrders_ds_order_crossdocked_licenseplate_count_widgetService;
  public get ds_order_crossdocked_licenseplate_count_widget(): TransloadOrders_ds_order_crossdocked_licenseplate_count_widgetService {
    if(!this._ds_order_crossdocked_licenseplate_count_widget) {
      this._ds_order_crossdocked_licenseplate_count_widget = this.injector.get(TransloadOrders_ds_order_crossdocked_licenseplate_count_widgetService);
    }
    return this._ds_order_crossdocked_licenseplate_count_widget;
  }
  private _ds_order_expected_licenseplate_count: TransloadOrders_ds_order_expected_licenseplate_countService;
  public get ds_order_expected_licenseplate_count(): TransloadOrders_ds_order_expected_licenseplate_countService {
    if(!this._ds_order_expected_licenseplate_count) {
      this._ds_order_expected_licenseplate_count = this.injector.get(TransloadOrders_ds_order_expected_licenseplate_countService);
    }
    return this._ds_order_expected_licenseplate_count;
  }
  private _ds_order_expected_licenseplate_count_widget: TransloadOrders_ds_order_expected_licenseplate_count_widgetService;
  public get ds_order_expected_licenseplate_count_widget(): TransloadOrders_ds_order_expected_licenseplate_count_widgetService {
    if(!this._ds_order_expected_licenseplate_count_widget) {
      this._ds_order_expected_licenseplate_count_widget = this.injector.get(TransloadOrders_ds_order_expected_licenseplate_count_widgetService);
    }
    return this._ds_order_expected_licenseplate_count_widget;
  }
  private _ds_order_loaded_licenseplate_count_widget: TransloadOrders_ds_order_loaded_licenseplate_count_widgetService;
  public get ds_order_loaded_licenseplate_count_widget(): TransloadOrders_ds_order_loaded_licenseplate_count_widgetService {
    if(!this._ds_order_loaded_licenseplate_count_widget) {
      this._ds_order_loaded_licenseplate_count_widget = this.injector.get(TransloadOrders_ds_order_loaded_licenseplate_count_widgetService);
    }
    return this._ds_order_loaded_licenseplate_count_widget;
  }
  private _ds_order_putaway_licenseplate_count_widget: TransloadOrders_ds_order_putaway_licenseplate_count_widgetService;
  public get ds_order_putaway_licenseplate_count_widget(): TransloadOrders_ds_order_putaway_licenseplate_count_widgetService {
    if(!this._ds_order_putaway_licenseplate_count_widget) {
      this._ds_order_putaway_licenseplate_count_widget = this.injector.get(TransloadOrders_ds_order_putaway_licenseplate_count_widgetService);
    }
    return this._ds_order_putaway_licenseplate_count_widget;
  }
  private _ds_order_total_received_gross_by_date_widget: TransloadOrders_ds_order_total_received_gross_by_date_widgetService;
  public get ds_order_total_received_gross_by_date_widget(): TransloadOrders_ds_order_total_received_gross_by_date_widgetService {
    if(!this._ds_order_total_received_gross_by_date_widget) {
      this._ds_order_total_received_gross_by_date_widget = this.injector.get(TransloadOrders_ds_order_total_received_gross_by_date_widgetService);
    }
    return this._ds_order_total_received_gross_by_date_widget;
  }
  private _ds_order_total_received_order_count_by_date: TransloadOrders_ds_order_total_received_order_count_by_dateService;
  public get ds_order_total_received_order_count_by_date(): TransloadOrders_ds_order_total_received_order_count_by_dateService {
    if(!this._ds_order_total_received_order_count_by_date) {
      this._ds_order_total_received_order_count_by_date = this.injector.get(TransloadOrders_ds_order_total_received_order_count_by_dateService);
    }
    return this._ds_order_total_received_order_count_by_date;
  }
  private _ds_order_total_received_order_count_by_date_widget: TransloadOrders_ds_order_total_received_order_count_by_date_widgetService;
  public get ds_order_total_received_order_count_by_date_widget(): TransloadOrders_ds_order_total_received_order_count_by_date_widgetService {
    if(!this._ds_order_total_received_order_count_by_date_widget) {
      this._ds_order_total_received_order_count_by_date_widget = this.injector.get(TransloadOrders_ds_order_total_received_order_count_by_date_widgetService);
    }
    return this._ds_order_total_received_order_count_by_date_widget;
  }
  private _ds_order_total_received_units_by_date_widget: TransloadOrders_ds_order_total_received_units_by_date_widgetService;
  public get ds_order_total_received_units_by_date_widget(): TransloadOrders_ds_order_total_received_units_by_date_widgetService {
    if(!this._ds_order_total_received_units_by_date_widget) {
      this._ds_order_total_received_units_by_date_widget = this.injector.get(TransloadOrders_ds_order_total_received_units_by_date_widgetService);
    }
    return this._ds_order_total_received_units_by_date_widget;
  }
  private _ds_orderclasses_dd: TransloadOrders_ds_orderclasses_ddService;
  public get ds_orderclasses_dd(): TransloadOrders_ds_orderclasses_ddService {
    if(!this._ds_orderclasses_dd) {
      this._ds_orderclasses_dd = this.injector.get(TransloadOrders_ds_orderclasses_ddService);
    }
    return this._ds_orderclasses_dd;
  }
  private _ds_orderline_total_gross_received_widget: TransloadOrders_ds_orderline_total_gross_received_widgetService;
  public get ds_orderline_total_gross_received_widget(): TransloadOrders_ds_orderline_total_gross_received_widgetService {
    if(!this._ds_orderline_total_gross_received_widget) {
      this._ds_orderline_total_gross_received_widget = this.injector.get(TransloadOrders_ds_orderline_total_gross_received_widgetService);
    }
    return this._ds_orderline_total_gross_received_widget;
  }
  private _ds_orderline_total_packaged_amount: TransloadOrders_ds_orderline_total_packaged_amountService;
  public get ds_orderline_total_packaged_amount(): TransloadOrders_ds_orderline_total_packaged_amountService {
    if(!this._ds_orderline_total_packaged_amount) {
      this._ds_orderline_total_packaged_amount = this.injector.get(TransloadOrders_ds_orderline_total_packaged_amountService);
    }
    return this._ds_orderline_total_packaged_amount;
  }
  private _ds_orderline_total_packaged_amount_widget: TransloadOrders_ds_orderline_total_packaged_amount_widgetService;
  public get ds_orderline_total_packaged_amount_widget(): TransloadOrders_ds_orderline_total_packaged_amount_widgetService {
    if(!this._ds_orderline_total_packaged_amount_widget) {
      this._ds_orderline_total_packaged_amount_widget = this.injector.get(TransloadOrders_ds_orderline_total_packaged_amount_widgetService);
    }
    return this._ds_orderline_total_packaged_amount_widget;
  }
  private _ds_orderline_total_received: TransloadOrders_ds_orderline_total_receivedService;
  public get ds_orderline_total_received(): TransloadOrders_ds_orderline_total_receivedService {
    if(!this._ds_orderline_total_received) {
      this._ds_orderline_total_received = this.injector.get(TransloadOrders_ds_orderline_total_receivedService);
    }
    return this._ds_orderline_total_received;
  }
  private _ds_orderline_total_received_widget: TransloadOrders_ds_orderline_total_received_widgetService;
  public get ds_orderline_total_received_widget(): TransloadOrders_ds_orderline_total_received_widgetService {
    if(!this._ds_orderline_total_received_widget) {
      this._ds_orderline_total_received_widget = this.injector.get(TransloadOrders_ds_orderline_total_received_widgetService);
    }
    return this._ds_orderline_total_received_widget;
  }
  private _ds_orderstatuses_dd: TransloadOrders_ds_orderstatuses_ddService;
  public get ds_orderstatuses_dd(): TransloadOrders_ds_orderstatuses_ddService {
    if(!this._ds_orderstatuses_dd) {
      this._ds_orderstatuses_dd = this.injector.get(TransloadOrders_ds_orderstatuses_ddService);
    }
    return this._ds_orderstatuses_dd;
  }
  private _ds_transload_composites_by_transload_id: TransloadOrders_ds_transload_composites_by_transload_idService;
  public get ds_transload_composites_by_transload_id(): TransloadOrders_ds_transload_composites_by_transload_idService {
    if(!this._ds_transload_composites_by_transload_id) {
      this._ds_transload_composites_by_transload_id = this.injector.get(TransloadOrders_ds_transload_composites_by_transload_idService);
    }
    return this._ds_transload_composites_by_transload_id;
  }
}

