import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  ChangeDetectorRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ViewerComponent } from '@mescius/activereportsjs-angular';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';
import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Notifications_ShellService } from './Notifications.shell.service';
import { Notifications_OperationService } from './Notifications.operation.service';
import { Notifications_DatasourceService } from './Notifications.datasource.index';
import { Notifications_FlowService } from './Notifications.flow.index';
import { Notifications_ReportService } from './Notifications.report.index';
import { Notifications_LocalizationService } from './Notifications.localization.service';
import { Notifications_transaction_report_ComponentContextService } from './Notifications.transaction_report.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { $frontendTypes} from './Notifications.frontend.types'
import { $frontendTypes as $types} from './Notifications.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Notifications-transaction_report',
  templateUrl: './Notifications.transaction_report.component.html'
})
export class Notifications_transaction_reportComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('reportViewer', { static: false }) reportViewer: ViewerComponent;

  report: any;
  reportParameters: any;

  inParams: { transaction_id: string, log_ids?: string[], message_ids?: string[] } = { transaction_id: null, log_ids: [], message_ids: [] };
  //#region Inputs
  @Input('transaction_id') set $inParams_transaction_id(v: string) {
    this.inParams.transaction_id = v;
  }
  get $inParams_transaction_id(): string {
    return this.inParams.transaction_id;
  }
  @Input('log_ids') set $inParams_log_ids(v: string[]) {
    this.inParams.log_ids = v;
  }
  get $inParams_log_ids(): string[] {
    return this.inParams.log_ids;
  }
  @Input('message_ids') set $inParams_message_ids(v: string[]) {
    this.inParams.message_ids = v;
  }
  get $inParams_message_ids(): string[] {
    return this.inParams.message_ids;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: Notifications_ShellService,
    private $datasources: Notifications_DatasourceService,
    private $flows: Notifications_FlowService,
    private $reports: Notifications_ReportService,
    private $localization: Notifications_LocalizationService,
    private $operations: Notifications_OperationService,
    private $context: Notifications_transaction_report_ComponentContextService,
    private changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.transaction_id)) {
        this.$missingRequiredInParams.push('transaction_id');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'transaction_report';
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $report = this;
    const $utils = this.$utils;

    // We want to use reportViewer.open only in the onViewerInit, otherwise
    // the reportViewer might not be ready. In order to do that, the viewer has to be recreated.
    // We recreate it by using *ngIf="report"

    // set the report to null so that the reportViewer gets destroyed (initially this will change nothing)
    this.report = null;

    // actually destroy the viewer
    this.changeDetector.detectChanges();

    // set the report to the actual one which should create the viewer
    this.report = await this.$reports.Notifications.transaction_report.getReport(this.inParams);
    this.reportParameters = this.$reports.Notifications.transaction_report.getReportParameters(this.inParams);
  }

  onViewerInit() {
    this.reportViewer?.open(this.report, {
        ReportParams: this.reportParameters
      });
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    
    return result;
  }

  close() {
    this.$finish.emit();
  }
}
