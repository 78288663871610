import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootprintApiManager_ds_get_materials_and_packages_by_project_idService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { projectId?: number, id?: number[], lookupCode?: string }): 
  Promise<{ result: { Id?: number, Description?: string, IsFixedWeight?: boolean, LookupCode?: string, Name?: string, NmfcNumber?: string, NmfcSubNumber?: string, ProjectId?: number, ShelfLifeSpan?: number, StandardCost?: number, StandardPrice?: number, StandardUnitsPerHour?: number, ValuationMethod?: string, ControllerType?: { Name?: string }, GlobalMaterial?: { Name?: string }, MaterialGroup?: { Name?: string }, Status?: { Name?: string }, PackagingLookups?: { PackagingId?: number, BasePackagingQuantity?: number, Height?: number, Length?: number, PalletHigh?: number, PalletTie?: number, ShippingVolume?: number, ShippingWeight?: number, StandardCost?: number, StandardPrice?: number, SubPackagingQuantity?: number, TareWeight?: number, UpcCode?: string, Volume?: number, Weight?: number, Width?: number, DimensionsUom?: { Name?: string, Short_name?: string }, VolumeUom?: { Name?: string, Short_name?: string }, WeightUom?: { Name?: string, Short_name?: string }, Packaging?: { Name?: string, ShortName?: string } }[], KitType?: { Name?: string } }[] }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { projectId?: number, id?: number[], lookupCode?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, Description?: string, IsFixedWeight?: boolean, LookupCode?: string, Name?: string, NmfcNumber?: string, NmfcSubNumber?: string, ProjectId?: number, ShelfLifeSpan?: number, StandardCost?: number, StandardPrice?: number, StandardUnitsPerHour?: number, ValuationMethod?: string, ControllerType?: { Name?: string }, GlobalMaterial?: { Name?: string }, MaterialGroup?: { Name?: string }, Status?: { Name?: string }, PackagingLookups?: { PackagingId?: number, BasePackagingQuantity?: number, Height?: number, Length?: number, PalletHigh?: number, PalletTie?: number, ShippingVolume?: number, ShippingWeight?: number, StandardCost?: number, StandardPrice?: number, SubPackagingQuantity?: number, TareWeight?: number, UpcCode?: string, Volume?: number, Weight?: number, Width?: number, DimensionsUom?: { Name?: string, Short_name?: string }, VolumeUom?: { Name?: string, Short_name?: string }, WeightUom?: { Name?: string, Short_name?: string }, Packaging?: { Name?: string, ShortName?: string } }[], KitType?: { Name?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { projectId?: number, id?: number[], lookupCode?: string, $keys: number[] }): 
  Promise<{ result: { Id?: number, Description?: string, IsFixedWeight?: boolean, LookupCode?: string, Name?: string, NmfcNumber?: string, NmfcSubNumber?: string, ProjectId?: number, ShelfLifeSpan?: number, StandardCost?: number, StandardPrice?: number, StandardUnitsPerHour?: number, ValuationMethod?: string, ControllerType?: { Name?: string }, GlobalMaterial?: { Name?: string }, MaterialGroup?: { Name?: string }, Status?: { Name?: string }, PackagingLookups?: { PackagingId?: number, BasePackagingQuantity?: number, Height?: number, Length?: number, PalletHigh?: number, PalletTie?: number, ShippingVolume?: number, ShippingWeight?: number, StandardCost?: number, StandardPrice?: number, SubPackagingQuantity?: number, TareWeight?: number, UpcCode?: string, Volume?: number, Weight?: number, Width?: number, DimensionsUom?: { Name?: string, Short_name?: string }, VolumeUom?: { Name?: string, Short_name?: string }, WeightUom?: { Name?: string, Short_name?: string }, Packaging?: { Name?: string, ShortName?: string } }[], KitType?: { Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      projectId?: number, id?: number[], lookupCode?: string    }) {
      return false;
    }
}
