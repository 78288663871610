import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootprintApiManager_ds_get_receiving_tasks_by_shipment_idService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { shipmentId: number }): 
  Promise<{ result: { Id?: number, ActualInventoryAmount?: number, ActualInventoryAmountPackId?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualTargetLicensePlateId?: number, ActualTargetLocationId?: number, ChainHead?: number, CompletedDateTime?: string, Employee?: string, GrossVolume?: number, GrossWeight?: number, LotId?: number, MaterialId?: number, NetVolume?: number, NetWeight?: number, Notes?: string, OperationCodeId?: number, OrderId?: number, OrderLineNumber?: number, ProjectId?: number, ShipmentId?: number, ShipmentLineId?: number, StatusId?: number, VendorLotId?: number, VolumeUomId?: number, WeightUomId?: number, ActualTargetLicensePlate?: { Id?: number, DimensionsUomId?: number, GrossVolume?: number, GrossWeight?: number, Height?: number, Length?: number, LookupCode?: string, MaximumWeight?: number, NetVolume?: number, NetWeight?: number, VolumeUomId?: number, WeightUomId?: number, Width?: number, Status?: { Name?: string } }, ActualTargetLocation?: { Name?: string, Status?: { Name?: string } }, Lot?: { Id?: number, Description?: string, LastReceiveDate?: string, LookupCode?: string, Notes?: string, ReceiveDate?: string, VendorLot?: { Id?: number, Description?: string, ExpirationDate?: string, ExpirationNotificationDateOverride?: string, ExpirationNotificationOverride?: boolean, LookupCode?: string, ManufactureDate?: string, Notes?: string, Vat?: string }, Material?: { Id?: number, PackagingLookups?: { PackagingId?: number, BasePackagingQuantity?: number, SubPackagingQuantity?: number }[] }, Status?: { Name?: string } }, ActualPackagedPack?: { Id?: number, Name?: string, ShortName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { shipmentId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ActualInventoryAmount?: number, ActualInventoryAmountPackId?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualTargetLicensePlateId?: number, ActualTargetLocationId?: number, ChainHead?: number, CompletedDateTime?: string, Employee?: string, GrossVolume?: number, GrossWeight?: number, LotId?: number, MaterialId?: number, NetVolume?: number, NetWeight?: number, Notes?: string, OperationCodeId?: number, OrderId?: number, OrderLineNumber?: number, ProjectId?: number, ShipmentId?: number, ShipmentLineId?: number, StatusId?: number, VendorLotId?: number, VolumeUomId?: number, WeightUomId?: number, ActualTargetLicensePlate?: { Id?: number, DimensionsUomId?: number, GrossVolume?: number, GrossWeight?: number, Height?: number, Length?: number, LookupCode?: string, MaximumWeight?: number, NetVolume?: number, NetWeight?: number, VolumeUomId?: number, WeightUomId?: number, Width?: number, Status?: { Name?: string } }, ActualTargetLocation?: { Name?: string, Status?: { Name?: string } }, Lot?: { Id?: number, Description?: string, LastReceiveDate?: string, LookupCode?: string, Notes?: string, ReceiveDate?: string, VendorLot?: { Id?: number, Description?: string, ExpirationDate?: string, ExpirationNotificationDateOverride?: string, ExpirationNotificationOverride?: boolean, LookupCode?: string, ManufactureDate?: string, Notes?: string, Vat?: string }, Material?: { Id?: number, PackagingLookups?: { PackagingId?: number, BasePackagingQuantity?: number, SubPackagingQuantity?: number }[] }, Status?: { Name?: string } }, ActualPackagedPack?: { Id?: number, Name?: string, ShortName?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { shipmentId: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, ActualInventoryAmount?: number, ActualInventoryAmountPackId?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualTargetLicensePlateId?: number, ActualTargetLocationId?: number, ChainHead?: number, CompletedDateTime?: string, Employee?: string, GrossVolume?: number, GrossWeight?: number, LotId?: number, MaterialId?: number, NetVolume?: number, NetWeight?: number, Notes?: string, OperationCodeId?: number, OrderId?: number, OrderLineNumber?: number, ProjectId?: number, ShipmentId?: number, ShipmentLineId?: number, StatusId?: number, VendorLotId?: number, VolumeUomId?: number, WeightUomId?: number, ActualTargetLicensePlate?: { Id?: number, DimensionsUomId?: number, GrossVolume?: number, GrossWeight?: number, Height?: number, Length?: number, LookupCode?: string, MaximumWeight?: number, NetVolume?: number, NetWeight?: number, VolumeUomId?: number, WeightUomId?: number, Width?: number, Status?: { Name?: string } }, ActualTargetLocation?: { Name?: string, Status?: { Name?: string } }, Lot?: { Id?: number, Description?: string, LastReceiveDate?: string, LookupCode?: string, Notes?: string, ReceiveDate?: string, VendorLot?: { Id?: number, Description?: string, ExpirationDate?: string, ExpirationNotificationDateOverride?: string, ExpirationNotificationOverride?: boolean, LookupCode?: string, ManufactureDate?: string, Notes?: string, Vat?: string }, Material?: { Id?: number, PackagingLookups?: { PackagingId?: number, BasePackagingQuantity?: number, SubPackagingQuantity?: number }[] }, Status?: { Name?: string } }, ActualPackagedPack?: { Id?: number, Name?: string, ShortName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      shipmentId: number    }) {
        if(isNil(inParams.shipmentId)) {
          return true; 
        }
      return false;
    }
}
