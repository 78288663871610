import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { PrintNode_homeComponent } from './PrintNode.home.component';
import { PrintNode_label_image_reportComponent } from './PrintNode.label_image_report.component';


@Injectable({ providedIn: 'root' })
export class PrintNode_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
  ) {
    super(dialog, toastr);
  }

  public PrintNode: PrintNode_ShellService = this;

  public openhome(replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'PrintNode',
        referenceName: 'PrintNode_home',
        component: PrintNode_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openhomeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      PrintNode_homeComponent,
      'PrintNode',
      mode,
      dialogSize
    )
  }
  public openlabel_image_report(inParams:{ base_64?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'label_image_report',
        referenceName: 'PrintNode_label_image_report',
        component: PrintNode_label_image_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlabel_image_reportDialog(
    inParams:{ base_64?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      PrintNode_label_image_reportComponent,
      'label_image_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'PrintNode_home') {
      const title = 'PrintNode';
      const component = PrintNode_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PrintNode_label_image_report') {
      const title = 'label_image_report';
      const component = PrintNode_label_image_reportComponent;
      const inParams:{ base_64?: string } = { base_64: null };
      if (!isNil(params.get('base_64'))) {
        const paramValueString = params.get('base_64');
        // TODO: date
        inParams.base_64 = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    return result;
  }
}
