import { Injectable } from '@angular/core';


@Injectable({providedIn: 'root'})
export class SettingsValuesService {


  private _SettingsService: any = {};
  private _isInitialized = false;

  public set SettingsService(value: any) {
    this._isInitialized = true;
    this._SettingsService = value;
  }
  public get SettingsService() {
    return this._SettingsService;
  }

  public get isInitialized() {
    return this._isInitialized;
  }

  public get FootPrintAPI() {
    return this._SettingsService['FootPrintAPI'];
  }
  public get MongoDB() {
    return this._SettingsService['MongoDB'];
  }
  public get NatureSweet() {
    return this._SettingsService['NatureSweet'];
  }
  public get PrintNodeKey() {
    return this._SettingsService['PrintNodeKey'];
  }

  public app: SettingsValuesService = this;

  public get Utilities() {
     return {
FootprintApi : this.FootPrintAPI,
MongoDb : this.MongoDB
    };
  }
  public get Cartonization() {
     return {
FootprintApi : this.FootPrintAPI,
MongoDb : this.MongoDB
    };
  }
  public get TransloadOrders() {
     return {
FootprintApi : this.FootPrintAPI,
MongoDb : this.MongoDB
    };
  }
  public get WorkOrders() {
     return {
FootprintApi : this.FootPrintAPI,
MongoDb : this.MongoDB
    };
  }
  public get Notifications() {
     return {
FootprintApi : this.FootPrintAPI,
MongoDb : this.MongoDB
    };
  }
  public get Invoices() {
     return {
FootprintApi : this.FootPrintAPI,
MongoDb : this.MongoDB
    };
  }
  public get Reports() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDB
    };
  }
  public get ExcelOrderImport() {
     return {
FootprintApi : this.FootPrintAPI,
MongoDb : this.MongoDB
    };
  }
  public get PurchaseOrders() {
     return {
FootprintApi : this.FootPrintAPI,
FootprintApi_ReadOnly : this.FootPrintAPI,
MongoDb : this.MongoDB
    };
  }
  public get SalesOrders() {
     return {
FootprintApi : this.FootPrintAPI,
MongoDb : this.MongoDB
    };
  }
  public get Inventory() {
     return {
FootprintApi : this.FootPrintAPI,
MongoDb : this.MongoDB
    };
  }
  public get FootprintApiManager() {
     return {
FootprintApi : this.FootPrintAPI,
FootprintApi_ReadOnly : this.FootPrintAPI,
MongoDb : this.MongoDB
    };
  }
  public get PrintNode() {
     return {
FootprintApi : this.FootPrintAPI,
MongoDb : this.MongoDB,
PrintNodeKey : this.PrintNodeKey
    };
  }
  public get Addresses() {
     return {
FootprintApi : this.FootPrintAPI,
FootprintApi_ReadOnly : this.FootPrintAPI,
MongoDb : this.MongoDB
    };
  }
  public get Attachments() {
     return {
FootprintApi : this.FootPrintAPI,
MongoDb : this.MongoDB
    };
  }
  public get Owners() {
     return {
FootprintApi : this.FootPrintAPI,
MongoDb : this.MongoDB
    };
  }
  public get SerialNumbers() {
     return {
FootprintApi : this.FootPrintAPI,
MongoDb : this.MongoDB
    };
  }
  public get Instructions() {
     return {
FootprintApi : this.FootPrintAPI,
MongoDb : this.MongoDB
    };
  }
  public get Materials() {
     return {
FootprintApi : this.FootPrintAPI,
MongoDb : this.MongoDB
    };
  }
  public get Carriers() {
     return {
FootprintApi : this.FootPrintAPI,
MongoDb : this.MongoDB
    };
  }
}
