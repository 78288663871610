import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class SalesOrders_ds_get_order_by_licenseplateService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { LicenseplateId: number }): 
  Promise<{ result: { Id?: number, Shipment?: { Id?: number, AccountId?: number, ActualWarehouseId?: number, CarrierId?: number, CarrierServiceTypeId?: number, ContactId?: number, LookupCode?: string, WaveId?: number, OrderLookups?: { OrderId?: number, ShipmentId?: number, Order?: { AccountId?: number, LookupCode?: string, OrderClassId?: number, OwnerReference?: string, ProjectId?: number, VendorReference?: string, Addresses?: { Id?: number, AttentionOf?: string, City?: string, Country?: string, FirstName?: string, IsResidential?: boolean, LastName?: string, Line1?: string, Line2?: string, Line3?: string, Line4?: string, LookupCode?: string, MiddleName?: string, Name?: string, Orderid?: number, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string, ReferenceCode?: string, State?: string, TypeId?: number }[] } }[] } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.LicenseplateId)) {
      missingRequiredInParams.push('\'LicenseplateId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { LicenseplateId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, Shipment?: { Id?: number, AccountId?: number, ActualWarehouseId?: number, CarrierId?: number, CarrierServiceTypeId?: number, ContactId?: number, LookupCode?: string, WaveId?: number, OrderLookups?: { OrderId?: number, ShipmentId?: number, Order?: { AccountId?: number, LookupCode?: string, OrderClassId?: number, OwnerReference?: string, ProjectId?: number, VendorReference?: string, Addresses?: { Id?: number, AttentionOf?: string, City?: string, Country?: string, FirstName?: string, IsResidential?: boolean, LastName?: string, Line1?: string, Line2?: string, Line3?: string, Line4?: string, LookupCode?: string, MiddleName?: string, Name?: string, Orderid?: number, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string, ReferenceCode?: string, State?: string, TypeId?: number }[] } }[] } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.LicenseplateId)) {
      missingRequiredInParams.push('\'LicenseplateId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { LicenseplateId: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, Shipment?: { Id?: number, AccountId?: number, ActualWarehouseId?: number, CarrierId?: number, CarrierServiceTypeId?: number, ContactId?: number, LookupCode?: string, WaveId?: number, OrderLookups?: { OrderId?: number, ShipmentId?: number, Order?: { AccountId?: number, LookupCode?: string, OrderClassId?: number, OwnerReference?: string, ProjectId?: number, VendorReference?: string, Addresses?: { Id?: number, AttentionOf?: string, City?: string, Country?: string, FirstName?: string, IsResidential?: boolean, LastName?: string, Line1?: string, Line2?: string, Line3?: string, Line4?: string, LookupCode?: string, MiddleName?: string, Name?: string, Orderid?: number, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string, ReferenceCode?: string, State?: string, TypeId?: number }[] } }[] } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.LicenseplateId)) {
      missingRequiredInParams.push('\'LicenseplateId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      LicenseplateId: number    }) {
        if(isNil(inParams.LicenseplateId)) {
          return true; 
        }
      return false;
    }
}
