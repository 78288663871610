import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Notifications_ds_get_inventory_below_minimum_thresholdService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { minimum_inventory_threshold_base_amount: number, project_id: number, warehouse_id: number }): 
  Promise<{ result: { TotalAmount?: number, WarehouseId?: number, MaterialId?: number, Material?: { LookupCode?: string, Description?: string, ProjectId?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.minimum_inventory_threshold_base_amount)) {
      missingRequiredInParams.push('\'minimum_inventory_threshold_base_amount\'');
    }
    if (isNil(inParams.project_id)) {
      missingRequiredInParams.push('\'project_id\'');
    }
    if (isNil(inParams.warehouse_id)) {
      missingRequiredInParams.push('\'warehouse_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { minimum_inventory_threshold_base_amount: number, project_id: number, warehouse_id: number, $top?: number, $skip?: number }): 
  Promise<{ result: { TotalAmount?: number, WarehouseId?: number, MaterialId?: number, Material?: { LookupCode?: string, Description?: string, ProjectId?: number } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.minimum_inventory_threshold_base_amount)) {
      missingRequiredInParams.push('\'minimum_inventory_threshold_base_amount\'');
    }
    if (isNil(inParams.project_id)) {
      missingRequiredInParams.push('\'project_id\'');
    }
    if (isNil(inParams.warehouse_id)) {
      missingRequiredInParams.push('\'warehouse_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { minimum_inventory_threshold_base_amount: number, project_id: number, warehouse_id: number, $keys: { WarehouseId?: number, MaterialId?: number, Material?: { LookupCode?: string, Description?: string, ProjectId?: number } }[] }): 
  Promise<{ result: { TotalAmount?: number, WarehouseId?: number, MaterialId?: number, Material?: { LookupCode?: string, Description?: string, ProjectId?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.minimum_inventory_threshold_base_amount)) {
      missingRequiredInParams.push('\'minimum_inventory_threshold_base_amount\'');
    }
    if (isNil(inParams.project_id)) {
      missingRequiredInParams.push('\'project_id\'');
    }
    if (isNil(inParams.warehouse_id)) {
      missingRequiredInParams.push('\'warehouse_id\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      minimum_inventory_threshold_base_amount: number, project_id: number, warehouse_id: number    }) {
        if(isNil(inParams.minimum_inventory_threshold_base_amount)) {
          return true; 
        }
        if(isNil(inParams.project_id)) {
          return true; 
        }
        if(isNil(inParams.warehouse_id)) {
          return true; 
        }
      return false;
    }
}
