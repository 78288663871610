import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Core } from '@mescius/activereportsjs';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'codemirror/lib/codemirror';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/javascript/javascript';

import { LicenseManager } from "@ag-grid-enterprise/core"

// for future reference addons can be added for more functions for example lint, 
// although some of them require extra packages to be installed or else you will get an error
// example: import 'codemirror/addon/lint/lint';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));


LicenseManager.setLicenseKey("Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-069986}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Datex_Corp}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Footprint}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Footprint}_need_to_be_licensed___{Footprint}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{22_November_2025}____[v3]_[0102]_MTc2Mzc2OTYwMDAwMA==bb883a28d79732d5a858e25634c1ad54");
Core.setLicenseKey(environment.mesciusLicenseKey);
