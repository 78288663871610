import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';
import { Cartonization_FlowService } from './Cartonization.flow.index';
import { TransloadOrders_FlowService } from './TransloadOrders.flow.index';
import { WorkOrders_FlowService } from './WorkOrders.flow.index';
import { Notifications_FlowService } from './Notifications.flow.index';
import { Invoices_FlowService } from './Invoices.flow.index';
import { Reports_FlowService } from './Reports.flow.index';
import { ExcelOrderImport_FlowService } from './ExcelOrderImport.flow.index';
import { PurchaseOrders_FlowService } from './PurchaseOrders.flow.index';
import { SalesOrders_FlowService } from './SalesOrders.flow.index';
import { Inventory_FlowService } from './Inventory.flow.index';
import { FootprintApiManager_FlowService } from './FootprintApiManager.flow.index';
import { PrintNode_FlowService } from './PrintNode.flow.index';

import { app_custom_alerts_sendService } from './app.flow.index';
import { app_custom_cartonize_shipment_flowService } from './app.flow.index';
import { app_custom_create_transload_order_line_flowService } from './app.flow.index';
import { app_custom_excel_order_importService } from './app.flow.index';
import { app_custom_freight_billing_process_accessorial_charge_flowService } from './app.flow.index';
import { app_custom_freight_billing_process_ordersService } from './app.flow.index';
import { app_custom_get_eligible_container_types_flowService } from './app.flow.index';
import { app_custom_get_report_data_flowService } from './app.flow.index';
import { app_custom_get_shipment_quantities_to_cartonize_flowService } from './app.flow.index';
import { app_custom_print_labelsService } from './app.flow.index';
import { app_custom_print_reportsService } from './app.flow.index';
import { app_custom_print_request_flowService } from './app.flow.index';
import { app_logs_writeService } from './app.flow.index';
import { app_messages_writeService } from './app.flow.index';
import { app_sftp_pullService } from './app.flow.index';
import { app_sftp_pull_and_submitService } from './app.flow.index';
import { app_test_report_printingService } from './app.flow.index';

import { $frontendTypes } from './app.frontend.types'

@Injectable({ providedIn: 'root' })
export class app_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
    this.Cartonization = this.injector.get(Cartonization_FlowService);
    this.TransloadOrders = this.injector.get(TransloadOrders_FlowService);
    this.WorkOrders = this.injector.get(WorkOrders_FlowService);
    this.Notifications = this.injector.get(Notifications_FlowService);
    this.Invoices = this.injector.get(Invoices_FlowService);
    this.Reports = this.injector.get(Reports_FlowService);
    this.ExcelOrderImport = this.injector.get(ExcelOrderImport_FlowService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_FlowService);
    this.SalesOrders = this.injector.get(SalesOrders_FlowService);
    this.Inventory = this.injector.get(Inventory_FlowService);
    this.FootprintApiManager = this.injector.get(FootprintApiManager_FlowService);
    this.PrintNode = this.injector.get(PrintNode_FlowService);
  }

    public Utilities: Utilities_FlowService;
    public Cartonization: Cartonization_FlowService;
    public TransloadOrders: TransloadOrders_FlowService;
    public WorkOrders: WorkOrders_FlowService;
    public Notifications: Notifications_FlowService;
    public Invoices: Invoices_FlowService;
    public Reports: Reports_FlowService;
    public ExcelOrderImport: ExcelOrderImport_FlowService;
    public PurchaseOrders: PurchaseOrders_FlowService;
    public SalesOrders: SalesOrders_FlowService;
    public Inventory: Inventory_FlowService;
    public FootprintApiManager: FootprintApiManager_FlowService;
    public PrintNode: PrintNode_FlowService;
  public app: app_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _custom_alerts_send: app_custom_alerts_sendService;
  public async custom_alerts_send(inParams: { application_name?: string, log_level?: number, log_input?: $frontendTypes.Utilities.Log, log_id?: string }): Promise< { success?: boolean, messages?: any[] }> {
    if(!this._custom_alerts_send) { 
      this._custom_alerts_send = this.injector.get(app_custom_alerts_sendService);
    }
    return this._custom_alerts_send.run(inParams);
  }
   
   

   
 
  private _custom_cartonize_shipment_flow: app_custom_cartonize_shipment_flowService;
  public async custom_cartonize_shipment_flow(inParams: { shipmentId: number }): Promise< { reasons?: string[] }> {
    if(!this._custom_cartonize_shipment_flow) { 
      this._custom_cartonize_shipment_flow = this.injector.get(app_custom_cartonize_shipment_flowService);
    }
    return this._custom_cartonize_shipment_flow.run(inParams);
  }
   
   

   
 
  private _custom_create_transload_order_line_flow: app_custom_create_transload_order_line_flowService;
  public async custom_create_transload_order_line_flow(inParams: { orderId: number, shipmentId: number, packagingId: number, packagedAmount: number, materialId?: number, lotId?: number, vendorlotId?: number, notes?: string, weightOrdered?: number, cubeOrdered?: number }): Promise< { reasons?: string[], linenumber?: number, confirm?: boolean }> {
    if(!this._custom_create_transload_order_line_flow) { 
      this._custom_create_transload_order_line_flow = this.injector.get(app_custom_create_transload_order_line_flowService);
    }
    return this._custom_create_transload_order_line_flow.run(inParams);
  }
   
   

   
 
  private _custom_excel_order_import: app_custom_excel_order_importService;
  public async custom_excel_order_import(inParams: { excel_file_blob: Blob }): Promise< { results?: any[] }> {
    if(!this._custom_excel_order_import) { 
      this._custom_excel_order_import = this.injector.get(app_custom_excel_order_importService);
    }
    return this._custom_excel_order_import.run(inParams);
  }
   
   

   
 
  private _custom_freight_billing_process_accessorial_charge_flow: app_custom_freight_billing_process_accessorial_charge_flowService;
  public async custom_freight_billing_process_accessorial_charge_flow(inParams: { order_id?: number }): Promise< { results?: any }> {
    if(!this._custom_freight_billing_process_accessorial_charge_flow) { 
      this._custom_freight_billing_process_accessorial_charge_flow = this.injector.get(app_custom_freight_billing_process_accessorial_charge_flowService);
    }
    return this._custom_freight_billing_process_accessorial_charge_flow.run(inParams);
  }
   
   

   
 
  private _custom_freight_billing_process_orders: app_custom_freight_billing_process_ordersService;
  public async custom_freight_billing_process_orders(inParams: {  }): Promise< { paramDetails?: any, result?: any }> {
    if(!this._custom_freight_billing_process_orders) { 
      this._custom_freight_billing_process_orders = this.injector.get(app_custom_freight_billing_process_ordersService);
    }
    return this._custom_freight_billing_process_orders.run(inParams);
  }
   
   

   
 
  private _custom_get_eligible_container_types_flow: app_custom_get_eligible_container_types_flowService;
  public async custom_get_eligible_container_types_flow(inParams: { shipmentId: number, context: string }): Promise< { containerTypeIds?: number[], logs?: string[] }> {
    if(!this._custom_get_eligible_container_types_flow) { 
      this._custom_get_eligible_container_types_flow = this.injector.get(app_custom_get_eligible_container_types_flowService);
    }
    return this._custom_get_eligible_container_types_flow.run(inParams);
  }
   
   

   
 
  private _custom_get_report_data_flow: app_custom_get_report_data_flowService;
  public async custom_get_report_data_flow(inParams: { package_name: string, report_name: string, inputs?: any }): Promise< { report_data?: Blob }> {
    if(!this._custom_get_report_data_flow) { 
      this._custom_get_report_data_flow = this.injector.get(app_custom_get_report_data_flowService);
    }
    return this._custom_get_report_data_flow.run(inParams);
  }
   
   

   
 
  private _custom_get_shipment_quantities_to_cartonize_flow: app_custom_get_shipment_quantities_to_cartonize_flowService;
  public async custom_get_shipment_quantities_to_cartonize_flow(inParams: { shipmentId: number }): Promise< { reasons?: string[], quantities?: { orderId?: number, lineNumber?: number, materialId?: number, packagingId?: number, amount?: number, group?: string, taskIds?: number[] }[], targetPickingTasks?: any[] }> {
    if(!this._custom_get_shipment_quantities_to_cartonize_flow) { 
      this._custom_get_shipment_quantities_to_cartonize_flow = this.injector.get(app_custom_get_shipment_quantities_to_cartonize_flowService);
    }
    return this._custom_get_shipment_quantities_to_cartonize_flow.run(inParams);
  }
   
   

   
 
  private _custom_print_labels: app_custom_print_labelsService;
  public async custom_print_labels(inParams: { order_id?: number, shipment_id?: number, order_line_number?: number, copies?: number, printer_name: string, report_name: string }): Promise<void> {
    if(!this._custom_print_labels) { 
      this._custom_print_labels = this.injector.get(app_custom_print_labelsService);
    }
    return this._custom_print_labels.run(inParams);
  }
   
   

   
 
  private _custom_print_reports: app_custom_print_reportsService;
  public async custom_print_reports(inParams: { order_id?: number, shipment_id?: number, order_line_number?: number, copies?: number, printer_name: string, report_name: string }): Promise< { reasons?: string[] }> {
    if(!this._custom_print_reports) { 
      this._custom_print_reports = this.injector.get(app_custom_print_reportsService);
    }
    return this._custom_print_reports.run(inParams);
  }
   
   

   
 
  private _custom_print_request_flow: app_custom_print_request_flowService;
  public async custom_print_request_flow(inParams: { printerId: number, content: string, contentType?: string, rotate?: number }): Promise< { outputDetails?: string, printJobId?: number }> {
    if(!this._custom_print_request_flow) { 
      this._custom_print_request_flow = this.injector.get(app_custom_print_request_flowService);
    }
    return this._custom_print_request_flow.run(inParams);
  }
   
   

   
 
  private _logs_write: app_logs_writeService;
  public async logs_write(inParams: { input?: $frontendTypes.Utilities.Log[] }): Promise< { output?: { log_id?: string }[], success?: boolean, error?: any[] }> {
    if(!this._logs_write) { 
      this._logs_write = this.injector.get(app_logs_writeService);
    }
    return this._logs_write.run(inParams);
  }
   
   

   
 
  private _messages_write: app_messages_writeService;
  public async messages_write(inParams: { input?: $frontendTypes.Utilities.Message[] }): Promise< { output?: { message_id?: string }[], success?: boolean, error?: any[] }> {
    if(!this._messages_write) { 
      this._messages_write = this.injector.get(app_messages_writeService);
    }
    return this._messages_write.run(inParams);
  }
   
   

   
 
  private _sftp_pull: app_sftp_pullService;
  public async sftp_pull(inParams: { connection: string, source_path: string, archive_path?: string, error_path?: string, file_extension?: string, file_name?: string, sort_asc?: boolean, transaction_id?: string }): Promise< { messages?: any[], success?: boolean, files?: { file_name?: string, file_blob?: any }[] }> {
    if(!this._sftp_pull) { 
      this._sftp_pull = this.injector.get(app_sftp_pullService);
    }
    return this._sftp_pull.run(inParams);
  }
   
   

   
 
  private _sftp_pull_and_submit: app_sftp_pull_and_submitService;
  public async sftp_pull_and_submit(inParams: { integration_id: string, sftp_connection: string, sftp_path: string, sftp_extension?: string, mq_connection?: string }): Promise< { messages?: any[] }> {
    if(!this._sftp_pull_and_submit) { 
      this._sftp_pull_and_submit = this.injector.get(app_sftp_pull_and_submitService);
    }
    return this._sftp_pull_and_submit.run(inParams);
  }
   
   

   
 
  private _test_report_printing: app_test_report_printingService;
  public async test_report_printing(inParams: {  }): Promise<void> {
    if(!this._test_report_printing) { 
      this._test_report_printing = this.injector.get(app_test_report_printingService);
    }
    return this._test_report_printing.run(inParams);
  }
   
   

   
}
