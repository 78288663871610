import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Notifications_ds_auto_email_rule_editorService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }


  public async get(inParams: { rule_id?: string }): Promise<{ result?: { id?: string, context?: string, filters?: string, to?: string, cc?: string, bcc?: string, subject?: string, body?: string, notes?: string, created_by?: string, created_on?: string, modified_by?: string, modified_on?: string, last_executed_on?: string, frequency?: string, projectId?: number, is_enabled?: boolean, include_attachments?: boolean, dock_appointment_carrier_id?: number, dock_appointment_type_id?: number, lot_expiration_days_before_expiry?: number, minimum_inventory_threshold_base_amount?: number, warehouse_id?: number } }> {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      rule_id?: string    }) {
      return false;
    }
}
