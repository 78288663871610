import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './FootprintApiManager.frontend.types'

@Injectable({ providedIn: 'root' })
export class FootprintApiManager_footprint_generic_json_order_to_entity_import_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { order?: $frontendTypes.FootprintApiManager.DatexOrderInfo, transaction_id?: string, source_lookup_code?: string, source_platform?: string, order_type?: string, order_action?: string, account_action?: string }): Promise<{ payload?: any[], messages?: string[], warehouse?: string, project?: string, referenceCode?: string }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

