import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { PrintNode_DatasourceService } from './PrintNode.datasource.index';

interface IPrintNode_label_image_reportServiceInParams {
  base_64?: string}

interface IPrintNode_label_image_reportServiceData {
  data?: { result?: { base_64?: string } }
}

@Injectable({ providedIn: 'root' })
export class PrintNode_label_image_reportService extends ReportBaseService<IPrintNode_label_image_reportServiceInParams, IPrintNode_label_image_reportServiceData> {

  protected reportReferenceName = 'label_image_report';
  protected appReferenceName = 'PrintNode';

  constructor(
    $utils: UtilsService,
    private $datasources: PrintNode_DatasourceService,
    ) {
      super($utils);
  }

  override throwIfMissingRequiredInParams (inParams: IPrintNode_label_image_reportServiceInParams) {
  }

  protected async getData(inParams: IPrintNode_label_image_reportServiceInParams): Promise<IPrintNode_label_image_reportServiceData> 
  {
    const $utils = this.$utils;
    const $report: { inParams: IPrintNode_label_image_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IPrintNode_label_image_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        base_64:  $report.inParams.base_64 
      };
      
      const dsData = await this.$datasources.PrintNode.ds_get_label_image_data.get(dsParams);
      
      data.data = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
