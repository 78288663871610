<ng-container *ngIf="isLoggedIn; else notLoggedIn">
  <ng-container *ngIf="isInitialized; else settingLoading">
    <router-outlet></router-outlet>
  </ng-container>
  <ng-template #settingLoading>
    <div class="full-screen-center">
      <div class="centered-content"> INITIALIZING <mat-progress-bar mode="buffer"></mat-progress-bar>
      </div>
    </div>
  </ng-template>
</ng-container>
<ng-template #notLoggedIn>
  <div *ngIf="!authenticating"
       class="full-screen-center">
    <button class="login-button"
            (click)="onLoginClicked()">LOGIN</button>
  </div>
  <div *ngIf="authenticating"
       class="full-screen-center">
    <div class="centered-content"> AUTHENTICATING <mat-progress-bar mode="buffer"></mat-progress-bar>
    </div>
  </div>
</ng-template>