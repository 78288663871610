import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { app_homeComponent } from './app.home.component';

import { Utilities_ShellService } from './Utilities.shell.service';
import { Cartonization_ShellService } from './Cartonization.shell.service';
import { TransloadOrders_ShellService } from './TransloadOrders.shell.service';
import { WorkOrders_ShellService } from './WorkOrders.shell.service';
import { Notifications_ShellService } from './Notifications.shell.service';
import { Invoices_ShellService } from './Invoices.shell.service';
import { Reports_ShellService } from './Reports.shell.service';
import { ExcelOrderImport_ShellService } from './ExcelOrderImport.shell.service';
import { PurchaseOrders_ShellService } from './PurchaseOrders.shell.service';
import { SalesOrders_ShellService } from './SalesOrders.shell.service';
import { Inventory_ShellService } from './Inventory.shell.service';
import { FootprintApiManager_ShellService } from './FootprintApiManager.shell.service';
import { PrintNode_ShellService } from './PrintNode.shell.service';

@Injectable({ providedIn: 'root' })
export class app_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    public Utilities: Utilities_ShellService,
    public Cartonization: Cartonization_ShellService,
    public TransloadOrders: TransloadOrders_ShellService,
    public WorkOrders: WorkOrders_ShellService,
    public Notifications: Notifications_ShellService,
    public Invoices: Invoices_ShellService,
    public Reports: Reports_ShellService,
    public ExcelOrderImport: ExcelOrderImport_ShellService,
    public PurchaseOrders: PurchaseOrders_ShellService,
    public SalesOrders: SalesOrders_ShellService,
    public Inventory: Inventory_ShellService,
    public FootprintApiManager: FootprintApiManager_ShellService,
    public PrintNode: PrintNode_ShellService,
  ) {
    super(dialog, toastr);
  }

  public app: app_ShellService = this;

  public openhome(replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'FootPrint Api',
        referenceName: 'home',
        component: app_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openhomeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_homeComponent,
      'FootPrint Api',
      mode,
      dialogSize
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'home') {
      const title = 'FootPrint Api';
      const component = app_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Cartonization.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.TransloadOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.WorkOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Notifications.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Invoices.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Reports.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.ExcelOrderImport.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.PurchaseOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.SalesOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Inventory.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.FootprintApiManager.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.PrintNode.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
