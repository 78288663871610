import { Inject, Injectable, Injector } from '@angular/core';


import { WorkOrders_work_order_reportService } from './WorkOrders.report.index';

@Injectable({ providedIn: 'root' })
export class WorkOrders_ReportService {

  constructor(
    private injector: Injector
  ) {
  }

  public WorkOrders: WorkOrders_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _work_order_report: WorkOrders_work_order_reportService;
  public get work_order_report(): WorkOrders_work_order_reportService {
    if(!this._work_order_report) {
      this._work_order_report = this.injector.get(WorkOrders_work_order_reportService);
    }
    return this._work_order_report;
  }
}

