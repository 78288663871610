import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Inventory_ds_inventory_licenseplate_gridService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { licenseplateId?: number, fullTextSearch?: string, ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[], licenseplateStatusId?: number, lotStatusId?: number, vendorlotTextSearch?: string }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, GrossWeight?: number, PackagedAmount?: number, Lot?: { LookupCode?: string, MaterialId?: number, ReceiveDate?: string, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string }, Material?: { Description?: string, LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string } } }, Packaging?: { ShortName?: string }, LicensePlate?: { LookupCode?: string, ParentId?: number, TypeId?: number, Location?: { Id?: number, Name?: string, Warehouse?: { Id?: number, Name?: string } }, ParentLicensePlate?: { Id?: number, LookupCode?: string } }, WeightUom?: { Short_name?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { licenseplateId?: number, fullTextSearch?: string, ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[], licenseplateStatusId?: number, lotStatusId?: number, vendorlotTextSearch?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, GrossWeight?: number, PackagedAmount?: number, Lot?: { LookupCode?: string, MaterialId?: number, ReceiveDate?: string, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string }, Material?: { Description?: string, LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string } } }, Packaging?: { ShortName?: string }, LicensePlate?: { LookupCode?: string, ParentId?: number, TypeId?: number, Location?: { Id?: number, Name?: string, Warehouse?: { Id?: number, Name?: string } }, ParentLicensePlate?: { Id?: number, LookupCode?: string } }, WeightUom?: { Short_name?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { licenseplateId?: number, fullTextSearch?: string, ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[], licenseplateStatusId?: number, lotStatusId?: number, vendorlotTextSearch?: string, $keys: { LicensePlateId?: number, LotId?: number, PackagedId?: number }[] }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, GrossWeight?: number, PackagedAmount?: number, Lot?: { LookupCode?: string, MaterialId?: number, ReceiveDate?: string, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string }, Material?: { Description?: string, LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string } } }, Packaging?: { ShortName?: string }, LicensePlate?: { LookupCode?: string, ParentId?: number, TypeId?: number, Location?: { Id?: number, Name?: string, Warehouse?: { Id?: number, Name?: string } }, ParentLicensePlate?: { Id?: number, LookupCode?: string } }, WeightUom?: { Short_name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      licenseplateId?: number, fullTextSearch?: string, ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[], licenseplateStatusId?: number, lotStatusId?: number, vendorlotTextSearch?: string    }) {
      return false;
    }
}
