import { Inject, Injectable, Injector }from '@angular/core';


import { Utilities_ds_applications_ddService } from './Utilities.datasource.index';
import { Utilities_ds_configurations_gridService } from './Utilities.datasource.index';
import { Utilities_ds_get_first_warehouseService } from './Utilities.datasource.index';
import { Utilities_ds_get_warehouses_by_warehouseIdsService } from './Utilities.datasource.index';
import { Utilities_ds_logs_gridService } from './Utilities.datasource.index';
import { Utilities_ds_messages_gridService } from './Utilities.datasource.index';
import { Utilities_ds_processes_ddService } from './Utilities.datasource.index';
import { Utilities_ds_statuses_ddService } from './Utilities.datasource.index';
import { Utilities_ds_time_selector_ddService } from './Utilities.datasource.index';
import { Utilities_ds_time_zone_ddService } from './Utilities.datasource.index';
import { Utilities_ds_transaction_types_ddService } from './Utilities.datasource.index';

@Injectable({ providedIn: 'root' })
export class Utilities_DatasourceService {

  constructor(
    private injector: Injector
  ) {
  }

  public Utilities: Utilities_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_applications_dd: Utilities_ds_applications_ddService;
  public get ds_applications_dd(): Utilities_ds_applications_ddService {
    if(!this._ds_applications_dd) {
      this._ds_applications_dd = this.injector.get(Utilities_ds_applications_ddService);
    }
    return this._ds_applications_dd;
  }
  private _ds_configurations_grid: Utilities_ds_configurations_gridService;
  public get ds_configurations_grid(): Utilities_ds_configurations_gridService {
    if(!this._ds_configurations_grid) {
      this._ds_configurations_grid = this.injector.get(Utilities_ds_configurations_gridService);
    }
    return this._ds_configurations_grid;
  }
  private _ds_get_first_warehouse: Utilities_ds_get_first_warehouseService;
  public get ds_get_first_warehouse(): Utilities_ds_get_first_warehouseService {
    if(!this._ds_get_first_warehouse) {
      this._ds_get_first_warehouse = this.injector.get(Utilities_ds_get_first_warehouseService);
    }
    return this._ds_get_first_warehouse;
  }
  private _ds_get_warehouses_by_warehouseIds: Utilities_ds_get_warehouses_by_warehouseIdsService;
  public get ds_get_warehouses_by_warehouseIds(): Utilities_ds_get_warehouses_by_warehouseIdsService {
    if(!this._ds_get_warehouses_by_warehouseIds) {
      this._ds_get_warehouses_by_warehouseIds = this.injector.get(Utilities_ds_get_warehouses_by_warehouseIdsService);
    }
    return this._ds_get_warehouses_by_warehouseIds;
  }
  private _ds_logs_grid: Utilities_ds_logs_gridService;
  public get ds_logs_grid(): Utilities_ds_logs_gridService {
    if(!this._ds_logs_grid) {
      this._ds_logs_grid = this.injector.get(Utilities_ds_logs_gridService);
    }
    return this._ds_logs_grid;
  }
  private _ds_messages_grid: Utilities_ds_messages_gridService;
  public get ds_messages_grid(): Utilities_ds_messages_gridService {
    if(!this._ds_messages_grid) {
      this._ds_messages_grid = this.injector.get(Utilities_ds_messages_gridService);
    }
    return this._ds_messages_grid;
  }
  private _ds_processes_dd: Utilities_ds_processes_ddService;
  public get ds_processes_dd(): Utilities_ds_processes_ddService {
    if(!this._ds_processes_dd) {
      this._ds_processes_dd = this.injector.get(Utilities_ds_processes_ddService);
    }
    return this._ds_processes_dd;
  }
  private _ds_statuses_dd: Utilities_ds_statuses_ddService;
  public get ds_statuses_dd(): Utilities_ds_statuses_ddService {
    if(!this._ds_statuses_dd) {
      this._ds_statuses_dd = this.injector.get(Utilities_ds_statuses_ddService);
    }
    return this._ds_statuses_dd;
  }
  private _ds_time_selector_dd: Utilities_ds_time_selector_ddService;
  public get ds_time_selector_dd(): Utilities_ds_time_selector_ddService {
    if(!this._ds_time_selector_dd) {
      this._ds_time_selector_dd = this.injector.get(Utilities_ds_time_selector_ddService);
    }
    return this._ds_time_selector_dd;
  }
  private _ds_time_zone_dd: Utilities_ds_time_zone_ddService;
  public get ds_time_zone_dd(): Utilities_ds_time_zone_ddService {
    if(!this._ds_time_zone_dd) {
      this._ds_time_zone_dd = this.injector.get(Utilities_ds_time_zone_ddService);
    }
    return this._ds_time_zone_dd;
  }
  private _ds_transaction_types_dd: Utilities_ds_transaction_types_ddService;
  public get ds_transaction_types_dd(): Utilities_ds_transaction_types_ddService {
    if(!this._ds_transaction_types_dd) {
      this._ds_transaction_types_dd = this.injector.get(Utilities_ds_transaction_types_ddService);
    }
    return this._ds_transaction_types_dd;
  }
}

