<wavelength-ui-shell [title]="title"
                     [subtitle]="subtitle"
                     [logo]="logo"
                     [home]="home"
                     [items]="items"
                     [toolbarItems]="toolbarItems"
                     (initialize)="onInitialized()">
  <ng-container customHeader>
    <div class="custom-header">
      <div class="shell-header-tools">
        <button id="doc360_help_btn"
                class="shell-button hide">
          <i class="icon icon-ic_fluent_question_circle_20_regular"></i>
        </button>
      </div>
      <div class="menu">
        <button [matMenuTriggerFor]="menu"
                class="shell-button user-account">
          <div class="user-name">{{ account?.username }}</div>
          <div class="user-icon"
               [matTooltip]="account?.username"
               matTooltipClass="datex-control-tooltip">
            <div class="icon-container"><i class="icon icon-ic_fluent_person_20_regular"></i></div>
          </div>
        </button>
        <mat-menu #menu="matMenu"
                  class="options-menu">
          <button mat-menu-item
                  (click)="onLogoutClicked()"><i class="icon icon-ic_fluent_sign_out_20_regular"></i>
            <span>Sign out</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </ng-container>
</wavelength-ui-shell>
