import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootprintApiManager_ds_get_temperature_readings_by_shipment_idService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { shipment_id?: number, license_plate_id?: number, load_container_id?: number }): 
  Promise<{ result: { Id?: number, DateCaptured?: string, Employee?: string, LicensePlateId?: number, LoadContainerId?: number, Notes?: string, ReadingPointId?: number, ReadingTypeId?: number, ShipmentId?: number, ShipmentLineId?: number, Temperature1?: number, Temperature1MeasurementUnitId?: number, Temperature2?: number, Temperature2MeasurementUnitId?: number, Temperature3?: number, Temperature3MeasurementUnitId?: number, ReadingPoint?: { Name?: string }, ReadingType?: { Name?: string }, Temperature1MeasurementUnit?: { Name?: string, Short_name?: string }, Temperature2MeasurementUnit?: { Name?: string, Short_name?: string }, Temperature3MeasurementUnit?: { Name?: string, Short_name?: string }, LicensePlate?: { LookupCode?: string }, ShipmentLine?: { Id?: number, OrderLine?: { LineNumber?: number, Material?: { LookupCode?: string } } } }[] }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { shipment_id?: number, license_plate_id?: number, load_container_id?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, DateCaptured?: string, Employee?: string, LicensePlateId?: number, LoadContainerId?: number, Notes?: string, ReadingPointId?: number, ReadingTypeId?: number, ShipmentId?: number, ShipmentLineId?: number, Temperature1?: number, Temperature1MeasurementUnitId?: number, Temperature2?: number, Temperature2MeasurementUnitId?: number, Temperature3?: number, Temperature3MeasurementUnitId?: number, ReadingPoint?: { Name?: string }, ReadingType?: { Name?: string }, Temperature1MeasurementUnit?: { Name?: string, Short_name?: string }, Temperature2MeasurementUnit?: { Name?: string, Short_name?: string }, Temperature3MeasurementUnit?: { Name?: string, Short_name?: string }, LicensePlate?: { LookupCode?: string }, ShipmentLine?: { Id?: number, OrderLine?: { LineNumber?: number, Material?: { LookupCode?: string } } } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { shipment_id?: number, license_plate_id?: number, load_container_id?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, DateCaptured?: string, Employee?: string, LicensePlateId?: number, LoadContainerId?: number, Notes?: string, ReadingPointId?: number, ReadingTypeId?: number, ShipmentId?: number, ShipmentLineId?: number, Temperature1?: number, Temperature1MeasurementUnitId?: number, Temperature2?: number, Temperature2MeasurementUnitId?: number, Temperature3?: number, Temperature3MeasurementUnitId?: number, ReadingPoint?: { Name?: string }, ReadingType?: { Name?: string }, Temperature1MeasurementUnit?: { Name?: string, Short_name?: string }, Temperature2MeasurementUnit?: { Name?: string, Short_name?: string }, Temperature3MeasurementUnit?: { Name?: string, Short_name?: string }, LicensePlate?: { LookupCode?: string }, ShipmentLine?: { Id?: number, OrderLine?: { LineNumber?: number, Material?: { LookupCode?: string } } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      shipment_id?: number, license_plate_id?: number, load_container_id?: number    }) {
      return false;
    }
}
