import { Inject, Injectable, Injector }from '@angular/core';


import { ExcelOrderImport_ds_get_asn_receiving_taskService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_licenseplate_by_licenseplateIdService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_licenseplatecontents_by_licenseplateIdService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_licenseplates_by_warehouses_locationsService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_lots_by_materials_projects_lotsService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_material_by_materialIdService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_material_by_projectId_materialIdService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_material_packaging_by_materialId_and_packagingIdService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_materialpackagings_by_materialIdsService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_measurement_units_by_names_typesService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_measurement_units_by_shortnamesService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_measurement_units_by_typeidService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_order_by_orderIdService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_order_by_orderId_detailedService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_orderaddress_by_type_and_orderIdService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_orderlines_by_contentService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_orderlines_by_orderId_bottom1Service } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_orderlines_by_orderId_linenumberService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_orders_by_lookupcodes_orderclasses_projects_warehousesService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_orders_by_lookupcodes_types_projects_warehousesService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_packagings_by_materials_projects_owners_packagingsService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_packagings_by_materials_projects_packagingsService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_planned_receiving_tasks_by_licenseplateId_top1Service } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_project_by_projectIdService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_released_receiving_tasks_by_licenseplateId_top1Service } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_serialnumbers_by_lots_licenseplates_Service } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_serialnumbers_by_serialNumberIdsService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_shipment_by_orderIdService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_shipmentline_by_shipmentlineIdService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_shipmentlines_by_orderId_bottom1Service } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_shipmentlines_by_orderlineService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_tasks_by_chainHeadService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_tasks_by_serialnumberIdsService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_vendorlots_by_materials_projects_vendorlotsService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_warehouse_by_warehouseIdService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_get_warehouses_by_namesService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_material_packagings_ddService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_materials_by_project_ddService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_order_import_gridService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_projects_ddService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_ds_warehouses_ddService } from './ExcelOrderImport.datasource.index';

@Injectable({ providedIn: 'root' })
export class ExcelOrderImport_DatasourceService {

  constructor(
    private injector: Injector
  ) {
  }

  public ExcelOrderImport: ExcelOrderImport_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_get_asn_receiving_task: ExcelOrderImport_ds_get_asn_receiving_taskService;
  public get ds_get_asn_receiving_task(): ExcelOrderImport_ds_get_asn_receiving_taskService {
    if(!this._ds_get_asn_receiving_task) {
      this._ds_get_asn_receiving_task = this.injector.get(ExcelOrderImport_ds_get_asn_receiving_taskService);
    }
    return this._ds_get_asn_receiving_task;
  }
  private _ds_get_licenseplate_by_licenseplateId: ExcelOrderImport_ds_get_licenseplate_by_licenseplateIdService;
  public get ds_get_licenseplate_by_licenseplateId(): ExcelOrderImport_ds_get_licenseplate_by_licenseplateIdService {
    if(!this._ds_get_licenseplate_by_licenseplateId) {
      this._ds_get_licenseplate_by_licenseplateId = this.injector.get(ExcelOrderImport_ds_get_licenseplate_by_licenseplateIdService);
    }
    return this._ds_get_licenseplate_by_licenseplateId;
  }
  private _ds_get_licenseplatecontents_by_licenseplateId: ExcelOrderImport_ds_get_licenseplatecontents_by_licenseplateIdService;
  public get ds_get_licenseplatecontents_by_licenseplateId(): ExcelOrderImport_ds_get_licenseplatecontents_by_licenseplateIdService {
    if(!this._ds_get_licenseplatecontents_by_licenseplateId) {
      this._ds_get_licenseplatecontents_by_licenseplateId = this.injector.get(ExcelOrderImport_ds_get_licenseplatecontents_by_licenseplateIdService);
    }
    return this._ds_get_licenseplatecontents_by_licenseplateId;
  }
  private _ds_get_licenseplates_by_warehouses_locations: ExcelOrderImport_ds_get_licenseplates_by_warehouses_locationsService;
  public get ds_get_licenseplates_by_warehouses_locations(): ExcelOrderImport_ds_get_licenseplates_by_warehouses_locationsService {
    if(!this._ds_get_licenseplates_by_warehouses_locations) {
      this._ds_get_licenseplates_by_warehouses_locations = this.injector.get(ExcelOrderImport_ds_get_licenseplates_by_warehouses_locationsService);
    }
    return this._ds_get_licenseplates_by_warehouses_locations;
  }
  private _ds_get_lots_by_materials_projects_lots: ExcelOrderImport_ds_get_lots_by_materials_projects_lotsService;
  public get ds_get_lots_by_materials_projects_lots(): ExcelOrderImport_ds_get_lots_by_materials_projects_lotsService {
    if(!this._ds_get_lots_by_materials_projects_lots) {
      this._ds_get_lots_by_materials_projects_lots = this.injector.get(ExcelOrderImport_ds_get_lots_by_materials_projects_lotsService);
    }
    return this._ds_get_lots_by_materials_projects_lots;
  }
  private _ds_get_material_by_materialId: ExcelOrderImport_ds_get_material_by_materialIdService;
  public get ds_get_material_by_materialId(): ExcelOrderImport_ds_get_material_by_materialIdService {
    if(!this._ds_get_material_by_materialId) {
      this._ds_get_material_by_materialId = this.injector.get(ExcelOrderImport_ds_get_material_by_materialIdService);
    }
    return this._ds_get_material_by_materialId;
  }
  private _ds_get_material_by_projectId_materialId: ExcelOrderImport_ds_get_material_by_projectId_materialIdService;
  public get ds_get_material_by_projectId_materialId(): ExcelOrderImport_ds_get_material_by_projectId_materialIdService {
    if(!this._ds_get_material_by_projectId_materialId) {
      this._ds_get_material_by_projectId_materialId = this.injector.get(ExcelOrderImport_ds_get_material_by_projectId_materialIdService);
    }
    return this._ds_get_material_by_projectId_materialId;
  }
  private _ds_get_material_packaging_by_materialId_and_packagingId: ExcelOrderImport_ds_get_material_packaging_by_materialId_and_packagingIdService;
  public get ds_get_material_packaging_by_materialId_and_packagingId(): ExcelOrderImport_ds_get_material_packaging_by_materialId_and_packagingIdService {
    if(!this._ds_get_material_packaging_by_materialId_and_packagingId) {
      this._ds_get_material_packaging_by_materialId_and_packagingId = this.injector.get(ExcelOrderImport_ds_get_material_packaging_by_materialId_and_packagingIdService);
    }
    return this._ds_get_material_packaging_by_materialId_and_packagingId;
  }
  private _ds_get_materialpackagings_by_materialIds: ExcelOrderImport_ds_get_materialpackagings_by_materialIdsService;
  public get ds_get_materialpackagings_by_materialIds(): ExcelOrderImport_ds_get_materialpackagings_by_materialIdsService {
    if(!this._ds_get_materialpackagings_by_materialIds) {
      this._ds_get_materialpackagings_by_materialIds = this.injector.get(ExcelOrderImport_ds_get_materialpackagings_by_materialIdsService);
    }
    return this._ds_get_materialpackagings_by_materialIds;
  }
  private _ds_get_measurement_units_by_names_types: ExcelOrderImport_ds_get_measurement_units_by_names_typesService;
  public get ds_get_measurement_units_by_names_types(): ExcelOrderImport_ds_get_measurement_units_by_names_typesService {
    if(!this._ds_get_measurement_units_by_names_types) {
      this._ds_get_measurement_units_by_names_types = this.injector.get(ExcelOrderImport_ds_get_measurement_units_by_names_typesService);
    }
    return this._ds_get_measurement_units_by_names_types;
  }
  private _ds_get_measurement_units_by_shortnames: ExcelOrderImport_ds_get_measurement_units_by_shortnamesService;
  public get ds_get_measurement_units_by_shortnames(): ExcelOrderImport_ds_get_measurement_units_by_shortnamesService {
    if(!this._ds_get_measurement_units_by_shortnames) {
      this._ds_get_measurement_units_by_shortnames = this.injector.get(ExcelOrderImport_ds_get_measurement_units_by_shortnamesService);
    }
    return this._ds_get_measurement_units_by_shortnames;
  }
  private _ds_get_measurement_units_by_typeid: ExcelOrderImport_ds_get_measurement_units_by_typeidService;
  public get ds_get_measurement_units_by_typeid(): ExcelOrderImport_ds_get_measurement_units_by_typeidService {
    if(!this._ds_get_measurement_units_by_typeid) {
      this._ds_get_measurement_units_by_typeid = this.injector.get(ExcelOrderImport_ds_get_measurement_units_by_typeidService);
    }
    return this._ds_get_measurement_units_by_typeid;
  }
  private _ds_get_order_by_orderId: ExcelOrderImport_ds_get_order_by_orderIdService;
  public get ds_get_order_by_orderId(): ExcelOrderImport_ds_get_order_by_orderIdService {
    if(!this._ds_get_order_by_orderId) {
      this._ds_get_order_by_orderId = this.injector.get(ExcelOrderImport_ds_get_order_by_orderIdService);
    }
    return this._ds_get_order_by_orderId;
  }
  private _ds_get_order_by_orderId_detailed: ExcelOrderImport_ds_get_order_by_orderId_detailedService;
  public get ds_get_order_by_orderId_detailed(): ExcelOrderImport_ds_get_order_by_orderId_detailedService {
    if(!this._ds_get_order_by_orderId_detailed) {
      this._ds_get_order_by_orderId_detailed = this.injector.get(ExcelOrderImport_ds_get_order_by_orderId_detailedService);
    }
    return this._ds_get_order_by_orderId_detailed;
  }
  private _ds_get_orderaddress_by_type_and_orderId: ExcelOrderImport_ds_get_orderaddress_by_type_and_orderIdService;
  public get ds_get_orderaddress_by_type_and_orderId(): ExcelOrderImport_ds_get_orderaddress_by_type_and_orderIdService {
    if(!this._ds_get_orderaddress_by_type_and_orderId) {
      this._ds_get_orderaddress_by_type_and_orderId = this.injector.get(ExcelOrderImport_ds_get_orderaddress_by_type_and_orderIdService);
    }
    return this._ds_get_orderaddress_by_type_and_orderId;
  }
  private _ds_get_orderlines_by_content: ExcelOrderImport_ds_get_orderlines_by_contentService;
  public get ds_get_orderlines_by_content(): ExcelOrderImport_ds_get_orderlines_by_contentService {
    if(!this._ds_get_orderlines_by_content) {
      this._ds_get_orderlines_by_content = this.injector.get(ExcelOrderImport_ds_get_orderlines_by_contentService);
    }
    return this._ds_get_orderlines_by_content;
  }
  private _ds_get_orderlines_by_orderId_bottom1: ExcelOrderImport_ds_get_orderlines_by_orderId_bottom1Service;
  public get ds_get_orderlines_by_orderId_bottom1(): ExcelOrderImport_ds_get_orderlines_by_orderId_bottom1Service {
    if(!this._ds_get_orderlines_by_orderId_bottom1) {
      this._ds_get_orderlines_by_orderId_bottom1 = this.injector.get(ExcelOrderImport_ds_get_orderlines_by_orderId_bottom1Service);
    }
    return this._ds_get_orderlines_by_orderId_bottom1;
  }
  private _ds_get_orderlines_by_orderId_linenumber: ExcelOrderImport_ds_get_orderlines_by_orderId_linenumberService;
  public get ds_get_orderlines_by_orderId_linenumber(): ExcelOrderImport_ds_get_orderlines_by_orderId_linenumberService {
    if(!this._ds_get_orderlines_by_orderId_linenumber) {
      this._ds_get_orderlines_by_orderId_linenumber = this.injector.get(ExcelOrderImport_ds_get_orderlines_by_orderId_linenumberService);
    }
    return this._ds_get_orderlines_by_orderId_linenumber;
  }
  private _ds_get_orders_by_lookupcodes_orderclasses_projects_warehouses: ExcelOrderImport_ds_get_orders_by_lookupcodes_orderclasses_projects_warehousesService;
  public get ds_get_orders_by_lookupcodes_orderclasses_projects_warehouses(): ExcelOrderImport_ds_get_orders_by_lookupcodes_orderclasses_projects_warehousesService {
    if(!this._ds_get_orders_by_lookupcodes_orderclasses_projects_warehouses) {
      this._ds_get_orders_by_lookupcodes_orderclasses_projects_warehouses = this.injector.get(ExcelOrderImport_ds_get_orders_by_lookupcodes_orderclasses_projects_warehousesService);
    }
    return this._ds_get_orders_by_lookupcodes_orderclasses_projects_warehouses;
  }
  private _ds_get_orders_by_lookupcodes_types_projects_warehouses: ExcelOrderImport_ds_get_orders_by_lookupcodes_types_projects_warehousesService;
  public get ds_get_orders_by_lookupcodes_types_projects_warehouses(): ExcelOrderImport_ds_get_orders_by_lookupcodes_types_projects_warehousesService {
    if(!this._ds_get_orders_by_lookupcodes_types_projects_warehouses) {
      this._ds_get_orders_by_lookupcodes_types_projects_warehouses = this.injector.get(ExcelOrderImport_ds_get_orders_by_lookupcodes_types_projects_warehousesService);
    }
    return this._ds_get_orders_by_lookupcodes_types_projects_warehouses;
  }
  private _ds_get_packagings_by_materials_projects_owners_packagings: ExcelOrderImport_ds_get_packagings_by_materials_projects_owners_packagingsService;
  public get ds_get_packagings_by_materials_projects_owners_packagings(): ExcelOrderImport_ds_get_packagings_by_materials_projects_owners_packagingsService {
    if(!this._ds_get_packagings_by_materials_projects_owners_packagings) {
      this._ds_get_packagings_by_materials_projects_owners_packagings = this.injector.get(ExcelOrderImport_ds_get_packagings_by_materials_projects_owners_packagingsService);
    }
    return this._ds_get_packagings_by_materials_projects_owners_packagings;
  }
  private _ds_get_packagings_by_materials_projects_packagings: ExcelOrderImport_ds_get_packagings_by_materials_projects_packagingsService;
  public get ds_get_packagings_by_materials_projects_packagings(): ExcelOrderImport_ds_get_packagings_by_materials_projects_packagingsService {
    if(!this._ds_get_packagings_by_materials_projects_packagings) {
      this._ds_get_packagings_by_materials_projects_packagings = this.injector.get(ExcelOrderImport_ds_get_packagings_by_materials_projects_packagingsService);
    }
    return this._ds_get_packagings_by_materials_projects_packagings;
  }
  private _ds_get_planned_receiving_tasks_by_licenseplateId_top1: ExcelOrderImport_ds_get_planned_receiving_tasks_by_licenseplateId_top1Service;
  public get ds_get_planned_receiving_tasks_by_licenseplateId_top1(): ExcelOrderImport_ds_get_planned_receiving_tasks_by_licenseplateId_top1Service {
    if(!this._ds_get_planned_receiving_tasks_by_licenseplateId_top1) {
      this._ds_get_planned_receiving_tasks_by_licenseplateId_top1 = this.injector.get(ExcelOrderImport_ds_get_planned_receiving_tasks_by_licenseplateId_top1Service);
    }
    return this._ds_get_planned_receiving_tasks_by_licenseplateId_top1;
  }
  private _ds_get_project_by_projectId: ExcelOrderImport_ds_get_project_by_projectIdService;
  public get ds_get_project_by_projectId(): ExcelOrderImport_ds_get_project_by_projectIdService {
    if(!this._ds_get_project_by_projectId) {
      this._ds_get_project_by_projectId = this.injector.get(ExcelOrderImport_ds_get_project_by_projectIdService);
    }
    return this._ds_get_project_by_projectId;
  }
  private _ds_get_released_receiving_tasks_by_licenseplateId_top1: ExcelOrderImport_ds_get_released_receiving_tasks_by_licenseplateId_top1Service;
  public get ds_get_released_receiving_tasks_by_licenseplateId_top1(): ExcelOrderImport_ds_get_released_receiving_tasks_by_licenseplateId_top1Service {
    if(!this._ds_get_released_receiving_tasks_by_licenseplateId_top1) {
      this._ds_get_released_receiving_tasks_by_licenseplateId_top1 = this.injector.get(ExcelOrderImport_ds_get_released_receiving_tasks_by_licenseplateId_top1Service);
    }
    return this._ds_get_released_receiving_tasks_by_licenseplateId_top1;
  }
  private _ds_get_serialnumbers_by_lots_licenseplates_: ExcelOrderImport_ds_get_serialnumbers_by_lots_licenseplates_Service;
  public get ds_get_serialnumbers_by_lots_licenseplates_(): ExcelOrderImport_ds_get_serialnumbers_by_lots_licenseplates_Service {
    if(!this._ds_get_serialnumbers_by_lots_licenseplates_) {
      this._ds_get_serialnumbers_by_lots_licenseplates_ = this.injector.get(ExcelOrderImport_ds_get_serialnumbers_by_lots_licenseplates_Service);
    }
    return this._ds_get_serialnumbers_by_lots_licenseplates_;
  }
  private _ds_get_serialnumbers_by_serialNumberIds: ExcelOrderImport_ds_get_serialnumbers_by_serialNumberIdsService;
  public get ds_get_serialnumbers_by_serialNumberIds(): ExcelOrderImport_ds_get_serialnumbers_by_serialNumberIdsService {
    if(!this._ds_get_serialnumbers_by_serialNumberIds) {
      this._ds_get_serialnumbers_by_serialNumberIds = this.injector.get(ExcelOrderImport_ds_get_serialnumbers_by_serialNumberIdsService);
    }
    return this._ds_get_serialnumbers_by_serialNumberIds;
  }
  private _ds_get_shipment_by_orderId: ExcelOrderImport_ds_get_shipment_by_orderIdService;
  public get ds_get_shipment_by_orderId(): ExcelOrderImport_ds_get_shipment_by_orderIdService {
    if(!this._ds_get_shipment_by_orderId) {
      this._ds_get_shipment_by_orderId = this.injector.get(ExcelOrderImport_ds_get_shipment_by_orderIdService);
    }
    return this._ds_get_shipment_by_orderId;
  }
  private _ds_get_shipmentline_by_shipmentlineId: ExcelOrderImport_ds_get_shipmentline_by_shipmentlineIdService;
  public get ds_get_shipmentline_by_shipmentlineId(): ExcelOrderImport_ds_get_shipmentline_by_shipmentlineIdService {
    if(!this._ds_get_shipmentline_by_shipmentlineId) {
      this._ds_get_shipmentline_by_shipmentlineId = this.injector.get(ExcelOrderImport_ds_get_shipmentline_by_shipmentlineIdService);
    }
    return this._ds_get_shipmentline_by_shipmentlineId;
  }
  private _ds_get_shipmentlines_by_orderId_bottom1: ExcelOrderImport_ds_get_shipmentlines_by_orderId_bottom1Service;
  public get ds_get_shipmentlines_by_orderId_bottom1(): ExcelOrderImport_ds_get_shipmentlines_by_orderId_bottom1Service {
    if(!this._ds_get_shipmentlines_by_orderId_bottom1) {
      this._ds_get_shipmentlines_by_orderId_bottom1 = this.injector.get(ExcelOrderImport_ds_get_shipmentlines_by_orderId_bottom1Service);
    }
    return this._ds_get_shipmentlines_by_orderId_bottom1;
  }
  private _ds_get_shipmentlines_by_orderline: ExcelOrderImport_ds_get_shipmentlines_by_orderlineService;
  public get ds_get_shipmentlines_by_orderline(): ExcelOrderImport_ds_get_shipmentlines_by_orderlineService {
    if(!this._ds_get_shipmentlines_by_orderline) {
      this._ds_get_shipmentlines_by_orderline = this.injector.get(ExcelOrderImport_ds_get_shipmentlines_by_orderlineService);
    }
    return this._ds_get_shipmentlines_by_orderline;
  }
  private _ds_get_tasks_by_chainHead: ExcelOrderImport_ds_get_tasks_by_chainHeadService;
  public get ds_get_tasks_by_chainHead(): ExcelOrderImport_ds_get_tasks_by_chainHeadService {
    if(!this._ds_get_tasks_by_chainHead) {
      this._ds_get_tasks_by_chainHead = this.injector.get(ExcelOrderImport_ds_get_tasks_by_chainHeadService);
    }
    return this._ds_get_tasks_by_chainHead;
  }
  private _ds_get_tasks_by_serialnumberIds: ExcelOrderImport_ds_get_tasks_by_serialnumberIdsService;
  public get ds_get_tasks_by_serialnumberIds(): ExcelOrderImport_ds_get_tasks_by_serialnumberIdsService {
    if(!this._ds_get_tasks_by_serialnumberIds) {
      this._ds_get_tasks_by_serialnumberIds = this.injector.get(ExcelOrderImport_ds_get_tasks_by_serialnumberIdsService);
    }
    return this._ds_get_tasks_by_serialnumberIds;
  }
  private _ds_get_vendorlots_by_materials_projects_vendorlots: ExcelOrderImport_ds_get_vendorlots_by_materials_projects_vendorlotsService;
  public get ds_get_vendorlots_by_materials_projects_vendorlots(): ExcelOrderImport_ds_get_vendorlots_by_materials_projects_vendorlotsService {
    if(!this._ds_get_vendorlots_by_materials_projects_vendorlots) {
      this._ds_get_vendorlots_by_materials_projects_vendorlots = this.injector.get(ExcelOrderImport_ds_get_vendorlots_by_materials_projects_vendorlotsService);
    }
    return this._ds_get_vendorlots_by_materials_projects_vendorlots;
  }
  private _ds_get_warehouse_by_warehouseId: ExcelOrderImport_ds_get_warehouse_by_warehouseIdService;
  public get ds_get_warehouse_by_warehouseId(): ExcelOrderImport_ds_get_warehouse_by_warehouseIdService {
    if(!this._ds_get_warehouse_by_warehouseId) {
      this._ds_get_warehouse_by_warehouseId = this.injector.get(ExcelOrderImport_ds_get_warehouse_by_warehouseIdService);
    }
    return this._ds_get_warehouse_by_warehouseId;
  }
  private _ds_get_warehouses_by_names: ExcelOrderImport_ds_get_warehouses_by_namesService;
  public get ds_get_warehouses_by_names(): ExcelOrderImport_ds_get_warehouses_by_namesService {
    if(!this._ds_get_warehouses_by_names) {
      this._ds_get_warehouses_by_names = this.injector.get(ExcelOrderImport_ds_get_warehouses_by_namesService);
    }
    return this._ds_get_warehouses_by_names;
  }
  private _ds_material_packagings_dd: ExcelOrderImport_ds_material_packagings_ddService;
  public get ds_material_packagings_dd(): ExcelOrderImport_ds_material_packagings_ddService {
    if(!this._ds_material_packagings_dd) {
      this._ds_material_packagings_dd = this.injector.get(ExcelOrderImport_ds_material_packagings_ddService);
    }
    return this._ds_material_packagings_dd;
  }
  private _ds_materials_by_project_dd: ExcelOrderImport_ds_materials_by_project_ddService;
  public get ds_materials_by_project_dd(): ExcelOrderImport_ds_materials_by_project_ddService {
    if(!this._ds_materials_by_project_dd) {
      this._ds_materials_by_project_dd = this.injector.get(ExcelOrderImport_ds_materials_by_project_ddService);
    }
    return this._ds_materials_by_project_dd;
  }
  private _ds_order_import_grid: ExcelOrderImport_ds_order_import_gridService;
  public get ds_order_import_grid(): ExcelOrderImport_ds_order_import_gridService {
    if(!this._ds_order_import_grid) {
      this._ds_order_import_grid = this.injector.get(ExcelOrderImport_ds_order_import_gridService);
    }
    return this._ds_order_import_grid;
  }
  private _ds_projects_dd: ExcelOrderImport_ds_projects_ddService;
  public get ds_projects_dd(): ExcelOrderImport_ds_projects_ddService {
    if(!this._ds_projects_dd) {
      this._ds_projects_dd = this.injector.get(ExcelOrderImport_ds_projects_ddService);
    }
    return this._ds_projects_dd;
  }
  private _ds_warehouses_dd: ExcelOrderImport_ds_warehouses_ddService;
  public get ds_warehouses_dd(): ExcelOrderImport_ds_warehouses_ddService {
    if(!this._ds_warehouses_dd) {
      this._ds_warehouses_dd = this.injector.get(ExcelOrderImport_ds_warehouses_ddService);
    }
    return this._ds_warehouses_dd;
  }
}

