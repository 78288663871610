import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_freight_billing_order_detailsService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { order_id?: number }): 
  Promise<{ result: { Id?: number, AccountId?: number, BillingAddresId?: number, LookupCode?: string, PriceOverride?: number, ShippingAddressId?: number, Project?: { Id?: number, LookupCode?: string, Name?: string, Owner?: { Id?: number, LookupCode?: string, Name?: string } }, Account?: { Id?: number, LookupCode?: string, Name?: string }, Addresses?: { Id?: number, City?: string, Country?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string, State?: string }[], ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { Id?: number, LookupCode?: string, ActualWarehouse?: { Id?: number, Name?: string } } }[], ds_freight_billing_licenseplates_aggregate_by_order?: { licenseplate_count?: number }[] }[] }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { order_id?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, AccountId?: number, BillingAddresId?: number, LookupCode?: string, PriceOverride?: number, ShippingAddressId?: number, Project?: { Id?: number, LookupCode?: string, Name?: string, Owner?: { Id?: number, LookupCode?: string, Name?: string } }, Account?: { Id?: number, LookupCode?: string, Name?: string }, Addresses?: { Id?: number, City?: string, Country?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string, State?: string }[], ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { Id?: number, LookupCode?: string, ActualWarehouse?: { Id?: number, Name?: string } } }[], ds_freight_billing_licenseplates_aggregate_by_order?: { licenseplate_count?: number }[] }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { order_id?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, AccountId?: number, BillingAddresId?: number, LookupCode?: string, PriceOverride?: number, ShippingAddressId?: number, Project?: { Id?: number, LookupCode?: string, Name?: string, Owner?: { Id?: number, LookupCode?: string, Name?: string } }, Account?: { Id?: number, LookupCode?: string, Name?: string }, Addresses?: { Id?: number, City?: string, Country?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string, State?: string }[], ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { Id?: number, LookupCode?: string, ActualWarehouse?: { Id?: number, Name?: string } } }[], ds_freight_billing_licenseplates_aggregate_by_order?: { licenseplate_count?: number }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      order_id?: number    }) {
      return false;
    }
}
