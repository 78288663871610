import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Invoices_ds_invoicing_transactionsService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { search?: string, billingContractId?: number, projectId?: number, invoiceId?: number, instructionId?: string, transactionId?: string, requestId?: string, status?: string, $top?: number, $skip?: number }): Promise<{ result?: { transactionId?: string, status?: string, projectId?: number, billingContractId?: number, invoiceId?: number, instructionId?: string, process?: string, notes?: string, createdOn?: string, modifiedOn?: string, parentTransactionId?: string, requestId?: string, termId?: number, taxScheduleId?: number, shipmentId?: number, warehouseId?: number, oneInvoicePerShipment?: boolean, oneInvoicePerWarehouse?: boolean, billingAggregationStrategyIds?: number[], instructionName?: string, transactionType?: string, billingTaskId?: number, useExistingInvoice?: boolean, billingCodeIds?: number[] }[], totalCount?: number }> {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { search?: string, billingContractId?: number, projectId?: number, invoiceId?: number, instructionId?: string, transactionId?: string, requestId?: string, status?: string, $keys: string[] }): Promise<{ result?: { transactionId?: string, status?: string, projectId?: number, billingContractId?: number, invoiceId?: number, instructionId?: string, process?: string, notes?: string, createdOn?: string, modifiedOn?: string, parentTransactionId?: string, requestId?: string, termId?: number, taxScheduleId?: number, shipmentId?: number, warehouseId?: number, oneInvoicePerShipment?: boolean, oneInvoicePerWarehouse?: boolean, billingAggregationStrategyIds?: number[], instructionName?: string, transactionType?: string, billingTaskId?: number, useExistingInvoice?: boolean, billingCodeIds?: number[] }[] }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      search?: string, billingContractId?: number, projectId?: number, invoiceId?: number, instructionId?: string, transactionId?: string, requestId?: string, status?: string    }) {
      return false;
    }
}
