import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootprintApiManager_ds_get_serial_numbers_by_project_idService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { projectId?: number, materialLookupCode?: string, warehouseIds?: number[], materialId?: number, licensePlateId?: number, lotId?: number }): 
  Promise<{ result: { Id?: number, ChainHead?: number, DimensionUomId?: number, GrossVolume?: number, GrossWeight?: number, Height?: number, Length?: number, LicensePlateId?: number, LookupCode?: string, LotId?: number, NetVolume?: number, NetWeight?: number, Notes?: string, PackagingId?: number, StatusId?: number, VolumeUomId?: number, WeightUomId?: number, Width?: number, DimensionsUom?: { Short_name?: string }, VolumeUom?: { Short_name?: string }, WeightUom?: { Short_name?: string }, LicensePlate?: { WarehouseId?: number }, Status?: { Name?: string }, Packaging?: { Name?: string, ShortName?: string } }[] }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { projectId?: number, materialLookupCode?: string, warehouseIds?: number[], materialId?: number, licensePlateId?: number, lotId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ChainHead?: number, DimensionUomId?: number, GrossVolume?: number, GrossWeight?: number, Height?: number, Length?: number, LicensePlateId?: number, LookupCode?: string, LotId?: number, NetVolume?: number, NetWeight?: number, Notes?: string, PackagingId?: number, StatusId?: number, VolumeUomId?: number, WeightUomId?: number, Width?: number, DimensionsUom?: { Short_name?: string }, VolumeUom?: { Short_name?: string }, WeightUom?: { Short_name?: string }, LicensePlate?: { WarehouseId?: number }, Status?: { Name?: string }, Packaging?: { Name?: string, ShortName?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { projectId?: number, materialLookupCode?: string, warehouseIds?: number[], materialId?: number, licensePlateId?: number, lotId?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, ChainHead?: number, DimensionUomId?: number, GrossVolume?: number, GrossWeight?: number, Height?: number, Length?: number, LicensePlateId?: number, LookupCode?: string, LotId?: number, NetVolume?: number, NetWeight?: number, Notes?: string, PackagingId?: number, StatusId?: number, VolumeUomId?: number, WeightUomId?: number, Width?: number, DimensionsUom?: { Short_name?: string }, VolumeUom?: { Short_name?: string }, WeightUom?: { Short_name?: string }, LicensePlate?: { WarehouseId?: number }, Status?: { Name?: string }, Packaging?: { Name?: string, ShortName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      projectId?: number, materialLookupCode?: string, warehouseIds?: number[], materialId?: number, licensePlateId?: number, lotId?: number    }) {
      return false;
    }
}
