import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class SalesOrders_ds_sales_order_picking_gridService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId?: number, fullTextSearch?: string, shipmentId?: number, waveId?: number }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualTargetLocationId?: number, ExpectedPackagedAmount?: number, ExpectedSourceLocationId?: number, LotId?: number, MaterialId?: number, OperationCodeId?: number, OrderId?: number, OrderLineNumber?: number, PickSlipId?: number, ProjectId?: number, ShipmentId?: number, ShipmentLineId?: number, VendorLotId?: number, WarehouseId?: number, Material?: { LookupCode?: string, ProjectId?: number }, ExpectedPackagedPack?: { ShortName?: string }, ActualPackagedPack?: { ShortName?: string }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string, Vat?: string }, ActualTargetLocation?: { Name?: string }, ShipmentLine?: { ActualPackagedAmount?: number, ActualPackaged?: { Name?: string, ShortName?: string } }, OrderLine?: { GrossWeight?: number, LotId?: number, NetWeight?: number, PackagedAmount?: number, WeightUomId?: number }, ExpectedSourceLocation?: { Id?: number, Name?: string }, ExpectedSourceLicensePlate?: { Id?: number, LocationId?: number, LookupCode?: string, Location?: { Id?: number, Name?: string } }, ExpectedTargetShippingContainer?: { Id?: number, LookupCode?: string }, OperationCode?: { Name?: string }, total_picked?: { TotalCount?: number, ExpectedCount?: number, ShipmentLine?: { OrderId?: number, OrderLineNumber?: number } } }[] }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId?: number, fullTextSearch?: string, shipmentId?: number, waveId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualTargetLocationId?: number, ExpectedPackagedAmount?: number, ExpectedSourceLocationId?: number, LotId?: number, MaterialId?: number, OperationCodeId?: number, OrderId?: number, OrderLineNumber?: number, PickSlipId?: number, ProjectId?: number, ShipmentId?: number, ShipmentLineId?: number, VendorLotId?: number, WarehouseId?: number, Material?: { LookupCode?: string, ProjectId?: number }, ExpectedPackagedPack?: { ShortName?: string }, ActualPackagedPack?: { ShortName?: string }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string, Vat?: string }, ActualTargetLocation?: { Name?: string }, ShipmentLine?: { ActualPackagedAmount?: number, ActualPackaged?: { Name?: string, ShortName?: string } }, OrderLine?: { GrossWeight?: number, LotId?: number, NetWeight?: number, PackagedAmount?: number, WeightUomId?: number }, ExpectedSourceLocation?: { Id?: number, Name?: string }, ExpectedSourceLicensePlate?: { Id?: number, LocationId?: number, LookupCode?: string, Location?: { Id?: number, Name?: string } }, ExpectedTargetShippingContainer?: { Id?: number, LookupCode?: string }, OperationCode?: { Name?: string }, total_picked?: { TotalCount?: number, ExpectedCount?: number, ShipmentLine?: { OrderId?: number, OrderLineNumber?: number } } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId?: number, fullTextSearch?: string, shipmentId?: number, waveId?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualTargetLocationId?: number, ExpectedPackagedAmount?: number, ExpectedSourceLocationId?: number, LotId?: number, MaterialId?: number, OperationCodeId?: number, OrderId?: number, OrderLineNumber?: number, PickSlipId?: number, ProjectId?: number, ShipmentId?: number, ShipmentLineId?: number, VendorLotId?: number, WarehouseId?: number, Material?: { LookupCode?: string, ProjectId?: number }, ExpectedPackagedPack?: { ShortName?: string }, ActualPackagedPack?: { ShortName?: string }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string, Vat?: string }, ActualTargetLocation?: { Name?: string }, ShipmentLine?: { ActualPackagedAmount?: number, ActualPackaged?: { Name?: string, ShortName?: string } }, OrderLine?: { GrossWeight?: number, LotId?: number, NetWeight?: number, PackagedAmount?: number, WeightUomId?: number }, ExpectedSourceLocation?: { Id?: number, Name?: string }, ExpectedSourceLicensePlate?: { Id?: number, LocationId?: number, LookupCode?: string, Location?: { Id?: number, Name?: string } }, ExpectedTargetShippingContainer?: { Id?: number, LookupCode?: string }, OperationCode?: { Name?: string }, total_picked?: { TotalCount?: number, ExpectedCount?: number, ShipmentLine?: { OrderId?: number, OrderLineNumber?: number } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      orderId?: number, fullTextSearch?: string, shipmentId?: number, waveId?: number    }) {
      return false;
    }
}
