import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { SalesOrders_DatasourceService } from './SalesOrders.datasource.index';

interface ISalesOrders_license_plate_labels_by_order_reportServiceInParams {
  orderId: number}

interface ISalesOrders_license_plate_labels_by_order_reportServiceData {
  LicensePlates?: { result?: { Id?: number, LookupCode?: string, LicensePlateContents?: { LotId?: number, PackagedId?: number, Amount?: number, PackagedAmount?: number, Lot?: { LookupCode?: string, Material?: { LookupCode?: string, Project?: { LookupCode?: string } }, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string } }, Packaged?: { ShortName?: string } }[], Shipment?: { Id?: number, LookupCode?: string, OrderLookups?: { OrderId?: number, Order?: { LookupCode?: string } }[] }, ArchivedShippingLicensePlateContents?: { LotId?: number, PackagedId?: number, Amount?: number, PackagedAmount?: number, Lot?: { LookupCode?: string, Material?: { LookupCode?: string, Project?: { LookupCode?: string } }, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string } }, Packaged?: { ShortName?: string } }[], TotalPackagedAmount?: number, OrderLookupCode?: string, MaterialLookupCode?: string, LotLookupCode?: string, VendorLotLookupCode?: string, ManufactureDate?: string, ProjectLookupCode?: string, ExpirationDate?: string, Packaging?: string }[] }
}

@Injectable({ providedIn: 'root' })
export class SalesOrders_license_plate_labels_by_order_reportService extends ReportBaseService<ISalesOrders_license_plate_labels_by_order_reportServiceInParams, ISalesOrders_license_plate_labels_by_order_reportServiceData> {

  protected reportReferenceName = 'license_plate_labels_by_order_report';
  protected appReferenceName = 'SalesOrders';

  constructor(
    $utils: UtilsService,
    private $datasources: SalesOrders_DatasourceService,
    ) {
      super($utils);
  }

  override throwIfMissingRequiredInParams (inParams: ISalesOrders_license_plate_labels_by_order_reportServiceInParams) {
  }

  protected async getData(inParams: ISalesOrders_license_plate_labels_by_order_reportServiceInParams): Promise<ISalesOrders_license_plate_labels_by_order_reportServiceData> 
  {
    const $utils = this.$utils;
    const $report: { inParams: ISalesOrders_license_plate_labels_by_order_reportServiceInParams } = {
      inParams: inParams
    };

    const data: ISalesOrders_license_plate_labels_by_order_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.$datasources.SalesOrders.ds_license_plate_labels_by_order_report.get(dsParams);
      
      data.LicensePlates = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
