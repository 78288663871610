import { Inject, Injectable, Injector } from '@angular/core';


import { WorkOrders_cancel_accessorial_task_flowService } from './WorkOrders.flow.index';
import { WorkOrders_cancel_work_order_flowService } from './WorkOrders.flow.index';
import { WorkOrders_complete_accessorial_task_flowService } from './WorkOrders.flow.index';
import { WorkOrders_complete_work_order_flowService } from './WorkOrders.flow.index';
import { WorkOrders_create_accessorial_task_flowService } from './WorkOrders.flow.index';
import { WorkOrders_create_work_order_flowService } from './WorkOrders.flow.index';
import { WorkOrders_delete_accessorial_task_flowService } from './WorkOrders.flow.index';
import { WorkOrders_delete_work_order_flowService } from './WorkOrders.flow.index';
import { WorkOrders_is_accessorial_task_cancelable_flowService } from './WorkOrders.flow.index';
import { WorkOrders_is_accessorial_task_completable_flowService } from './WorkOrders.flow.index';
import { WorkOrders_is_accessorial_task_deletable_flowService } from './WorkOrders.flow.index';
import { WorkOrders_is_workorder_cancelable_flowService } from './WorkOrders.flow.index';
import { WorkOrders_is_workorder_completable_flowService } from './WorkOrders.flow.index';
import { WorkOrders_is_workorder_deletable_flowService } from './WorkOrders.flow.index';
import { WorkOrders_is_workorder_releasable_flowService } from './WorkOrders.flow.index';
import { WorkOrders_release_accessorial_task_flowService } from './WorkOrders.flow.index';
import { WorkOrders_release_work_order_flowService } from './WorkOrders.flow.index';
import { WorkOrders_update_accessorial_task_flowService } from './WorkOrders.flow.index';
import { WorkOrders_work_order_cancel_header_with_lines_flowService } from './WorkOrders.flow.index';
import { WorkOrders_work_order_complete_header_with_lines_flowService } from './WorkOrders.flow.index';
import { WorkOrders_work_order_delete_header_with_lines_flowService } from './WorkOrders.flow.index';
import { WorkOrders_work_order_release_header_with_lines_flowService } from './WorkOrders.flow.index';

import { $frontendTypes } from './WorkOrders.frontend.types'

@Injectable({ providedIn: 'root' })
export class WorkOrders_FlowService {

  constructor(
    private injector: Injector
  ) {
  }

  public WorkOrders: WorkOrders_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _cancel_accessorial_task_flow: WorkOrders_cancel_accessorial_task_flowService;
  public async cancel_accessorial_task_flow(inParams: { taskId: number, cancelledReasonCodeId?: number, cancelledNotes?: string }): Promise< { reason?: string }> {
    if(!this._cancel_accessorial_task_flow) { 
      this._cancel_accessorial_task_flow = this.injector.get(WorkOrders_cancel_accessorial_task_flowService);
    }
    return this._cancel_accessorial_task_flow.run(inParams);
  }
   
   

   
 
  private _cancel_work_order_flow: WorkOrders_cancel_work_order_flowService;
  public async cancel_work_order_flow(inParams: { workOrderId: number }): Promise< { reason?: string }> {
    if(!this._cancel_work_order_flow) { 
      this._cancel_work_order_flow = this.injector.get(WorkOrders_cancel_work_order_flowService);
    }
    return this._cancel_work_order_flow.run(inParams);
  }
   
   

   
 
  private _complete_accessorial_task_flow: WorkOrders_complete_accessorial_task_flowService;
  public async complete_accessorial_task_flow(inParams: { task_id: number, is_billing_request: boolean, actual_amount?: number, actual_uom?: number }): Promise< { reason?: string }> {
    if(!this._complete_accessorial_task_flow) { 
      this._complete_accessorial_task_flow = this.injector.get(WorkOrders_complete_accessorial_task_flowService);
    }
    return this._complete_accessorial_task_flow.run(inParams);
  }
   
   

   
 
  private _complete_work_order_flow: WorkOrders_complete_work_order_flowService;
  public async complete_work_order_flow(inParams: { workOrderId: number }): Promise< { reason?: string }> {
    if(!this._complete_work_order_flow) { 
      this._complete_work_order_flow = this.injector.get(WorkOrders_complete_work_order_flowService);
    }
    return this._complete_work_order_flow.run(inParams);
  }
   
   

   
 
  private _create_accessorial_task_flow: WorkOrders_create_accessorial_task_flowService;
  public async create_accessorial_task_flow(inParams: { project_id: number, expected_amount: number, expected_uom: number, operation_code_id: number, warehouse_id: number, action: $frontendTypes.WorkOrders.EAccessorialTaskOption, additional_properties?: { notes?: string, work_order_id?: number, order_id?: number, shipment_id?: number, license_plate_id?: number, shipping_container_id?: number } }): Promise< { reason?: string, task_id?: number }> {
    if(!this._create_accessorial_task_flow) { 
      this._create_accessorial_task_flow = this.injector.get(WorkOrders_create_accessorial_task_flowService);
    }
    return this._create_accessorial_task_flow.run(inParams);
  }
   
   

   
 
  private _create_work_order_flow: WorkOrders_create_work_order_flowService;
  public async create_work_order_flow(inParams: { projectId: number, warehouseId: number, lookupcode?: string }): Promise< { workOrderId?: number, reason?: string }> {
    if(!this._create_work_order_flow) { 
      this._create_work_order_flow = this.injector.get(WorkOrders_create_work_order_flowService);
    }
    return this._create_work_order_flow.run(inParams);
  }
   
   

   
 
  private _delete_accessorial_task_flow: WorkOrders_delete_accessorial_task_flowService;
  public async delete_accessorial_task_flow(inParams: { taskId: number }): Promise< { reason?: string }> {
    if(!this._delete_accessorial_task_flow) { 
      this._delete_accessorial_task_flow = this.injector.get(WorkOrders_delete_accessorial_task_flowService);
    }
    return this._delete_accessorial_task_flow.run(inParams);
  }
   
   

   
 
  private _delete_work_order_flow: WorkOrders_delete_work_order_flowService;
  public async delete_work_order_flow(inParams: { workOrderId: number }): Promise< { reason?: string }> {
    if(!this._delete_work_order_flow) { 
      this._delete_work_order_flow = this.injector.get(WorkOrders_delete_work_order_flowService);
    }
    return this._delete_work_order_flow.run(inParams);
  }
   
   

   
 
  private _is_accessorial_task_cancelable_flow: WorkOrders_is_accessorial_task_cancelable_flowService;
  public async is_accessorial_task_cancelable_flow(inParams: { taskId: number }): Promise< { reason?: string }> {
    if(!this._is_accessorial_task_cancelable_flow) { 
      this._is_accessorial_task_cancelable_flow = this.injector.get(WorkOrders_is_accessorial_task_cancelable_flowService);
    }
    return this._is_accessorial_task_cancelable_flow.run(inParams);
  }
   
   

   
 
  private _is_accessorial_task_completable_flow: WorkOrders_is_accessorial_task_completable_flowService;
  public async is_accessorial_task_completable_flow(inParams: { taskId: number }): Promise< { reason?: string }> {
    if(!this._is_accessorial_task_completable_flow) { 
      this._is_accessorial_task_completable_flow = this.injector.get(WorkOrders_is_accessorial_task_completable_flowService);
    }
    return this._is_accessorial_task_completable_flow.run(inParams);
  }
   
   

   
 
  private _is_accessorial_task_deletable_flow: WorkOrders_is_accessorial_task_deletable_flowService;
  public async is_accessorial_task_deletable_flow(inParams: { taskId: number }): Promise< { reason?: string }> {
    if(!this._is_accessorial_task_deletable_flow) { 
      this._is_accessorial_task_deletable_flow = this.injector.get(WorkOrders_is_accessorial_task_deletable_flowService);
    }
    return this._is_accessorial_task_deletable_flow.run(inParams);
  }
   
   

   
 
  private _is_workorder_cancelable_flow: WorkOrders_is_workorder_cancelable_flowService;
  public async is_workorder_cancelable_flow(inParams: { workOrderId: number }): Promise< { reason?: string }> {
    if(!this._is_workorder_cancelable_flow) { 
      this._is_workorder_cancelable_flow = this.injector.get(WorkOrders_is_workorder_cancelable_flowService);
    }
    return this._is_workorder_cancelable_flow.run(inParams);
  }
   
   

   
 
  private _is_workorder_completable_flow: WorkOrders_is_workorder_completable_flowService;
  public async is_workorder_completable_flow(inParams: { workOrderId: number }): Promise< { reason?: string }> {
    if(!this._is_workorder_completable_flow) { 
      this._is_workorder_completable_flow = this.injector.get(WorkOrders_is_workorder_completable_flowService);
    }
    return this._is_workorder_completable_flow.run(inParams);
  }
   
   

   
 
  private _is_workorder_deletable_flow: WorkOrders_is_workorder_deletable_flowService;
  public async is_workorder_deletable_flow(inParams: { workOrderId: number }): Promise< { reason?: string }> {
    if(!this._is_workorder_deletable_flow) { 
      this._is_workorder_deletable_flow = this.injector.get(WorkOrders_is_workorder_deletable_flowService);
    }
    return this._is_workorder_deletable_flow.run(inParams);
  }
   
   

   
 
  private _is_workorder_releasable_flow: WorkOrders_is_workorder_releasable_flowService;
  public async is_workorder_releasable_flow(inParams: { workOrderId: number }): Promise< { reason?: string }> {
    if(!this._is_workorder_releasable_flow) { 
      this._is_workorder_releasable_flow = this.injector.get(WorkOrders_is_workorder_releasable_flowService);
    }
    return this._is_workorder_releasable_flow.run(inParams);
  }
   
   

   
 
  private _release_accessorial_task_flow: WorkOrders_release_accessorial_task_flowService;
  public async release_accessorial_task_flow(inParams: { taskId: number }): Promise< { reason?: string }> {
    if(!this._release_accessorial_task_flow) { 
      this._release_accessorial_task_flow = this.injector.get(WorkOrders_release_accessorial_task_flowService);
    }
    return this._release_accessorial_task_flow.run(inParams);
  }
   
   

   
 
  private _release_work_order_flow: WorkOrders_release_work_order_flowService;
  public async release_work_order_flow(inParams: { workOrderId: number }): Promise< { reason?: string }> {
    if(!this._release_work_order_flow) { 
      this._release_work_order_flow = this.injector.get(WorkOrders_release_work_order_flowService);
    }
    return this._release_work_order_flow.run(inParams);
  }
   
   

   
 
  private _update_accessorial_task_flow: WorkOrders_update_accessorial_task_flowService;
  public async update_accessorial_task_flow(inParams: { taskId: number, expected_amount?: number, expected_uom?: number, actual_amount?: number, notes?: string, operation_code_id?: number }): Promise< { reason?: string }> {
    if(!this._update_accessorial_task_flow) { 
      this._update_accessorial_task_flow = this.injector.get(WorkOrders_update_accessorial_task_flowService);
    }
    return this._update_accessorial_task_flow.run(inParams);
  }
   
   

   
 
  private _work_order_cancel_header_with_lines_flow: WorkOrders_work_order_cancel_header_with_lines_flowService;
  public async work_order_cancel_header_with_lines_flow(inParams: { workOrderId: number }): Promise< { reason?: string }> {
    if(!this._work_order_cancel_header_with_lines_flow) { 
      this._work_order_cancel_header_with_lines_flow = this.injector.get(WorkOrders_work_order_cancel_header_with_lines_flowService);
    }
    return this._work_order_cancel_header_with_lines_flow.run(inParams);
  }
   
   

   
 
  private _work_order_complete_header_with_lines_flow: WorkOrders_work_order_complete_header_with_lines_flowService;
  public async work_order_complete_header_with_lines_flow(inParams: { workOrderId: number }): Promise< { reason?: string }> {
    if(!this._work_order_complete_header_with_lines_flow) { 
      this._work_order_complete_header_with_lines_flow = this.injector.get(WorkOrders_work_order_complete_header_with_lines_flowService);
    }
    return this._work_order_complete_header_with_lines_flow.run(inParams);
  }
   
   

   
 
  private _work_order_delete_header_with_lines_flow: WorkOrders_work_order_delete_header_with_lines_flowService;
  public async work_order_delete_header_with_lines_flow(inParams: { workOrderId: number }): Promise< { reason?: string }> {
    if(!this._work_order_delete_header_with_lines_flow) { 
      this._work_order_delete_header_with_lines_flow = this.injector.get(WorkOrders_work_order_delete_header_with_lines_flowService);
    }
    return this._work_order_delete_header_with_lines_flow.run(inParams);
  }
   
   

   
 
  private _work_order_release_header_with_lines_flow: WorkOrders_work_order_release_header_with_lines_flowService;
  public async work_order_release_header_with_lines_flow(inParams: { workOrderId: number }): Promise< { reason?: string }> {
    if(!this._work_order_release_header_with_lines_flow) { 
      this._work_order_release_header_with_lines_flow = this.injector.get(WorkOrders_work_order_release_header_with_lines_flowService);
    }
    return this._work_order_release_header_with_lines_flow.run(inParams);
  }
   
   

   
}
