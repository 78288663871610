import { 
  Component, 
  ComponentRef, 
  Inject, 
  OnDestroy, 
  OnInit, 
  TemplateRef, 
  ViewChild, 
  ViewContainerRef,
  AfterViewInit,
  EmbeddedViewRef,
} from '@angular/core';

import { 
  Router,
  ActivatedRoute, 
  ParamMap 
} from '@angular/router';

import { Location } from '@angular/common';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {Title} from "@angular/platform-browser";

import { 
  MsalInterceptorConfiguration, 
  MsalService, 
  MSAL_INTERCEPTOR_CONFIG 
} from '@azure/msal-angular';


import { 
  AccountInfo, 
  EndSessionRequest 
} from '@azure/msal-browser';

import { LoadingBarService } from '@ngx-loading-bar/core';

import { isNil, isObject } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexErrorService } from './error/datex-error-service';
import { protectedResources } from './auth-config';

import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';
import { WavelengthUiModule, WavelengthShellService, IMenubarItem, MenuItemModel } from 'wavelength-ui';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { app_shell_ComponentContextService } from './app.shell.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

enum EmbedScriptType {
  Inline,
  External
}

@Component({
  standalone: true,
  imports: [
    SharedModule,
    WavelengthUiModule
  ],
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  providers: [
    {
      provide: WavelengthShellService,
      useExisting: app_ShellService
    }
  ]
})
export class ShellComponent {

  title: string = "FootPrint Api";
  subtitle: string = "";
  logo: string = "";

  account: AccountInfo;
  home: IMenubarItem;
  items: IMenubarItem[];
  menubar: any;
  toolbarItems: IMenubarItem[];
  toolbar: any;

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: app_ShellService,
    private $datasources: app_DatasourceService,
    private $flows: app_FlowService,
    private $reports: app_ReportService,
    private $localization: app_LocalizationService,
    private $operations: app_OperationService,
    private $context: app_shell_ComponentContextService,

    private location: Location,
    private authService: MsalService,
    private route: ActivatedRoute,
    private router: Router,
    private loadingBarService: LoadingBarService,
    private titleService:Title,
    private datexErrorService: DatexErrorService,
    @Inject(MSAL_INTERCEPTOR_CONFIG) private msalInterceptorConfiguration: MsalInterceptorConfiguration
  ) {

    this.home = new MenuItemModel(
      'home',
      'icon-ic_fluent_home_20_regular',
      'Home',
      [],
      () => this.openHome()
    );

    this.items = [
    ];

    this.menubar = { 
    };

    this.toolbarItems = [
    ];


    this.toolbar = { 
    };
  }

  private addScript(type: EmbedScriptType, data: string) {
    switch(type){
      case EmbedScriptType.Inline:
        this.addInlineScript(data);
        break;
      case EmbedScriptType.External:
        this.addExternalScript(data);
        break;
      default:
        throw new Error(`Unknown script type ${type}`);
    }
  }

  private addInlineScript(body: string) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = body;
    document.body.appendChild(script);
  }

  private addExternalScript(url: string) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.defer = true;
    document.body.appendChild(script);
  }

  private openHome() {    
    this.$shell.openhome(
      false
    );
  }

  onLogoutClicked(): void {
    this.authService.logout();
  }

  onInitialized() {

    this.account = this.authService.instance.getActiveAccount();
  }

}
