import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Notifications_ds_email_requests_gridService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { entityType?: string, entityId?: number, ruleId?: string, projectId?: number, context?: string, unsent_only?: boolean, $top?: number, $skip?: number }): Promise<{ result?: { id?: string, rule_id?: string, entity_type?: string, entity_id?: number, status?: string, to?: string, cc?: string, bcc?: string, subject?: string, body?: string, created_on?: string, sent_on?: string }[], totalCount?: number }> {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { entityType?: string, entityId?: number, ruleId?: string, projectId?: number, context?: string, unsent_only?: boolean, $keys: string[] }): Promise<{ result?: { id?: string, rule_id?: string, entity_type?: string, entity_id?: number, status?: string, to?: string, cc?: string, bcc?: string, subject?: string, body?: string, created_on?: string, sent_on?: string }[] }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      entityType?: string, entityId?: number, ruleId?: string, projectId?: number, context?: string, unsent_only?: boolean    }) {
      return false;
    }
}
