import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface IReports_custom_outbound_bill_of_lading_reportServiceInParams {
  orderId: number, shipmentId?: number}

interface IReports_custom_outbound_bill_of_lading_reportServiceData {
  Header?: { result?: { Id?: number, CreatedSysUser?: string, LookupCode?: string, OwnerReference?: string, RequestedDeliveryDate?: string, TempSensorSN?: string, VendorReference?: string, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { BillOfLading?: string, GrossWeight?: number, ItemCount?: number, LoadContainerId?: number, LookupCode?: string, PalletCount?: number, SealId?: string, ShippedDate?: string, TrackingIdentifier?: string, TrailerId?: string, LoadContainer?: { LookupCode?: string } } }[], PreferredCarrier?: { Name?: string }, PreferredWarehouse?: { Name?: string, WarehousesContactsLookup?: { ContactId?: number, Contact?: { PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, Project?: { Name?: string, ProjectsContactsLookup?: { ContactId?: number, Contact?: { Id?: number, Address?: { City?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[], Owner?: { LookupCode?: string, Name?: string } }, ShipToAddress?: { AttentionOf?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string, PrimaryTelephone?: string, OrderId?: number } }[] }
  Details?: { result?: { Contents?: { ShipmentId?: number, LicensePlateId?: number, LotId?: number, PackagedId?: number, Amount?: number, PackagedAmount?: number, LotLookupCode?: string, MaterialLookupCode?: string, MaterialDescription?: string, NetWeight?: number, GrossWeight?: number, WeightUomId?: number, OrderId?: number, LicensePlateLookupCode?: string, WeightUom?: string, Uom?: string, SerialCount?: number, IsFixedWeight?: boolean, LotCOO?: string, PackagedSummaryAmount?: number, MaterialCOO?: string, ShipmentLineId?: number, OrderLinePackagedAmount?: number }[] } }
  Signature?: { result?: { Id?: number, FileName?: string, FileExtension?: string, Description?: string, CreatedSysDateTime?: string, ModifiedSysDateTime?: string, FileSize?: number, FileContent?: string } }
  Pallet?: { result?: { OrderId?: number, ShipmentId?: number, Shipment?: { Id?: number, PalletTransactions?: { Amount?: number, PalletClassId?: number, PalletClass?: { Name?: string } }[] } }[] }
}

@Injectable({ providedIn: 'root' })
export class Reports_custom_outbound_bill_of_lading_reportService extends ReportBaseService<IReports_custom_outbound_bill_of_lading_reportServiceInParams, IReports_custom_outbound_bill_of_lading_reportServiceData> {

  protected reportReferenceName = 'custom_outbound_bill_of_lading_report';
  protected appReferenceName = 'Reports';

  constructor(
    $utils: UtilsService,
    private $datasources: Reports_DatasourceService,
    ) {
      super($utils);
  }

  override throwIfMissingRequiredInParams (inParams: IReports_custom_outbound_bill_of_lading_reportServiceInParams) {
  }

  protected async getData(inParams: IReports_custom_outbound_bill_of_lading_reportServiceInParams): Promise<IReports_custom_outbound_bill_of_lading_reportServiceData> 
  {
    const $utils = this.$utils;
    const $report: { inParams: IReports_custom_outbound_bill_of_lading_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IReports_custom_outbound_bill_of_lading_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.$datasources.Reports.custom_ds_outbound_header_bill_of_lading_report.get(dsParams);
      
      data.Header = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.$datasources.Reports.custom_ds_outbound_details_bill_of_lading_report_advanced.get(dsParams);
      
      data.Details = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.$datasources.Reports.custom_ds_get_order_signature_attachment.get(dsParams);
      
      data.Signature = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId ,
        shipmentId:  null 
      };
      
      const dsData = await this.$datasources.Reports.custom_ds_get_pallet_details.get(dsParams);
      
      data.Pallet = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
