import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './app.frontend.types'

@Injectable({ providedIn: 'root' })
export class app_custom_excel_order_importService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { excel_file_blob: Blob }): Promise<{ results?: any[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.excel_file_blob)) {
      missingRequiredInParams.push('\'excel_file_blob\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    url += `?inParams=${encodeURIComponent(JSON.stringify(omit(inParams, ['excel_file_blob'])))}`;
    const body = inParams.excel_file_blob;
    this.$utils.http.setBlobHeadersToHttpClientOptions(body, options);
    
    return this.$utils.http.post(url, body, options);
    
  }

}

