import { Inject, Injectable, Injector } from '@angular/core';


import { Notifications_log_notes_reportService } from './Notifications.report.index';
import { Notifications_transaction_reportService } from './Notifications.report.index';

@Injectable({ providedIn: 'root' })
export class Notifications_ReportService {

  constructor(
    private injector: Injector
  ) {
  }

  public Notifications: Notifications_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _log_notes_report: Notifications_log_notes_reportService;
  public get log_notes_report(): Notifications_log_notes_reportService {
    if(!this._log_notes_report) {
      this._log_notes_report = this.injector.get(Notifications_log_notes_reportService);
    }
    return this._log_notes_report;
  }
  private _transaction_report: Notifications_transaction_reportService;
  public get transaction_report(): Notifications_transaction_reportService {
    if(!this._transaction_report) {
      this._transaction_report = this.injector.get(Notifications_transaction_reportService);
    }
    return this._transaction_report;
  }
}

