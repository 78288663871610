import { Inject, Injectable, Injector } from '@angular/core';


import { PurchaseOrders_inbound_orders_summary_reportService } from './PurchaseOrders.report.index';
import { PurchaseOrders_inbound_unloading_tally_reportService } from './PurchaseOrders.report.index';
import { PurchaseOrders_open_putaway_reportService } from './PurchaseOrders.report.index';
import { PurchaseOrders_purchase_order_reportService } from './PurchaseOrders.report.index';
import { PurchaseOrders_receiving_reportService } from './PurchaseOrders.report.index';

@Injectable({ providedIn: 'root' })
export class PurchaseOrders_ReportService {

  constructor(
    private injector: Injector
  ) {
  }

  public PurchaseOrders: PurchaseOrders_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _inbound_orders_summary_report: PurchaseOrders_inbound_orders_summary_reportService;
  public get inbound_orders_summary_report(): PurchaseOrders_inbound_orders_summary_reportService {
    if(!this._inbound_orders_summary_report) {
      this._inbound_orders_summary_report = this.injector.get(PurchaseOrders_inbound_orders_summary_reportService);
    }
    return this._inbound_orders_summary_report;
  }
  private _inbound_unloading_tally_report: PurchaseOrders_inbound_unloading_tally_reportService;
  public get inbound_unloading_tally_report(): PurchaseOrders_inbound_unloading_tally_reportService {
    if(!this._inbound_unloading_tally_report) {
      this._inbound_unloading_tally_report = this.injector.get(PurchaseOrders_inbound_unloading_tally_reportService);
    }
    return this._inbound_unloading_tally_report;
  }
  private _open_putaway_report: PurchaseOrders_open_putaway_reportService;
  public get open_putaway_report(): PurchaseOrders_open_putaway_reportService {
    if(!this._open_putaway_report) {
      this._open_putaway_report = this.injector.get(PurchaseOrders_open_putaway_reportService);
    }
    return this._open_putaway_report;
  }
  private _purchase_order_report: PurchaseOrders_purchase_order_reportService;
  public get purchase_order_report(): PurchaseOrders_purchase_order_reportService {
    if(!this._purchase_order_report) {
      this._purchase_order_report = this.injector.get(PurchaseOrders_purchase_order_reportService);
    }
    return this._purchase_order_report;
  }
  private _receiving_report: PurchaseOrders_receiving_reportService;
  public get receiving_report(): PurchaseOrders_receiving_reportService {
    if(!this._receiving_report) {
      this._receiving_report = this.injector.get(PurchaseOrders_receiving_reportService);
    }
    return this._receiving_report;
  }
}

