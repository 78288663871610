import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Inventory_ds_inventory_location_gridService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { ownerId?: number, projectId?: number, warehouseId?: number, locationId?: number, materialIds?: number[], fullTextSearch?: string, lotId?: number, take?: number, skip?: number, zonePath?: string }): 
  Promise<{ result: { LocationId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, Location?: { Id?: number, Name?: string, WarehouseId?: number, Warehouse?: { Name?: string }, ParentLocationContainer?: { Name?: string } }, Lot?: { LookupCode?: string, ReceiveDate?: string, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string }, Material?: { Id?: number, Description?: string, LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string } } }, Packaging?: { Name?: string, ShortName?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { ownerId?: number, projectId?: number, warehouseId?: number, locationId?: number, materialIds?: number[], fullTextSearch?: string, lotId?: number, take?: number, skip?: number, zonePath?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { LocationId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, Location?: { Id?: number, Name?: string, WarehouseId?: number, Warehouse?: { Name?: string }, ParentLocationContainer?: { Name?: string } }, Lot?: { LookupCode?: string, ReceiveDate?: string, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string }, Material?: { Id?: number, Description?: string, LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string } } }, Packaging?: { Name?: string, ShortName?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { ownerId?: number, projectId?: number, warehouseId?: number, locationId?: number, materialIds?: number[], fullTextSearch?: string, lotId?: number, take?: number, skip?: number, zonePath?: string, $keys: { LocationId?: number, LotId?: number, PackagedId?: number }[] }): 
  Promise<{ result: { LocationId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, Location?: { Id?: number, Name?: string, WarehouseId?: number, Warehouse?: { Name?: string }, ParentLocationContainer?: { Name?: string } }, Lot?: { LookupCode?: string, ReceiveDate?: string, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string }, Material?: { Id?: number, Description?: string, LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string } } }, Packaging?: { Name?: string, ShortName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      ownerId?: number, projectId?: number, warehouseId?: number, locationId?: number, materialIds?: number[], fullTextSearch?: string, lotId?: number, take?: number, skip?: number, zonePath?: string    }) {
      return false;
    }
}
