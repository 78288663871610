import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './ExcelOrderImport.frontend.types'

@Injectable({ providedIn: 'root' })
export class ExcelOrderImport_adjust_asn_orderline_and_shipmentline_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { adjustPackagedAmount: number, shipmentlineId: number, orderId: number, orderlineNumber: number }): Promise<void> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.adjustPackagedAmount)) {
      missingRequiredInParams.push('\'adjustPackagedAmount\'');
    }
    if (isNil(inParams.shipmentlineId)) {
      missingRequiredInParams.push('\'shipmentlineId\'');
    }
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.orderlineNumber)) {
      missingRequiredInParams.push('\'orderlineNumber\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

