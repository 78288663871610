import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './FootprintApiManager.frontend.types'

@Injectable({ providedIn: 'root' })
export class FootprintApiManager_translate_serial_number_from_public_to_footprint_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { public_serial_numbers: $frontendTypes.FootprintApiManager.public_serial_number[] }): Promise<{ footprint_serial_numbers: { Archived?: boolean, ChainHead?: number, DimensionsUom?: { Short_name?: string, Id?: number }, GrossVolume?: number, GrossWeight?: number, Height?: number, Id?: number, Length?: number, LicensePlate?: { LookupCode?: string, Id?: number }, LookupCode?: string, NetWeight?: number, NetVolume?: number, Notes?: string, OriginalChainHead?: number, OutboundShipment?: { LookupCode?: string, Id?: number }, Packaging?: { ShortName?: string, Id?: number }, Status?: { Name?: string, Id?: number }, VolumeUom?: { Short_name?: string, Id?: number }, WeightUom?: { Short_name?: string, Id?: number }, Width?: number, ContainsPortalVisibleAttachments?: boolean, Lot?: { Id?: number, LookupCode?: string }, custom_fields?: $frontendTypes.FootprintApiManager.public_custom_field[] }[], errors?: string[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.public_serial_numbers)) {
      missingRequiredInParams.push('\'public_serial_numbers\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

