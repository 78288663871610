import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_ds_get_non_fixed_weight_details_by_shipmentId_completedService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { shipmentIds: number[] }): 
  Promise<{ result: { NetWeight?: number, GrossWeight?: number, SerialCount?: number, LotId?: number, LicensePlateId?: number, PackagingId?: number, WeightUomId?: number, OutboundShipmentId?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentIds)) {
      missingRequiredInParams.push('\'shipmentIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { shipmentIds: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { NetWeight?: number, GrossWeight?: number, SerialCount?: number, LotId?: number, LicensePlateId?: number, PackagingId?: number, WeightUomId?: number, OutboundShipmentId?: number }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentIds)) {
      missingRequiredInParams.push('\'shipmentIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { shipmentIds: number[], $keys: { LotId?: number, LicensePlateId?: number, PackagingId?: number, WeightUomId?: number, OutboundShipmentId?: number }[] }): 
  Promise<{ result: { NetWeight?: number, GrossWeight?: number, SerialCount?: number, LotId?: number, LicensePlateId?: number, PackagingId?: number, WeightUomId?: number, OutboundShipmentId?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentIds)) {
      missingRequiredInParams.push('\'shipmentIds\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      shipmentIds: number[]    }) {
        if(isNil(inParams.shipmentIds)) {
          return true; 
        }
      return false;
    }
}
