import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Cartonization_ds_get_material_packaging_by_materialIdsService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { materialIds: number[], packagingId?: number }): 
  Promise<{ result: { MaterialId?: number, PackagingId?: number, BasePackagingId?: number, BasePackagingQuantity?: number, DimensionUomId?: number, Height?: number, Length?: number, ShippingWeight?: number, UseAsShippingContainer?: boolean, VolumeUomId?: number, WeightUomId?: number, Width?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.materialIds)) {
      missingRequiredInParams.push('\'materialIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { materialIds: number[], packagingId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { MaterialId?: number, PackagingId?: number, BasePackagingId?: number, BasePackagingQuantity?: number, DimensionUomId?: number, Height?: number, Length?: number, ShippingWeight?: number, UseAsShippingContainer?: boolean, VolumeUomId?: number, WeightUomId?: number, Width?: number }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.materialIds)) {
      missingRequiredInParams.push('\'materialIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { materialIds: number[], packagingId?: number, $keys: { MaterialId?: number, PackagingId?: number }[] }): 
  Promise<{ result: { MaterialId?: number, PackagingId?: number, BasePackagingId?: number, BasePackagingQuantity?: number, DimensionUomId?: number, Height?: number, Length?: number, ShippingWeight?: number, UseAsShippingContainer?: boolean, VolumeUomId?: number, WeightUomId?: number, Width?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.materialIds)) {
      missingRequiredInParams.push('\'materialIds\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      materialIds: number[], packagingId?: number    }) {
        if(isNil(inParams.materialIds)) {
          return true; 
        }
      return false;
    }
}
