import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Invoices_ds_freight_billing_details_editorService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }


  public async get(inParams: { date_from?: string, date_to?: string, full_text_search?: string }): Promise<{ result?: { header_id?: string, freight_header_lookupcode?: string, charge_type?: string, charge_amount?: number, pallet_range_from?: number, pallet_range_to?: number, created_date?: string, date_number?: number, order?: number } }> {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      date_from?: string, date_to?: string, full_text_search?: string    }) {
      return false;
    }
}
