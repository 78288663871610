import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootprintApiManager_ds_find_order_by_transaction_idService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { transaction_id: string }): 
  Promise<{ result: { Id?: number, LookupCode?: string, OrderClassId?: number, OrderClass?: { Name?: string, OrderClassTypeId?: number, OrderTypeId?: number } } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.transaction_id)) {
      missingRequiredInParams.push('\'transaction_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      transaction_id: string    }) {
        if(isNil(inParams.transaction_id)) {
          return true; 
        }
      return false;
    }
}
