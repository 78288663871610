import { Inject, Injectable, Injector } from '@angular/core';


import { FootprintApiManager_log_notes_reportService } from './FootprintApiManager.report.index';
import { FootprintApiManager_transaction_reportService } from './FootprintApiManager.report.index';

@Injectable({ providedIn: 'root' })
export class FootprintApiManager_ReportService {

  constructor(
    private injector: Injector
  ) {
  }

  public FootprintApiManager: FootprintApiManager_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _log_notes_report: FootprintApiManager_log_notes_reportService;
  public get log_notes_report(): FootprintApiManager_log_notes_reportService {
    if(!this._log_notes_report) {
      this._log_notes_report = this.injector.get(FootprintApiManager_log_notes_reportService);
    }
    return this._log_notes_report;
  }
  private _transaction_report: FootprintApiManager_transaction_reportService;
  public get transaction_report(): FootprintApiManager_transaction_reportService {
    if(!this._transaction_report) {
      this._transaction_report = this.injector.get(FootprintApiManager_transaction_reportService);
    }
    return this._transaction_report;
  }
}

