import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface IReports_custom_invoice_reportServiceInParams {
  Id: number}

interface IReports_custom_invoice_reportServiceData {
  custom_invoice_report?: { result?: { Id?: number, BillToContactId?: number, CreatedSysDateTime?: string, DueDate?: string, InvoiceDate?: string, LookupCode?: string, ProjectId?: number, InvoiceLines?: { Description?: string, LineTotal?: number, Quantity?: number, UnitPrice?: number, BillingRecords?: { ActualUOM?: number, BillingTask?: { Id?: number, Order?: { OwnerReference?: string }, BillingCode?: { Description?: string } }, ActualMeasurementUnit?: { Short_name?: string } }[] }[], Project?: { Id?: number, Owner?: { LookupCode?: string } }, Account?: { LookupCode?: string, Name?: string, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string } }[] }, Term?: { Description?: string, Name?: string, PeriodInDays?: number }, OwnerReference?: string, BillingDescription?: string, ActualMesurmentUnit?: string }[] }
  ds_get_owner_address_by_invoiceId?: { result?: { Id?: number, Project?: { Id?: number, Owner?: { Id?: number, OwnersContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } } }
  ds_get_invoice_header_by_invoiceId?: { result?: { Id?: number, DueDate?: string, InvoiceDate?: string, LookupCode?: string, Notes?: string, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string, Enterprise?: { Name?: string } } } } }
  ds_get_owner_opeartional_address_by_invoiceId?: { result?: { Id?: number, TypeId?: number, Project?: { Id?: number, Owner?: { Id?: number, OwnersContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, TypeId?: number, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } } }
}

@Injectable({ providedIn: 'root' })
export class Reports_custom_invoice_reportService extends ReportBaseService<IReports_custom_invoice_reportServiceInParams, IReports_custom_invoice_reportServiceData> {

  protected reportReferenceName = 'custom_invoice_report';
  protected appReferenceName = 'Reports';

  constructor(
    $utils: UtilsService,
    private $datasources: Reports_DatasourceService,
    ) {
      super($utils);
  }

  override throwIfMissingRequiredInParams (inParams: IReports_custom_invoice_reportServiceInParams) {
  }

  protected async getData(inParams: IReports_custom_invoice_reportServiceInParams): Promise<IReports_custom_invoice_reportServiceData> 
  {
    const $utils = this.$utils;
    const $report: { inParams: IReports_custom_invoice_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IReports_custom_invoice_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        Id:  $report.inParams.Id 
      };
      
      const dsData = await this.$datasources.Reports.custom_ds_invoice_report_enhanced.get(dsParams);
      
      data.custom_invoice_report = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        invoiceId:  $report.inParams.Id 
      };
      
      const dsData = await this.$datasources.Reports.ds_get_owner_address_by_invoiceId.get(dsParams);
      
      data.ds_get_owner_address_by_invoiceId = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        invoiceId:  $report.inParams.Id 
      };
      
      const dsData = await this.$datasources.Reports.ds_get_invoice_header_by_invoiceId.get(dsParams);
      
      data.ds_get_invoice_header_by_invoiceId = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        invoiceId:  $report.inParams.Id 
      };
      
      const dsData = await this.$datasources.Reports.ds_get_owner_opeartional_address_by_invoiceId.get(dsParams);
      
      data.ds_get_owner_opeartional_address_by_invoiceId = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
