import { Inject, Injectable, Injector } from '@angular/core';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';

import { Utilities_OperationService } from './Utilities.operation.service';
import { Cartonization_OperationService } from './Cartonization.operation.service';
import { TransloadOrders_OperationService } from './TransloadOrders.operation.service';
import { WorkOrders_OperationService } from './WorkOrders.operation.service';
import { Notifications_OperationService } from './Notifications.operation.service';
import { Invoices_OperationService } from './Invoices.operation.service';
import { Reports_OperationService } from './Reports.operation.service';
import { ExcelOrderImport_OperationService } from './ExcelOrderImport.operation.service';
import { PurchaseOrders_OperationService } from './PurchaseOrders.operation.service';
import { SalesOrders_OperationService } from './SalesOrders.operation.service';
import { Inventory_OperationService } from './Inventory.operation.service';
import { FootprintApiManager_OperationService } from './FootprintApiManager.operation.service';
import { PrintNode_OperationService } from './PrintNode.operation.service';


@Injectable({ providedIn: 'root' })
export class app_OperationService {

  constructor(
    private injector: Injector,
    private $utils: UtilsService,
  ) {
    this.Utilities = this.injector.get(Utilities_OperationService);
    this.Cartonization = this.injector.get(Cartonization_OperationService);
    this.TransloadOrders = this.injector.get(TransloadOrders_OperationService);
    this.WorkOrders = this.injector.get(WorkOrders_OperationService);
    this.Notifications = this.injector.get(Notifications_OperationService);
    this.Invoices = this.injector.get(Invoices_OperationService);
    this.Reports = this.injector.get(Reports_OperationService);
    this.ExcelOrderImport = this.injector.get(ExcelOrderImport_OperationService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_OperationService);
    this.SalesOrders = this.injector.get(SalesOrders_OperationService);
    this.Inventory = this.injector.get(Inventory_OperationService);
    this.FootprintApiManager = this.injector.get(FootprintApiManager_OperationService);
    this.PrintNode = this.injector.get(PrintNode_OperationService);
  }

    public Utilities: Utilities_OperationService;
    public Cartonization: Cartonization_OperationService;
    public TransloadOrders: TransloadOrders_OperationService;
    public WorkOrders: WorkOrders_OperationService;
    public Notifications: Notifications_OperationService;
    public Invoices: Invoices_OperationService;
    public Reports: Reports_OperationService;
    public ExcelOrderImport: ExcelOrderImport_OperationService;
    public PurchaseOrders: PurchaseOrders_OperationService;
    public SalesOrders: SalesOrders_OperationService;
    public Inventory: Inventory_OperationService;
    public FootprintApiManager: FootprintApiManager_OperationService;
    public PrintNode: PrintNode_OperationService;
  public app: app_OperationService = this;


  private isAuthorized(operationName: string): Promise<boolean> {
    return this.$utils.http.post(`${environment.backendUrl}api/app/operations/${operationName}/isauthorized`, null);
  }
}
