import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './FootprintApiManager.frontend.types'

@Injectable({ providedIn: 'root' })
export class FootprintApiManager_storage_lifecycleService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { action: string, payload?: { id?: string, sender?: string, receiver?: string, ingress_type?: string, ingress_id?: string, transaction_id?: string, translation_status?: string, import_status?: string, order_status?: string, reference?: string, export_status?: string, send_status?: string, egress_type?: string, egress_id?: string, date_time?: string, environment?: string }, skip?: number, take?: number, integration_name: string, statuses?: string[] }): Promise<{ payload?: { id?: string, sender?: string, receiver?: string, ingress_type?: string, ingress_id?: string, transaction_id?: string, translation_status?: string, import_status?: string, order_status?: string, reference?: string, export_status?: string, send_status?: string, egress_type?: string, egress_id?: string, date_time?: string, environment?: string, integration_name?: string }[], success?: boolean, count?: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.action)) {
      missingRequiredInParams.push('\'action\'');
    }
    if (isNil(inParams.integration_name)) {
      missingRequiredInParams.push('\'integration_name\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

