import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Inventory_ds_get_inventory_licenseplate_snapshot_by_projectId_warehouseIdService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { projectId: number, warehouseId: number, materialId?: number }): 
  Promise<{ result: { TotalPackagedAmount?: number, TotalAmount?: number, TotalGrossWeight?: number, TotalNetWeight?: number, LicensePlateId?: number, PackagedId?: number, WeightUomId?: number, LotId?: number, LicensePlate?: { WarehouseId?: number, LookupCode?: string, Warehouse?: { Name?: string }, Location?: { Id?: number, Name?: string } }, Packaging?: { ShortName?: string }, WeightUom?: { Short_name?: string }, Lot?: { MaterialId?: number, LookupCode?: string, ReceiveDate?: string, LastReceiveDate?: string, Material?: { LookupCode?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { projectId: number, warehouseId: number, materialId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { TotalPackagedAmount?: number, TotalAmount?: number, TotalGrossWeight?: number, TotalNetWeight?: number, LicensePlateId?: number, PackagedId?: number, WeightUomId?: number, LotId?: number, LicensePlate?: { WarehouseId?: number, LookupCode?: string, Warehouse?: { Name?: string }, Location?: { Id?: number, Name?: string } }, Packaging?: { ShortName?: string }, WeightUom?: { Short_name?: string }, Lot?: { MaterialId?: number, LookupCode?: string, ReceiveDate?: string, LastReceiveDate?: string, Material?: { LookupCode?: string } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { projectId: number, warehouseId: number, materialId?: number, $keys: { LicensePlateId?: number, LicensePlate?: { WarehouseId?: number, LookupCode?: string, Warehouse?: { Name?: string }, Location?: { Id?: number, Name?: string } }, PackagedId?: number, Packaging?: { ShortName?: string }, WeightUomId?: number, WeightUom?: { Short_name?: string }, LotId?: number, Lot?: { MaterialId?: number, LookupCode?: string, Material?: { LookupCode?: string }, ReceiveDate?: string, LastReceiveDate?: string } }[] }): 
  Promise<{ result: { TotalPackagedAmount?: number, TotalAmount?: number, TotalGrossWeight?: number, TotalNetWeight?: number, LicensePlateId?: number, PackagedId?: number, WeightUomId?: number, LotId?: number, LicensePlate?: { WarehouseId?: number, LookupCode?: string, Warehouse?: { Name?: string }, Location?: { Id?: number, Name?: string } }, Packaging?: { ShortName?: string }, WeightUom?: { Short_name?: string }, Lot?: { MaterialId?: number, LookupCode?: string, ReceiveDate?: string, LastReceiveDate?: string, Material?: { LookupCode?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      projectId: number, warehouseId: number, materialId?: number    }) {
        if(isNil(inParams.projectId)) {
          return true; 
        }
        if(isNil(inParams.warehouseId)) {
          return true; 
        }
      return false;
    }
}
